import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AdminHeader from "./admin-header";
import API from "../../../api";
import Loading from "../components/loading";
//import "./styles/gear-list-print.css";
import { createToast } from "./common";
import { handleApiError } from "./error-utils";
import { formatCentralTime } from "../../../common";

// Add print-specific styles
const printStyles = `
  @media print {
    header, nav, footer, .non-printable {
      display: none !important;
    }
    .printable-area {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
    .print-header {
      display: block;
      text-align: center;
      margin-bottom: 20px;
    }
    body {
      margin: 0;
      padding: 0;
      background: white;
    }
  }
`;

const GearListPrint = () => {
  const { dateId, classId } = useParams();
  const navigate = useNavigate();
  const [gearList, setGearList] = useState([]);
  const [classInfo, setClassInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [gearListLinkId, setGearListLinkId] = useState(null);
  const [gearListType, setGearListType] = useState("long_range"); // Default to long_range

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch gear list data
        const response = await API.get(`gear/admin/date/${dateId}`);
        if (response && response.gear_json) {
          setGearList(
            Array.isArray(response.gear_json) ? response.gear_json : []
          );

          // Save the gear list link ID if available
          if (response.gear_list_link_id) {
            setGearListLinkId(response.gear_list_link_id);
          }
        }

        // Fetch class and schedule info concurrently
        const [classResponse, scheduleResponse] = await Promise.all([
          API.get(`classes/${classId}`),
          API.get(`schedule/${dateId}`),
        ]);

        if (classResponse && scheduleResponse) {
          setClassInfo({
            ...classResponse[0],
            start_date: new Date(scheduleResponse[0].start_date),
            end_date: new Date(scheduleResponse[0].end_date),
          });

          // Set the gear list type if available
          if (classResponse[0].gear_list) {
            setGearListType(classResponse[0].gear_list);
          }
        }

        setLoading(false);
      } catch (error) {
        handleApiError(error, createToast, "Error fetching gear list");
        setLoading(false);
      }
    };

    fetchData();
  }, [dateId, classId]);

  const printPage = () => {
    window.print();
  };

  const goBackToSchedule = () => {
    navigate("/--admin-schedule");
  };

  // Helper function to check if a section has any valid data
  const hasValidData = (section, fields) => {
    if (!section) return false;

    return fields.some((field) => {
      const value = section[field];
      return value && value !== "not_sure" && value !== "N/A";
    });
  };

  // Generate the full gear list URL
  const gearListUrl = gearListLinkId
    ? `${window.location.origin}/gear-list/${gearListLinkId}`
    : null;

  // Define fields to check for each section
  const rifleFields = [
    "manufacturer",
    "make",
    "model",
    "action",
    "barrelLength",
    "barrelTwist",
  ];
  const scopeFields = [
    "manufacturer",
    "make",
    "model",
    "boreAxis",
    "turretUnits",
    "magMin",
    "magMax",
  ];
  const ammoFields = [
    "manufacturer",
    "boxName",
    "boxNumber",
    "caliber",
    "weight",
    "muzzleVelocity",
  ];

  // Simplified fields for standard rifle format
  const standardRifleFields = ["manufacturer"];
  const standardScopeFields = ["manufacturer"];
  const standardAmmoFields = ["caliber"];

  if (loading) {
    return (
      <AdminHeader title="Gear List">
        <Loading />
      </AdminHeader>
    );
  }

  // Render standard rifle table - a simplified view with all three key fields in one table
  const renderStandardRifleTable = () => {
    return (
      <div className="row section-padding">
        <div className="col-12">
          {gearList.length === 0 ? (
            <div className="alert alert-info">
              No gear list entries found for this class date.
            </div>
          ) : (
            gearList.map((entry, index) => (
              <div key={index} className="card mb-4">
                <div className="card-header bg-warning text-dark">
                  <h3>{entry.name}</h3>
                  <p className="mb-0">
                    <small>
                      Submitted:{" "}
                      {formatCentralTime(new Date(entry.date_submitted))}
                    </small>
                  </p>
                </div>
                <div className="card-body">
                  <table className="table table-striped">
                    <tbody>
                      <tr>
                        <th style={{ width: "30%" }}>
                          Rifle Style (Manufacturer/Brand)
                        </th>
                        <td>{entry.rifle?.manufacturer || "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Optic</th>
                        <td>{entry.scope?.manufacturer || "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Caliber</th>
                        <td>{entry.ammo?.caliber || "N/A"}</td>
                      </tr>
                      {entry.comments && (
                        <tr>
                          <th>Comments</th>
                          <td>{entry.comments}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    );
  };

  // Render detailed gear tables (original layout)
  const renderDetailedGearTables = () => {
    return (
      <div className="row section-padding">
        <div className="col-12">
          {gearList.length === 0 ? (
            <div className="alert alert-info">
              No gear list entries found for this class date.
            </div>
          ) : (
            gearList.map((entry, index) => (
              <div key={index} className="card mb-4">
                <div className="card-header bg-warning text-dark">
                  <h3>{entry.name}</h3>
                  <p className="mb-0">
                    <small>
                      Submitted:{" "}
                      {formatCentralTime(new Date(entry.date_submitted))}
                    </small>
                  </p>
                </div>
                <div className="card-body">
                  <div className="row">
                    {hasValidData(entry.rifle, rifleFields) && (
                      <div className="col-md-4">
                        <h4 className="text-primary">Rifle</h4>
                        <table className="table table-striped">
                          <tbody>
                            <tr>
                              <th>Manufacturer</th>
                              <td>{entry.rifle.manufacturer || "N/A"}</td>
                            </tr>
                            <tr>
                              <th>Make</th>
                              <td>{entry.rifle.make || "N/A"}</td>
                            </tr>
                            <tr>
                              <th>Model</th>
                              <td>{entry.rifle.model || "N/A"}</td>
                            </tr>
                            <tr>
                              <th>Action</th>
                              <td>
                                {entry.rifle.action === "bolt"
                                  ? "Bolt Action"
                                  : entry.rifle.action === "break"
                                  ? "Break Barrel"
                                  : entry.rifle.action === "semi_auto"
                                  ? "Semi Auto"
                                  : "Not Specified"}
                              </td>
                            </tr>
                            <tr>
                              <th>Barrel Length</th>
                              <td>
                                {entry.rifle.barrelLength || "N/A"} inches
                              </td>
                            </tr>
                            <tr>
                              <th>Barrel Twist</th>
                              <td>{entry.rifle.barrelTwist || "N/A"}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}

                    {hasValidData(entry.scope, scopeFields) && (
                      <div className="col-md-4">
                        <h4 className="text-primary">Scope</h4>
                        <table className="table table-striped">
                          <tbody>
                            <tr>
                              <th>Manufacturer</th>
                              <td>{entry.scope.manufacturer || "N/A"}</td>
                            </tr>
                            <tr>
                              <th>Make</th>
                              <td>{entry.scope.make || "N/A"}</td>
                            </tr>
                            <tr>
                              <th>Model</th>
                              <td>{entry.scope.model || "N/A"}</td>
                            </tr>
                            <tr>
                              <th>Bore Axis</th>
                              <td>{entry.scope.boreAxis || "N/A"}</td>
                            </tr>
                            <tr>
                              <th>Turret Units</th>
                              <td>
                                {entry.scope.turretUnits === "moa"
                                  ? "Minute of Angle (MOA)"
                                  : entry.scope.turretUnits === "iphy"
                                  ? "Inch Per Hundred Yards (IPHY)"
                                  : entry.scope.turretUnits === "mill"
                                  ? "Milliradians (MIL)"
                                  : "Not Specified"}
                              </td>
                            </tr>
                            <tr>
                              <th>Magnification</th>
                              <td>
                                {entry.scope.magMin || "?"} -{" "}
                                {entry.scope.magMax || "?"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}

                    {hasValidData(entry.ammo, ammoFields) && (
                      <div className="col-md-4">
                        <h4 className="text-primary">Ammunition</h4>
                        <table className="table table-striped">
                          <tbody>
                            <tr>
                              <th>Manufacturer</th>
                              <td>{entry.ammo.manufacturer || "N/A"}</td>
                            </tr>
                            <tr>
                              <th>Box Name</th>
                              <td>{entry.ammo.boxName || "N/A"}</td>
                            </tr>
                            <tr>
                              <th>Box Number</th>
                              <td>{entry.ammo.boxNumber || "N/A"}</td>
                            </tr>
                            <tr>
                              <th>Caliber</th>
                              <td>{entry.ammo.caliber || "N/A"}</td>
                            </tr>
                            <tr>
                              <th>Weight</th>
                              <td>{entry.ammo.weight || "N/A"} grains</td>
                            </tr>
                            <tr>
                              <th>Muzzle Velocity</th>
                              <td>{entry.ammo.muzzleVelocity || "N/A"}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  {entry.comments && (
                    <div className="row mt-3">
                      <div className="col-12">
                        <h4 className="text-primary">Comments</h4>
                        <p>{entry.comments}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {/* Include print styles in the page */}
      <style>{printStyles}</style>

      {/* Non-printable header section */}
      <div className="non-printable">
        <AdminHeader>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h1 className="pbgt-yellow">
              Gear List for {classInfo?.class_title}
            </h1>
            <div>
              <button
                className="btn btn-secondary me-2"
                onClick={goBackToSchedule}
              >
                <i className="bi bi-arrow-left me-2"></i>Back to Schedule
              </button>
              <button className="btn btn-primary" onClick={printPage}>
                <i className="bi bi-printer me-2"></i>Print This Page
              </button>
            </div>
          </div>
          <div className="text-white">
            <p>
              <strong>Class Date:</strong>{" "}
              {formatCentralTime(classInfo?.start_date, {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </p>
            <p>
              <strong>Total Entries:</strong> {gearList.length}
            </p>
            <p>
              <strong>Gear List Type:</strong>{" "}
              {gearListType === "standard_rifle"
                ? "Standard Rifle"
                : "Long Range"}
            </p>
            {gearListUrl && (
              <p>
                <strong>Gear List Link:</strong>{" "}
                <a
                  href={gearListUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white"
                >
                  {gearListUrl} <i className="bi bi-box-arrow-up-right"></i>
                </a>
                <button
                  className="btn btn-sm btn-outline-light ms-2"
                  onClick={() => {
                    navigator.clipboard.writeText(gearListUrl);
                    alert("Gear list link copied to clipboard!");
                  }}
                >
                  <i className="bi bi-clipboard"></i> Copy
                </button>
              </p>
            )}
          </div>
        </AdminHeader>
      </div>

      {/* Printable content area */}
      <div className="printable-area">
        {/* Print-only header that shows only when printing */}
        <div className="print-header d-none d-print-block">
          <h2>Gear List for {classInfo?.class_title}</h2>
          <div style={{ textAlign: "center" }}>
            <h5>{classInfo?.class_title}</h5>
            <p>
              {formatCentralTime(classInfo?.start_date, {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </p>
          </div>
          <p>
            <strong>Total Entries:</strong> {gearList.length}
          </p>
          <p>
            <strong>Gear List Type:</strong>{" "}
            {gearListType === "standard_rifle"
              ? "Standard Rifle"
              : "Long Range"}
          </p>
          {gearListUrl && (
            <p>
              <strong>Gear List Link:</strong> {gearListUrl}
            </p>
          )}
        </div>

        {/* Conditionally render based on gear list type */}
        {gearListType === "standard_rifle"
          ? renderStandardRifleTable()
          : renderDetailedGearTables()}
      </div>
    </>
  );
};

export default GearListPrint;
