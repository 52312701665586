import React, { useEffect, useState } from "react";
import SortableTable from "../components/sortable-table";
import EditClass from "./edit-class";
import AdminHeader from "./admin-header";
import API from "../../../api";

const fetchClassInfo = async () => {
  const response = await API.get("classes");
  return response;
};

const AdminClasses = () => {
  const [classInfo, setClassInfo] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showInactive, setShowInactive] = useState(false);

  useEffect(async () => {
    setLoading(true);
    const _classInfo = await fetchClassInfo();
    const _classInfoWithIds = _classInfo.map((classItem) => ({
      id: classItem.class_id,
      ...classItem,
    }));
    setClassInfo(_classInfoWithIds);
    setLoading(false);
  }, []);

  const updateSelectedClass = (updatedClass) => {
    const newClassInfo = classInfo.map((classItem) => {
      if (classItem.class_id === updatedClass.class_id) {
        return {
          id: updatedClass.class_id,
          class_id: updatedClass.class_id,
          class_title: updatedClass.class_title,
          days: updatedClass.days,
          hours_per_day: updatedClass.hours_per_day,
          all_day: updatedClass.all_day,
          default_cost: updatedClass.default_cost,
          gear_list: updatedClass.gear_list,
          active: updatedClass.active,
          call_for_pricing: updatedClass.call_for_pricing,
          link: updatedClass.link,
        };
      }
      return classItem;
    });
    setClassInfo(newClassInfo);
  };

  const filteredClasses = showInactive
    ? classInfo
    : classInfo.filter((classItem) => classItem.active);

  const headers = [
    { key: "class_id", text: "ID" },
    { key: "class_title", text: "Class Type" },
    {
      key: "default_cost",
      text: "Default Cost",
      render: ({ default_cost, call_for_pricing }) =>
        call_for_pricing ? "Call for Pricing" : `$${default_cost}`,
    },
    {
      key: "gear_list",
      text: "Gear List",
      render: ({ gear_list }) =>
        gear_list === "none"
          ? "None"
          : gear_list === "long_range"
          ? "Long Range"
          : "AR15 Carbine",
    },
    {
      key: "active",
      text: "Status",
      render: ({ active }) =>
        active ? (
          <span className="text-success">Active</span>
        ) : (
          <span className="text-danger">Inactive</span>
        ),
    },
  ];

  return (
    <>
      <AdminHeader title="Edit Classes">
        Stats on available details. This will continue to improve with time. An
        entire list of newsletter emails is available to copy and paste into
        your email client.
      </AdminHeader>
      <div className="row section-padding">
        <div className="col-sm-1" />
        <div className="col-xs-12 col-sm-10">
          {selectedClass ? (
            <EditClass
              classInfo={selectedClass}
              updateSelectedClass={updateSelectedClass}
              back={() => setSelectedClass(null)}
            />
          ) : (
            <>
              <SortableTable
                headers={headers}
                data={filteredClasses}
                default_sort_column={headers[0].key}
                default_sort_direction="desc"
                extra_controls={
                  <div className="form-check d-flex align-items-center ms-3">
                    <input
                      className="form-check-input me-2 mt-0"
                      type="checkbox"
                      id="showInactiveCheck"
                      checked={showInactive}
                      onChange={(e) => setShowInactive(e.target.checked)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="showInactiveCheck"
                    >
                      Show Inactive Classes
                    </label>
                  </div>
                }
                onRowClick={(row) => setSelectedClass(row)}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminClasses;
