import React from "react";
import { Link } from "react-router-dom";
import JumbotronHeader from "./components/jumbotron-header";

const links = [];

const SiteMap = () => {
  return (
    <>
      <JumbotronHeader title="Site Map" links={links}>
        <div className="row">
          <h1 className="pbgt-yellow">Find the right page with the Site Map</h1>

          <div className="col-sm-6">
            <h2>Indoor &amp; Outdoor Training</h2>

            <ul>
              <li>
                <Link
                  to="/classes-overview"
                  title="Overview of all Classes Available"
                >
                  All Classes Available
                </Link>
              </li>

              <li>
                <Link
                  to="/precision-rifle-training"
                  title="750 Yard Precision Rifle Training"
                >
                  750 Yard Precision Rifle
                </Link>
              </li>
              <li>
                <Link
                  to="/mn-carry-permit"
                  title="Minnesota Permit to Carry Classes Available"
                >
                  MN Permit To Carry
                </Link>
              </li>
              <li>
                <Link
                  to="/instructor-training"
                  title="Certified Firearms Instructor Training"
                >
                  Certified Firearms Instructor Training
                </Link>
              </li>
              <li>
                <Link
                  to="/reloading-intro"
                  title="Introduction to Reloading Classes Available"
                >
                  Introduction to Reloading
                </Link>
              </li>

              <li>
                <Link
                  to="/vehicle-tactics"
                  title="Vehicle Tactics Firearms Training Classes"
                >
                  Vehicle Tactics
                </Link>
              </li>
              <li>
                <Link
                  to="/lowlight-engagements"
                  title="Low Light Engagements Firearms Training Classes"
                >
                  Low Light Engagements
                </Link>
              </li>

              <li>
                <Link
                  to="/intermediate-pistol-training"
                  title="Intermediate Pistol Training at the Outdoor Range"
                >
                  Intermediate Pistol
                </Link>
              </li>
              <li>
                <Link
                  to="/advanced-pistol-training"
                  title="Advanced Pistol Training at our Private Outdoor Range"
                >
                  Advanced Pistol
                </Link>
              </li>
              <li>
                <Link
                  to="/ar15-training"
                  title="AR15 Rifle Training at our Outdoor Dedicated Range"
                >
                  Basic AR15
                </Link>
              </li>
              <li>
                <Link
                  to="/ar15-advanced-training"
                  title="Advanced AR15 Rifle Training at our Outdoor Dedicated Range"
                >
                  Advanced AR15
                </Link>
              </li>
              <li>
                <Link
                  to="/shotgun-self-defense-training"
                  title="Shotgun Self Defense in and around the Home"
                >
                  Shotgun Self Defense
                </Link>
              </li>
              <li>
                <Link
                  to="/climbing-tower"
                  title="Five Station 43 Foot Tower of Pure Adrenaline"
                >
                  Rappelling Tower
                </Link>
              </li>
              <li>
                <Link
                  to="/women-pistol-training"
                  title="Women Only Pistol Training Outdoors"
                >
                  Women Only
                </Link>
              </li>
              <li>
                <Link
                  to="/outdoor-student-shoot-night"
                  title="Outdoor Student Shoot Night Exclusive Training Events"
                >
                  Outdoor Student Shoot Night
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-sm-6">
            <h2>Outdoor Training Facility</h2>
            <ul>
              <li>
                <Link
                  to="/outdoor-facility"
                  title="Outdoor Training Facility Features"
                >
                  Outdoor Training Facility Features
                </Link>
              </li>
            </ul>

            <h2>Upcoming Training Events</h2>
            <ul>
              <li itemScope="" itemType="https://schema.org/Event">
                <Link
                  itemProp="url"
                  to="/advanced-pistol-2-day-training"
                  title="2 Day Advanced Pistol"
                >
                  <span itemProp="name">2 Day Advanced Pistol</span> on{" "}
                  <span itemProp="startDate" content="2024-07-26T09:00:00-0500">
                    Jul 26th
                  </span>
                </Link>
                <meta
                  itemProp="description"
                  content="This 2 Day Advanced Pistol class is designed for 16 actual hours of training. Every pistol course starts with the fundamentals for accurate pistol shooting. Then we progress through more advanced exercises including moving &amp; shooting, single handed operation, use of cover, and tactics. Stages of exercises designed specifically to test multiple scenarios will be used. These stages have many strategies that can be used to navigate successfully and we walk you through the various tactics involved. We also push the mental end of training by adding stress such as timed exercises, physical challenges and multi tasking.rnrnrnWe provide multiple instructors, targets, food, beverages and lodging - all included in the class fee. You'll use every pistol range, the camping area, and bunkhouse. You don't need to leave the facility for the 2 days your training!rnrn"
                />
                <meta itemProp="endDate" content="2024-07-27T17:00:00-0500" />
                <div
                  itemProp="location"
                  itemScope=""
                  itemType="https://schema.org/LocalBusiness"
                >
                  <meta
                    itemProp="name"
                    content="PointBlank Gun Training Private Outdoor Range"
                  />
                  <meta itemProp="address" content="Pillager, MN" />
                </div>
              </li>
              <li itemScope="" itemType="https://schema.org/Event">
                <Link
                  itemProp="url"
                  to="/reloading-intro"
                  title="Introduction to Reloading"
                >
                  <span itemProp="name">Introduction to Reloading</span> on{" "}
                  <span itemProp="startDate" content="2024-07-30T18:00:00-0500">
                    Jul 30th
                  </span>
                </Link>
                <meta
                  itemProp="description"
                  content="This introductory class is intended to take the fear out of reloading handgun and Rifle ammunition. Students will learn what is required to load safe and consistent cartridges.rnrnrnThis is a hands-on class, with all supplies included.rn"
                />
                <meta itemProp="endDate" content="2024-07-30T22:00:00-0500" />
                <div
                  itemProp="location"
                  itemScope=""
                  itemType="https://schema.org/LocalBusiness"
                >
                  <meta
                    itemProp="name"
                    content="PointBlank Gun Training Private Outdoor Range"
                  />
                  <meta itemProp="address" content="Pillager, MN" />
                </div>
              </li>
              <li itemScope="" itemType="https://schema.org/Event">
                <Link
                  itemProp="url"
                  to="/vehicle-tactics"
                  title="Vehicle Tactics"
                >
                  <span itemProp="name">Vehicle Tactics</span> on{" "}
                  <span itemProp="startDate" content="2024-08-03T09:00:00-0500">
                    Aug 3rd
                  </span>
                </Link>
                <meta
                  itemProp="description"
                  content="This class is designed for the ccw student and self-defense enthusiast. Courses of fire include shooting from a vehicle, dealing with passengers, shooting thru doors, shooting different types of glass, exit strategies, use of cover, and much more. rnrnThis is NOT a beginner class!"
                />
                <meta itemProp="endDate" content="2024-08-03T15:00:00-0500" />
                <div
                  itemProp="location"
                  itemScope=""
                  itemType="https://schema.org/LocalBusiness"
                >
                  <meta
                    itemProp="name"
                    content="PointBlank Gun Training Private Outdoor Range"
                  />
                  <meta itemProp="address" content="Pillager, MN" />
                </div>
              </li>
              <li itemScope="" itemType="https://schema.org/Event">
                <Link
                  itemProp="url"
                  to="/mn-carry-permit"
                  title="MN Permit to Carry Class"
                >
                  <span itemProp="name">MN Permit to Carry Class</span> on{" "}
                  <span itemProp="startDate" content="2024-08-04T10:00:00-0500">
                    Aug 4th
                  </span>
                </Link>
                <meta
                  itemProp="description"
                  content="This class will allow you to apply for your MN permit to carry a pistol through the sheriff department.rn           Class length is approximately 7 hours and completed in one day.  We want to foster a great learning environmentrn           so in addition to our classroom, we have a heated indoor shooting area and our class is all inclusive.rn           rn           Our class is fast paced and the most practical information you will find.rn           We will teach you about gun safety, purchasing a pistol, information on carrying it on or about you,rn           laws about travel, storage, self-defense, selling, and more. Upon completion of this course, you can attendrn           our Winter Student Shoot Nights and Outdoor Student Shoot Nights."
                />
                <meta itemProp="endDate" content="2024-08-04T17:00:00-0500" />
                <div
                  itemProp="location"
                  itemScope=""
                  itemType="https://schema.org/LocalBusiness"
                >
                  <meta
                    itemProp="name"
                    content="PointBlank Gun Training Indoor Range and Classroom"
                  />
                  <meta
                    itemProp="address"
                    content="4859 Red River Trail SW Pillager, MN 56473"
                  />
                </div>
              </li>
              <li itemScope="" itemType="https://schema.org/Event">
                <Link
                  itemProp="url"
                  to="/precision-rifle-training"
                  title="Precision Rifle"
                >
                  <span itemProp="name">Precision Rifle</span> on{" "}
                  <span itemProp="startDate" content="2024-08-13T09:00:00-0500">
                    Aug 13th
                  </span>
                </Link>
                <meta
                  itemProp="description"
                  content="You will learn confidence in your equipment at a multitude of distances. Our range is capable from 0 to 750 yards and you will progress through all distances you can safely and accurately shoot in this class. With many topics including ammo selection, trajectory, Coriolis effects and Spin Drift. rnrnThe class is segmented into logical steps to help you improve accuracy starting at 100 yards and working up to 750 yards. rnrnThis class will be hosted outdoors, so please check the gear list for required items.rn"
                />
                <meta itemProp="endDate" content="2024-08-13T17:00:00-0500" />
                <div
                  itemProp="location"
                  itemScope=""
                  itemType="https://schema.org/LocalBusiness"
                >
                  <meta
                    itemProp="name"
                    content="PointBlank Gun Training Private Outdoor Range"
                  />
                  <meta itemProp="address" content="Pillager, MN" />
                </div>
              </li>
              <li itemScope="" itemType="https://schema.org/Event">
                <Link
                  itemProp="url"
                  to="/ar15-advanced-training"
                  title="Advanced AR15 Training"
                >
                  <span itemProp="name">Advanced AR15 Training</span> on{" "}
                  <span itemProp="startDate" content="2024-08-20T09:00:00-0500">
                    Aug 20th
                  </span>
                </Link>
                <meta
                  itemProp="description"
                  content="Our Advanced AR 15 training class is the next step for students familiar with this weapons platform. After a quick range briefing, students will warm-up with skill on demand exercises, then progress into speed drills while maintaining accuracy.rnrnrnShooting drills will evolve in complexity throughout this course, from cover, vehicle tactics, room clearing, and manipulating a long gun in confined spaces are covered.rn"
                />
                <meta itemProp="endDate" content="2024-08-20T17:00:00-0500" />
                <div
                  itemProp="location"
                  itemScope=""
                  itemType="https://schema.org/LocalBusiness"
                >
                  <meta
                    itemProp="name"
                    content="PointBlank Gun Training Private Outdoor Range"
                  />
                  <meta itemProp="address" content="Pillager, MN" />
                </div>
              </li>
              <li itemScope="" itemType="https://schema.org/Event">
                <Link
                  itemProp="url"
                  to="/advanced-pistol-training"
                  title="Advanced Pistol"
                >
                  <span itemProp="name">Advanced Pistol</span> on{" "}
                  <span itemProp="startDate" content="2024-08-24T09:00:00-0500">
                    Aug 24th
                  </span>
                </Link>
                <meta
                  itemProp="description"
                  content="Upon completion of our intermediate handgun training, you can register for advanced training. We will take many of the basic and intermediate skills and contort them into advanced skills. This class is designed to push your practical skills. Drills are designed to make you think outside of the box. It is fast paced and intended to mentally and physically push the student. Awareness and observation will be a key to success in this course. Expect to shoot 300 rounds. rnrnThis class will be hosted outdoors, so please check the gear list for required items."
                />
                <meta itemProp="endDate" content="2024-08-24T17:00:00-0500" />
                <div
                  itemProp="location"
                  itemScope=""
                  itemType="https://schema.org/LocalBusiness"
                >
                  <meta
                    itemProp="name"
                    content="PointBlank Gun Training Private Outdoor Range"
                  />
                  <meta itemProp="address" content="Pillager, MN" />
                </div>
              </li>{" "}
            </ul>
          </div>
        </div>
      </JumbotronHeader>

      <div className="container-fluid bg-grey section-padding">
        <div className="row">
          <div className="col-sm-6">
            <h2>Complete Schedule of Training Events</h2>

            <ul>
              <li>
                <Link to="/schedule" title="Schedule of Training Events">
                  Training Schedule Events
                </Link>
              </li>
              <li>
                <Link
                  to="/schedule/carry-classes"
                  title="Schedule for our Permit to Carry Class dates."
                >
                  Carry Permit Classes
                </Link>
              </li>
              <li>
                <Link
                  to="/schedule/pistol-classes"
                  title="Schedule for our Pistol class dates for beginner to advanced including multiple day classes."
                >
                  Pistol Classes
                </Link>
              </li>
              <li>
                <Link
                  to="/schedule/rifle-classes"
                  title="Schedule for our AR15, Shotgun and Precision Rifle class dates."
                >
                  Rifle Classes
                </Link>
              </li>
              <li>
                <Link
                  to="/schedule/tower-classes"
                  title="Schedule for our upcoming Rappelling Tower class dates."
                >
                  Tower Classes
                </Link>
              </li>
            </ul>

            <h3>Galleries of Past Training Events</h3>
            <ul>
              <li>
                <Link
                  to="/photo-gallery"
                  title="Photo Gallery of Previous Training Events"
                >
                  Photo Gallery of Past Training Events
                </Link>
              </li>
              <li>
                <Link
                  to="/video-gallery"
                  title="Video Gallery of Previous Training Events"
                >
                  Video Gallery of Past Training Events
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-sm-6">
            <h2>About PointBlank Instructors</h2>

            <ul>
              <li>
                <Link
                  to="/instructors"
                  title="About PointBlank Instructors, Brian, Jaime and Vance"
                >
                  About the Instructors
                </Link>
              </li>
            </ul>

            <h2>Contact Information &amp; Driving Directions</h2>

            <ul>
              <li>
                <Link
                  to="/contact"
                  title="Contact PointBlank with all your questions"
                >
                  Contact PointBlank
                </Link>
              </li>
              <li>
                <Link
                  to="/map"
                  title="Map and Driving Directions to PointBlank Indoor Shooting Range and Classroom"
                >
                  Map and Directions to PointBlank Indoor Range and Classroom
                </Link>
              </li>
              <li>
                <Link
                  to="/policies"
                  title="PointBlank policies, terms and conditions"
                >
                  PointBlank Policies
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SiteMap;
