import React from "react";
import { Link } from "react-router-dom";
import JumbotronHeader from "../components/jumbotron-header";
import Testimonials from "../components/testimonials";
import WhyPointBlank from "../components/why-pointblank";
import Payment from "../components/payment";
import Pricing from "../components/pricing";
import UpcomingClassDates from "../components/upcoming-class-dates";
import { filter_schedule, getClassCost } from "../../../common";
import { useSchedule } from "../../../schedule-provider";
import SignupButtons from "../components/signup-buttons";
import RangeRulesExpectations from "../components/range-rules-expectations";

const links = [
  { title: "Schedule", link: "#schedule" },
  { title: "Sign Up", link: "#sign-up" },
  { title: "Testimonials", link: "#testimonials" },
];

const CLASS_ID = 27;

const AdvancedAR15Training = () => {
  const { schedule, activeClasses } = useSchedule();
  const dates = filter_schedule(schedule, CLASS_ID);
  const cost = getClassCost(activeClasses, CLASS_ID);

  return (
    <>
      <JumbotronHeader title="Advanced AR15 Carbine Training" links={links}>
        <div className="row">
          <h1 className="pbgt-yellow">Advanced AR15 Carbine Training</h1>
          <div className="col-md-6 col-lg-6">
            <p className="pbgt-body-text">
              Our Advanced AR15 Carbine training class is the next step for
              students familiar with this weapons platform. Shooting drills will
              evolve in complexity throughout this course, from cover, vehicle
              tactics, room clearing, chaos drills, and manipulating a carbine
              in confined spaces are covered.
            </p>

            <p className="pbgt-body-text">
              This is an outdoor course. Students should prepare for weather
              conditions appropriate for the season.
            </p>

            <SignupButtons class_id={CLASS_ID} />
          </div>
          <div className="col-md-6 col-lg-6 pbgt-gear-list">
            <h2>Gear List</h2>
            <ul className="pbgt-body-text">
              <li>AR15 or equivalent rifle</li>
              <li>Single, Double or 3-Point Sling</li>
              <li>Electronic Ear Muffs preferred</li>
              <li>Safety Glasses</li>
              <li>Ball Cap</li>
              <li>Minimum 3 magazines</li>
              <li>Weather Appropriate Clothing</li>
              <li>300 to 500 rounds of ammo</li>
              <li>
                <strong className="pbgt-yellow">
                  NO Green Tip or Armor Piercing Ammo
                </strong>
              </li>
              <li>Magazine Pouch</li>
              <li>Backup rifle optional</li>
            </ul>
          </div>
        </div>
      </JumbotronHeader>

      <RangeRulesExpectations />

      <UpcomingClassDates
        title="Advanced AR15 Carbine Training"
        description="Advanced AR15 Carbine Training"
        dates={dates}
        cost={cost}
      />

      <Payment dates={dates} />

      <Testimonials />
    </>
  );
};

export default AdvancedAR15Training;
