import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import AdminHeader from "./admin-header";
import Loading from "../components/loading";
import SortableTable from "../components/sortable-table";
import API from "../../../api";
import { createToast } from "./common";
import { handleApiError } from "./error-utils";

const convertSignupsData = (data) => {
  return data.map((entry) => {
    entry.start_date = new Date(entry.start_date);
    entry.timestamp = new Date(entry.timestamp);
    return entry;
  });
};

let args = {};
const setArgs = (key, value) => {
  args[key] = value;
};
const removeArg = (key) => {
  delete args[key];
};
const reduceArgs = () => {
  return Object.keys(args).map((key) => `${key}=${args[key]}`);
};

const fetchSignups = async () => {
  const response = await API.get("signups", reduceArgs());
  return convertSignupsData(response.data);
};

const RemoveFromClass = ({ data, extra }) => {
  const removeStudent = async () => {
    try {
      await API.delete(`signups/${data.signupid}`);
      createToast("Student removed from class");
      extra.forceUpdate();
    } catch (error) {
      handleApiError(error, createToast, "Error removing student from class");
    }
  };

  return (
    <button className="btn btn-danger btn-sm" onClick={removeStudent}>
      Remove from class
    </button>
  );
};

const ExtraControls = ({ loading, setLoading, setSignups }) => {
  const [omitRemoveFromClass, setOmitRemoveFromClass] = useState(false);
  const updateOmitRemoveFromClass = (e) => {
    if (e.target.checked) {
      setArgs("omitRemoveFromClass", 1);
    } else {
      removeArg("omitRemoveFromClass");
    }

    setOmitRemoveFromClass(e.target.checked);
  };

  useEffect(async () => {
    // Only fetch the schedule if the omitRemoveFromClass state changes and data is not loading
    if (!loading) {
      setLoading(true);
      try {
        const response = await fetchSignups();
        setSignups(response);
      } catch (error) {
        handleApiError(error, createToast, "Error fetching signups");
        setSignups([]);
      }
      setLoading(false);
    }
  }, [omitRemoveFromClass]);

  return (
    <>
      <div className="spacer" />
      <label>Omit "Remove from class":</label>
      <input
        type="checkbox"
        onChange={updateOmitRemoveFromClass}
        style={{ position: "relative", top: 2 }}
      />
      &nbsp;&nbsp;
      {loading ? (
        <Loading
          height={20}
          width={40}
          paddingTop={0}
          paddingRight={0}
          marginTop={-2}
          marginLeft={45}
          fontSize={20}
        />
      ) : null}
    </>
  );
};

const AdminSignups = () => {
  const [signups, setSignups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [_forceUpdate, setForceUpdate] = useState(false);

  const forceUpdate = () => {
    setForceUpdate(!_forceUpdate);
  };

  const headers = [
    { text: "ID", key: "signupid" },
    { text: "Class Title", key: "class_title" },
    { text: "Class Date", key: "start_date" },
    { text: "First Name", key: "firstn" },
    { text: "Last Name", key: "lastn" },
    { text: "Size", key: "students" },
    {
      text: "Paid",
      key: "paid",
      render: (row) => `$${row.paid.toFixed(2)}`,
    },
    {
      text: "Remaining",
      key: "remaining",
      render: (row) => `$${row.remaining.toFixed(2)}`,
    },
    { text: "Sign Up Time", key: "timestamp" },
    {
      text: "Actions",
      key: "actions",
      render: (row, extra) => {
        if (row.class_title === "Remove from class") {
          return null;
        }
        return <RemoveFromClass data={row} extra={extra} />;
      },
    },
  ];

  useEffect(async () => {
    args = {};
  }, []);

  useEffect(async () => {
    setLoading(true);
    try {
      const response = await API.get("signups", reduceArgs());
      const signups = convertSignupsData(response.data);
      setSignups(signups);
    } catch (error) {
      handleApiError(error, createToast, "Error fetching signups");
      setSignups([]);
    }
    setLoading(false);
  }, [_forceUpdate]);

  return (
    <>
      <AdminHeader title="Student Signups">
        View and Edit the upcoming class schedule. This shows a list of the
        upcoming classes, ordered by class date. Editing a class date includes
        total students, cost, date and class type. Printing of a class student
        roster list is also available. Student signups can be edited/created for
        each class date.
      </AdminHeader>
      <div className="row section-padding">
        <div className="col-sm-1" />
        <div className="col-xs-12 col-sm-10">
          <SortableTable
            headers={headers}
            data={signups}
            default_sort_column={headers[2].key}
            extra_controls={
              <ExtraControls
                loading={loading}
                setLoading={setLoading}
                setSignups={setSignups}
              />
            }
            forceUpdate={forceUpdate}
          />
        </div>
      </div>
    </>
  );
};

export default AdminSignups;
