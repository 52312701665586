import React from "react";
import { Link } from "react-router-dom";

import { useSchedule } from "../../../schedule-provider";
import JumbotronHeader from "../components/jumbotron-header";
import Testimonials from "../components/testimonials";
import WhyPointBlank from "../components/why-pointblank";
import Payment from "../components/payment";
import Pricing from "../components/pricing";
import UpcomingClassDates from "../components/upcoming-class-dates";
import { filter_schedule, getClassCost } from "../../../common";
import SignupButtons from "../components/signup-buttons";
import RangeRulesExpectations from "../components/range-rules-expectations";

const links = [
  { title: "Topics", link: "#topics" },
  { title: "Schedule", link: "#schedule" },
  { title: "Sign Up", link: "#sign-up" },
  { title: "Testimonials", link: "#testimonials" },
];

const CLASS_ID = 18;

const TwoDayAdvancedPistol = () => {
  const { schedule, activeClasses } = useSchedule();
  const dates = filter_schedule(schedule, CLASS_ID);
  const cost = getClassCost(activeClasses, CLASS_ID);

  return (
    <>
      <JumbotronHeader title="2 Day Advanced Pistol Training" links={links}>
        <div className="row">
          <h1 className="pbgt-yellow">2 Day Advanced Pistol Training</h1>
          <div className="col-md-6 col-lg-6">
            <p className="pbgt-body-text">
              Our 2 day Advanced Pistol class is designed to bring a Carry
              Permit Holder's, LEO's, or handgun enthusiast's skill set to a new
              level. Students will be pushed to balance speed with accuracy
              while learning tactics and techniques such as single-handed
              operation, use of cover, multi-stage scenarios, corner work and
              room clearing, timed exercises shooting in/around vehicles, actual
              lowlight and more.
            </p>
            <p className="pbgt-body-text">
              <strong className="pbgt-yellow">
                What students should expect:{" "}
              </strong>
              You will be exhausted at the end of day one (training ends at
              approximately 10pm).
            </p>
            <p className="pbgt-body-text">
              Students can expect hearty meals, beverages and snacks, as well as
              lodging in our timberframed lodge with full amenities - all at no
              additional cost!
            </p>
            <p className="pbgt-body-text">
              This class will be hosted outdoors, so please check the gear list
              for required items.
            </p>
            <SignupButtons class_id={CLASS_ID} />
          </div>
          <div className="col-md-6 pbgt-gear-list pbgt-body-text">
            <h2>Gear List</h2>
            <ul>
              <li>Usable Safe Holster</li>
              <li>Weapon-Mounted Light</li>
              <li>Electronic Ear Muffs preferred</li>
              <li>Handgun</li>
              <li>Multiple magazines</li>
              <li>Weather Appropriate Clothing</li>
              <li>600 to 800 Rounds of Ammo</li>
              <li>Magazine Pouch</li>
              <li>Flashlight with usable holster</li>
              <li>Backup firearm optional</li>
            </ul>
          </div>
        </div>
      </JumbotronHeader>
      <div id="topics" className="container-fluid bg-grey section-padding">
        <div className="row">
          <div className="col-sm-4">
            <h2>Covered Topics:</h2>
            <ul className="pbgt-body-text">
              <li>Multi-stage Scenarios</li>
              <li>Actual Lowlight</li>
              <li>Room Clearing</li>
              <li>Working Around Walls</li>
              <li>Shooter Diagnosis</li>
              <li>Timed Exercises</li>
              <li>Multi-target engagements</li>
              <li>Shooting &amp; Moving</li>
              <li>Use of Cover</li>
              <li>Moving Targets</li>
              <li>Tricks and Tips</li>
              <li>Equipment Effectiveness</li>
              <li>Shooting Fundamentals</li>
            </ul>
          </div>
          <div className="col-sm-8">
            <img
              src="/images/slides/advanced-pistol-1.jpg"
              data-src="/images/slides/advanced-pistol-1.jpg"
              alt="2 Day Advanced Pistol Training Class at PointBlank Gun Training"
              className="img-fluid"
            />
            <p className="pbgt-body-text">
              <b>Day 2:</b> Students rise to coffee, orange juice and a hearty
              breakfast. Following a warm-up and quick recap of the previous
              day's exercises, we move on to new material. Day 2 ends at
              approximately 4:30 PM with student certificates issued.
            </p>
          </div>
        </div>
      </div>

      <RangeRulesExpectations />

      <UpcomingClassDates
        title="2 Day Advanced Pistol Training"
        description="2 Day Advanced Pistol Training"
        dates={dates}
        cost={cost}
      />

      <Payment dates={dates} />

      <Testimonials />
    </>
  );
};

export default TwoDayAdvancedPistol;
