import React from "react";
import { Link } from "react-router-dom";
import JumbotronHeader from "../components/jumbotron-header";
import Testimonials from "../components/testimonials";
import WhyPointBlank from "../components/why-pointblank";
import Payment from "../components/payment";
import Pricing from "../components/pricing";
import UpcomingClassDates from "../components/upcoming-class-dates";
import { useSchedule } from "../../../schedule-provider";
import { filter_schedule, getClassCost } from "../../../common";
import SignupButtons from "../components/signup-buttons";
import RangeRulesExpectations from "../components/range-rules-expectations";

const links = [
  { title: "Schedule", link: "#schedule" },
  { title: "Sign Up", link: "#sign-up" },
  { title: "Testimonials", link: "#testimonials" },
];

const CLASS_ID = 15;

const LowLightEngagements = () => {
  const { schedule, activeClasses } = useSchedule();
  const dates = filter_schedule(schedule, CLASS_ID);
  const cost = getClassCost(activeClasses, CLASS_ID);

  return (
    <>
      <JumbotronHeader title="Lowlight Engagements" links={links}>
        <div className="row">
          <h1 className="pbgt-yellow">Lowlight Engagements</h1>
          <div className="col-md-6 col-lg-6">
            <p className="pbgt-body-text">
              Six months out of twelve, MN Carry Permit Holders go to and from
              work in the dark. This is NOT a beginner class and students should
              have a solid drawstroke and understanding of handgun fundamentals
              including safety. We recommend taking our{" "}
              <Link to="/intermediate-pistol-training" className="pbgt-yellow">
                Intermediate Pistol
              </Link>{" "}
              class before attending this class.
            </p>
            <p className="pbgt-body-text">
              Students will shoot multiple courses of fire using weapon-mounted
              lights and handheld flashlights, learn handheld light techniques,
              and effective scanning.
            </p>
            <p className="pbgt-body-text">
              This class will be hosted outdoors, so please check the gear list
              for required items.
            </p>
            <p className="pbgt-body-text">
              Learn to read ligting conditions and ways to deal with different
              lighting scenarios. The pros and cons of weapon-mounted lights and
              designs of flashlights will also be covered. Experience lowlight
              muzzle flash with multiple types of firearms and ammunition.
            </p>
            <p className="pbgt-body-text">
              If you carry a handgun for self-defense YOU NEED this class.{" "}
            </p>
            <p className="pbgt-body-text">
              This is an outdoor course. Students should prepare for weather
              conditions appropriate for the season.
            </p>
            <SignupButtons class_id={CLASS_ID} />
          </div>
          <div className="col-md-6 pbgt-gear-list pbgt-body-text">
            <h2>Gear List</h2>
            <ul>
              <li>Usable Safe Holster</li>
              <li>Flashlight and Holster/Pouch</li>
              <li>Weapons Light</li>
              <li>Electronic Ear Muffs preferred</li>
              <li>Handgun</li>
              <li>Multiple magazines</li>
              <li>Weather Appropriate Clothing</li>
              <li>250 rounds ammo</li>
              <li>Magazine Pouch</li>
              <li>Safety Glasses</li>
              <li>Backup firearm optional</li>
            </ul>
          </div>
        </div>
      </JumbotronHeader>

      <RangeRulesExpectations />

      <UpcomingClassDates
        title="Lowlight Engagements"
        description="Lowlight Engagements"
        dates={dates}
        cost={cost}
      />

      <Payment dates={dates} />

      <Testimonials />
    </>
  );
};

export default LowLightEngagements;
