import React from "react";
import AdminHeader from "./admin-header";

const AdminHome = () => {
  return (
    <>
      <AdminHeader title="Admin">
        Welcome to the new and improved admin page! Here you can edit classes,
        schedules, and student signups. Just click on the links in the bar above
        to get started.
      </AdminHeader>
    </>
  );
};

export default AdminHome;
