import Home from "./pages/home";
import Schedule from "./pages/schedule";
import InstructorTraining from "./pages/classes/instructor-training";
import MNPermitToCarry from "./pages/classes/mn-permit-to-carry";
import VehicleTactics from "./pages/classes/vehicle-tactics";
import LowLightEngagements from "./pages/classes/low-light-engagements";
import IntermediatePistol from "./pages/classes/intermediate-pistol";
import AdvancedPistol from "./pages/classes/advanced-pistol";
import RedDotPistol from "./pages/classes/red-dot-pistol";
import TwoDayAdvancedPistol from "./pages/classes/2-day-advanced-pistol";
import SevenFiftyRifle from "./pages/classes/750-yard-prt";
import FiveHundredRifle from "./pages/classes/500-yard-prt";
import TwoDaySevenFiftyRifle from "./pages/classes/2-day-750-yard-prt";
import SevenFiftyAdvanced from "./pages/classes/750-yard-advanced-prt";
import AR15Training from "./pages/classes/ar15-training";
import AdvancedAR15Training from "./pages/classes/advanced-ar15-training";
import ShotgunSelfDefense from "./pages/classes/shotgun-self-defense";
import POSTCredits from "./pages/classes/post-credits";
import OutdoorRange from "./pages/outdoor-range";
import Podcast from "./pages/podcast";
import VideoGallery from "./pages/video-gallery";
import PhotoGallery from "./pages/photo-gallery";
import Instructors from "./pages/instructors";
import Map from "./pages/map";
import ContactUs from "./pages/contact-us";
import SiteMap from "./pages/site-map";
import Policies from "./pages/policies";
import AdminLogin from "./pages/admin/login";
import AdminHome from "./pages/admin/home";
import AdminStats from "./pages/admin/stats";
import AdminSchedule from "./pages/admin/schedule";
import AdminSignups from "./pages/admin/signups";
import AdminClasses from "./pages/admin/classes";
import ThankYou from "./pages/thank-you";
import GearEntry from "./pages/gear-entry";
import GearSubmissionSuccess from "./pages/gear-submission-success";
import GearListPrint from "./pages/admin/gear-list-print";
import ClassStudents from "./pages/admin/class-students";
import VerifyEmail from "./pages/verify-email";
import PrivateSignup from "./pages/private-signup";

export const findRoute = (label) => {
  for (const nav_link of nav_links) {
    if (nav_link.dropdown) {
      for (const dropdown_link of nav_link.dropdown) {
        if (dropdown_link.label?.includes(label)) {
          return dropdown_link.path;
        }
      }
    } else {
      if (nav_link.label?.includes(label)) {
        return nav_link.path;
      }
    }
  }
  return null;
};

export const nav_links = [
  { path: "/", label: "Home" },
  {
    label: "Schedule",
    path: "/schedule",
  },
  {
    label: "Classes",
    dropdown: [
      // --------------------------------------------------------------
      { header: "Specialty Classes" },
      {
        path: "/instructor-training",
        label: "Certified Firearms Instructor Training",
      },
      // --------------------------------------------------------------
      { header: "Indoor Heated Range" },
      { path: "/mn-carry-permit", label: "MN Permit to Carry" },
      // --------------------------------------------------------------
      { header: "Outdoor Pistol Classes" },
      { path: "/red-dot-pistol-training", label: "NEW! Red Dot Pistol" },
      {
        path: "/advanced-pistol-2-day-training",
        label: "2-Day Advanced Pistol",
      },
      {
        path: "/advanced-pistol-training",
        label: "Advanced Pistol",
      },
      {
        path: "/intermediate-pistol-training",
        label: "Intermediate Pistol",
      },
      { path: "/lowlight-engagements", label: "Low Light Engagements" },
      { path: "/vehicle-tactics", label: "Vehicle Tactics" },
      // --------------------------------------------------------------
      { header: "PRS Rifle up to 750 Yards!" },
      {
        path: "/2-day-precision-rifle-training",
        label: "2-Day Precision Rifle",
      },
      { path: "/500-yard-prt", label: "NEW! 500-Yard Precision Rifle" },
      {
        path: "/precision-rifle-training",
        label: "750-Yard Precision Rifle",
      },
      {
        path: "/advanced-precision-rifle-training",
        label: "Advanced Precision Rifle",
      },
      // --------------------------------------------------------------
      { header: "Outdoor Rifle Classes" },
      { path: "/ar15-training", label: "AR15 Carbine" },
      {
        path: "/ar15-advanced-training",
        label: "Advanced AR15 Carbine",
      },
      {
        path: "/shotgun-self-defense-training",
        label: "Shotgun Self Defense",
      },
      // --------------------------------------------------------------
      { header: "Law Enforcement Classes" },
      { path: "/law-enforcement", label: "P.O.S.T. Credit Classes" },
    ],
  },
  { path: "/outdoor-facility", label: "Range" },
  { path: "/podcast", label: "Podcast" },
  {
    label: "Gallery",
    dropdown: [
      { path: "/video-gallery", label: "Video Gallery" },
      { path: "/photo-gallery", label: "Photo Gallery" },
    ],
  },
  {
    label: "About",
    dropdown: [
      //{ path: "/instructors", label: "Instructors" },
      { path: "/map", label: "Map" },
      { path: "/contact", label: "Contact" },
    ],
  },
];

export const pages = [
  { path: "/", component: Home, class_id: null },
  { path: "/schedule", component: Schedule, class_id: null },
  {
    path: "/instructor-training",
    component: InstructorTraining,
    class_id: 29,
  }, // Firearms Instructor Training
  { path: "/mn-carry-permit", component: MNPermitToCarry, class_id: 1 }, // MN Permit to Carry Class
  { path: "/vehicle-tactics", component: VehicleTactics, class_id: 16 }, // Vehicle Tactics
  {
    path: "/lowlight-engagements",
    component: LowLightEngagements,
    class_id: 15,
  }, // Low Light Engagements
  {
    path: "/intermediate-pistol-training",
    component: IntermediatePistol,
    class_id: 4,
  }, // Intermediate Pistol
  {
    path: "/advanced-pistol-training",
    component: AdvancedPistol,
    class_id: 12,
  }, // Advanced Pistol
  {
    path: "/advanced-pistol-2-day-training",
    component: TwoDayAdvancedPistol,
    class_id: 18,
  }, // 2 Day Advanced Pistol
  {
    path: "/red-dot-pistol-training",
    component: RedDotPistol,
    class_id: 31,
  }, // Red Dot Pistol
  {
    path: "/500-yard-prt",
    component: FiveHundredRifle,
    class_id: 32,
  }, // 500 Yard Precision Rifle
  {
    path: "/precision-rifle-training",
    component: SevenFiftyRifle,
    class_id: 10,
  }, // Precision Rifle
  {
    path: "/2-day-precision-rifle-training",
    component: TwoDaySevenFiftyRifle,
    class_id: 23,
  }, // 2 Day Precision Rifle
  {
    path: "/advanced-precision-rifle-training",
    component: SevenFiftyAdvanced,
    class_id: 17,
  }, // Advanced Precision Rifle
  { path: "/ar15-training", component: AR15Training, class_id: 13 }, // AR15 Training
  {
    path: "/ar15-advanced-training",
    component: AdvancedAR15Training,
    class_id: 27,
  }, // Advanced AR15 Training
  {
    path: "/shotgun-self-defense-training",
    component: ShotgunSelfDefense,
    class_id: 5,
  }, // Shotgun Self Defense
  {
    path: "/law-enforcement",
    component: POSTCredits,
    class_id: 33,
  }, // P.O.S.T. Credit Classes
  { path: "/outdoor-facility", component: OutdoorRange, class_id: null }, // No exact match found
  { path: "/podcast", component: Podcast, class_id: null }, // No exact match found
  { path: "/video-gallery", component: VideoGallery, class_id: null }, // No exact match found
  { path: "/photo-gallery", component: PhotoGallery, class_id: null }, // No exact match found
  { path: "/instructors", component: Instructors, class_id: null }, // No exact match found
  { path: "/map", component: Map, class_id: null }, // No exact match found
  { path: "/contact", component: ContactUs, class_id: null }, // No exact match found
  { path: "/site-map", component: SiteMap, class_id: null }, // No exact match found
  { path: "/policies", component: Policies, class_id: null }, // No exact match found
  { path: "/thank-you", component: ThankYou, class_id: null },
  { path: "/gear-list/:linkId", component: GearEntry, class_id: null },
  {
    path: "/gear-submission-success",
    component: GearSubmissionSuccess,
    class_id: null,
  },
  { path: "/verify-email/:code", component: VerifyEmail, class_id: null },
  { path: "/private-signup/:uuid", component: PrivateSignup, class_id: null },
  { path: "/--admin", component: AdminHome, isAdmin: true, class_id: null }, // No exact match found
  {
    path: "/--admin-login",
    component: AdminLogin,
    isAdmin: true,
    class_id: null,
  }, // No exact match found
  //{ path: "/--admin-class", component: AdminClass, isAdmin: true, class_id: null }, // No exact match found
  {
    path: "/--admin-stats",
    component: AdminStats,
    isAdmin: true,
    class_id: null,
  }, // No exact match found
  {
    path: "/--admin-schedule",
    component: AdminSchedule,
    isAdmin: true,
    class_id: null,
  }, // No exact match found
  {
    path: "/--admin-signups",
    component: AdminSignups,
    isAdmin: true,
    class_id: null,
  }, // No exact match found
  {
    path: "/--admin-classes",
    component: AdminClasses,
    isAdmin: true,
    class_id: null,
  },
  {
    path: "/--admin-gear-list/:dateId/:classId",
    component: GearListPrint,
    isAdmin: true,
    class_id: null,
  },
  {
    path: "/--admin-class-students/:scheduleId",
    component: ClassStudents,
    isAdmin: true,
    class_id: null,
  },
];
