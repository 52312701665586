import React from "react";
import { ScheduleProvider } from "../schedule-provider";
import PaymentProvider from "./payment-provider";
import Header from "./header";
import Body from "./body";
import Footer from "./footer";

// functional component that renders the header, body, and footer components
const Layout = () => {
  return (
    <PaymentProvider>
      <ScheduleProvider>
        <Header />
        <Body />
        <Footer />
      </ScheduleProvider>
    </PaymentProvider>
  );
};

export default Layout;
