import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import JumbotronHeader from "./components/jumbotron-header";
import PBPaymentForm from "./components/payment-form";
import API from "../../api";
import Loading from "./components/loading";
import { formatCentralTime } from "../../common";

const links = [];

const PrivateSignup = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [scheduleData, setScheduleData] = useState(null);
  const [error, setError] = useState(null);
  const [payDepositOnly, setPayDepositOnly] = useState(false);
  const [renderPaymentForm, setRenderPaymentForm] = useState(false);
  const [studentCount, setStudentCount] = useState(1);
  const [billingContact, setBillingContact] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    postalCode: "",
    country: "US",
    amount: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await API.get(`schedule/byuuid/${uuid}`);

        if (!response.success) {
          setError("This signup link is invalid or has expired.");
          setLoading(false);
          return;
        }

        const data = response.data;

        // Calculate deposit amount - half the total cost rounded up to nearest dollar
        const calculatedDeposit = Math.ceil(parseFloat(data.total_cost) / 2);

        // Store both the original and calculated deposit
        data.calculated_deposit = calculatedDeposit;
        data.open_slots = data.total_students - data.current_students;

        setScheduleData(data);
        setBillingContact((prev) => ({
          ...prev,
          amount: calculatedDeposit,
        }));
      } catch (error) {
        setError("Error loading class information. Please try again later.");
        console.error("Error fetching private signup data:", error);
      }

      setLoading(false);
    };

    fetchData();
  }, [uuid]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBillingContact((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const updatePaymentAmount = (isDepositOnly, students) => {
    if (!scheduleData) return;

    if (isDepositOnly) {
      // Deposit amount * number of students
      setBillingContact((prev) => ({
        ...prev,
        amount: scheduleData.deposit_cost * students,
      }));
    } else {
      // Full payment amount * number of students
      setBillingContact((prev) => ({
        ...prev,
        amount: parseFloat(scheduleData.total_cost) * students,
      }));
    }
  };

  const handleStudentCountChange = (e) => {
    const count = parseInt(e.target.value);
    setStudentCount(count);
    updatePaymentAmount(payDepositOnly, count);
  };

  const togglePayDeposit = () => {
    setPayDepositOnly(!payDepositOnly);
    updatePaymentAmount(!payDepositOnly, studentCount);
  };

  const showPaymentForm = () => {
    setRenderPaymentForm(true);
  };

  const checkBillingInfo = () => {
    if (
      billingContact.firstName === "" ||
      billingContact.lastName === "" ||
      billingContact.email === "" ||
      billingContact.phone === "" ||
      billingContact.address === "" ||
      billingContact.city === "" ||
      billingContact.postalCode === ""
    ) {
      return false;
    }
    return true;
  };

  const handleContinueToPayment = () => {
    showPaymentForm();
  };

  if (loading) {
    return <Loading height={200} />;
  }

  if (error) {
    return (
      <JumbotronHeader title="Error">
        <div className="alert alert-danger">{error}</div>
      </JumbotronHeader>
    );
  }

  const paymentAmount = billingContact.amount;

  return (
    <JumbotronHeader title={`Sign Up for ${scheduleData.class_title}`}>
      <div className="row">
        <div className="col-sm-12">
          <div className="row mb-4">
            <div className="col-md-6">
              <h2>Class Details</h2>
              <p className="pbgt-body-text">
                <strong>Class:</strong> {scheduleData.class_title}
                <br />
                <strong>Start Date:</strong>{" "}
                {formatCentralTime(
                  new Date(scheduleData.start_date),
                  "dddd, MMMM D, YYYY h:mm A"
                )}
                <br />
                <strong>End Date:</strong>{" "}
                {formatCentralTime(
                  new Date(scheduleData.end_date),
                  "dddd, MMMM D, YYYY h:mm A"
                )}
                <br />
                <strong>Total Cost:</strong> $
                {parseFloat(scheduleData.total_cost).toFixed(2)}
                <br />
                <strong>Required Deposit:</strong> $
                {scheduleData.deposit_cost.toFixed(2)}
                <br />
                <strong>Available Slots:</strong> {scheduleData.open_slots}
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <h4>Your Information</h4>
              <div className="mb-3">
                <label htmlFor="firstName" className="form-label">
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  name="firstName"
                  value={billingContact.firstName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="lastName" className="form-label">
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  name="lastName"
                  value={billingContact.lastName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={billingContact.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Phone
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="phone"
                  name="phone"
                  value={billingContact.phone}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="col-md-6">
              <h4>Billing Information</h4>
              <div className="mb-3">
                <label htmlFor="address" className="form-label">
                  Address
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  name="address"
                  value={billingContact.address}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="city" className="form-label">
                  City
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="city"
                  name="city"
                  value={billingContact.city}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="postalCode" className="form-label">
                  Postal Code
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="postalCode"
                  name="postalCode"
                  value={billingContact.postalCode}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12">
              <div className="mb-3">
                <label htmlFor="studentCount" className="form-label">
                  Number of Students
                </label>
                <select
                  className="form-control"
                  id="studentCount"
                  value={studentCount}
                  onChange={handleStudentCountChange}
                >
                  {[...Array(scheduleData.open_slots)].map((_, i) => (
                    <option key={i + 1} value={i + 1}>
                      {i + 1} {i === 0 ? "student" : "students"}
                    </option>
                  ))}
                </select>
                <small className="form-text" style={{ color: "white" }}>
                  Select the total number of students attending (including
                  yourself).
                </small>
              </div>

              <h4>Payment</h4>
              <div className="mb-4">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="payDepositSwitch"
                    checked={payDepositOnly}
                    onChange={togglePayDeposit}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="payDepositSwitch"
                  >
                    Pay Deposit Only ($
                    {(scheduleData.deposit_cost * studentCount).toFixed(2)})
                  </label>
                </div>
                <div className="mt-2">
                  <strong>Payment Amount: </strong>${paymentAmount.toFixed(2)}
                  {payDepositOnly && (
                    <div>
                      Remaining balance: $
                      {(
                        parseFloat(scheduleData.total_cost) * studentCount -
                        scheduleData.deposit_cost * studentCount
                      ).toFixed(2)}
                    </div>
                  )}
                </div>
              </div>

              {!checkBillingInfo() ? (
                <p className="pbgt-body-text">
                  Please fill out all fields in order to process your payment
                  and reserve a spot in this class.
                </p>
              ) : (
                <>
                  <p className="pbgt-body-text">
                    <b>NOTE:</b> Your payment will be processed securely through
                    Square. We do not store any of your credit card information.
                  </p>

                  {!renderPaymentForm ? (
                    <button
                      type="button"
                      className="btn btn-default center-block btn-lg pbgt-yellow-btn"
                      onClick={handleContinueToPayment}
                    >
                      Proceed to Payment
                    </button>
                  ) : (
                    <PBPaymentForm
                      scheduleId={scheduleData.index}
                      numberOfStudents={studentCount}
                      paymentAmount={paymentAmount}
                      billingContact={billingContact}
                      _paymentDetails={{
                        amount: paymentAmount.toFixed(2),
                        class_name: scheduleData.class_title,
                        class_id: scheduleData.class_id,
                        product_url: window.location.href,
                        students: studentCount,
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </JumbotronHeader>
  );
};

export default PrivateSignup;
