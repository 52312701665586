import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { formatCentralTime } from "../../../common";

const SortableTable = ({
  headers,
  data,
  default_sort_column = null,
  default_sort_direction = "asc",
  extra_controls = null,
  onRowClick = null,
  isRowClickable = (row) => true,
  ...extra
}) => {
  const [sort, setSort] = useState({
    column: default_sort_column,
    direction: default_sort_direction,
  });
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [sortedData, setSortedData] = useState(data);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  // Change the number of rows per page
  const changeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
  };

  // Sort the table based on the column clicked
  const sortTable = (column) => {
    if (sort.column === column) {
      setSort({
        column,
        direction: sort.direction === "asc" ? "desc" : "asc",
      });
    } else {
      setSort({ column, direction: "asc" });
    }
  };

  const sortData = (_data = []) => {
    return _data.sort((a, b) => {
      if (sort.column) {
        if (a[sort.column] > b[sort.column]) {
          return sort.direction === "asc" ? -1 : 1;
        }
        if (a[sort.column] < b[sort.column]) {
          return sort.direction === "asc" ? 1 : -1;
        }
      }
      return 0;
    });
  };

  // Sort the data any time the sort state changes
  useEffect(() => {
    let newSort = sortData(sortedData);
    // Spread the sorted data into a new array to force a re-render
    setSortedData([...newSort]);
  }, [sort]);

  // Update the sorted data any time the data changes
  // Update the number of pages any time the data changes
  useEffect(() => {
    const newSort = sortData(data);
    // Create a new array reference to ensure React detects the change
    setSortedData([...newSort]);
    let newPageCount = Math.max(
      isNaN(rowsPerPage) ? 1 : Math.ceil(data.length / rowsPerPage),
      1
    );
    setPageCount(newPageCount);
    if (currentPage > newPageCount + 1) {
      setCurrentPage(newPageCount);
    }
  }, [data, sort.column, sort.direction, rowsPerPage]);

  // Force key update when sortedData changes to ensure re-render
  const tableKey = JSON.stringify(
    sortedData.map(
      (row) => row.id || row.signupid || row._id || row.key || Math.random()
    )
  );

  // Update the number of pages any time the rows per page changes
  useEffect(() => {
    let newPageCount = Math.max(
      isNaN(rowsPerPage) ? 1 : Math.ceil(data.length / rowsPerPage),
      1
    );
    setPageCount(newPageCount);
    if (currentPage > newPageCount + 1) {
      setCurrentPage(newPageCount);
    }
  }, [rowsPerPage]);

  const pages =
    pageCount <= 1
      ? [1]
      : pageCount === 2
      ? [1, 2]
      : currentPage === 1
      ? [currentPage, currentPage + 1, currentPage + 2]
      : currentPage === pageCount
      ? [currentPage - 2, currentPage - 1, currentPage]
      : [currentPage - 1, currentPage, currentPage + 1];

  return (
    <>
      <div className="d-flex align-items-center mb-3 sortable-table-controls">
        <div>
          <label>Rows per page:</label>
          <select
            id="rowsPerPage"
            className="form-select w-auto d-inline"
            value={rowsPerPage}
            onChange={changeRowsPerPage}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="all">All</option>
          </select>
        </div>
        <div className="spacer" />
        <div>Pages: {pageCount}</div>
        {extra_controls}
        <div className="flex-fill" />
        <nav>
          <ul className="pagination mb-0">
            <li className="page-item">
              <div
                className={`page-link ${currentPage <= 1 ? "disabled" : ""}`}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                Previous
              </div>
            </li>
            <li className="page-item">
              <div
                className={`page-link ${
                  currentPage === pages[0] ? "active" : ""
                }`}
                onClick={() => setCurrentPage(pages[0])}
              >
                {pages[0]}
              </div>
            </li>
            {pageCount > 1 ? (
              <li className="page-item">
                <div
                  className={`page-link ${
                    currentPage === pages[1] ? "active" : ""
                  }`}
                  onClick={() => setCurrentPage(pages[1])}
                >
                  {pages[1]}
                </div>
              </li>
            ) : null}
            {pageCount > 2 ? (
              <li className="page-item">
                <div
                  className={`page-link ${
                    currentPage === pages[2] ? "active" : ""
                  }`}
                  onClick={() => setCurrentPage(pages[2])}
                >
                  {pages[2]}
                </div>
              </li>
            ) : null}
            <li className="page-item">
              <div
                className={`page-link ${
                  currentPage >= pageCount ? "disabled" : ""
                }`}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                Next
              </div>
            </li>
          </ul>
        </nav>
      </div>
      <table className="table table-striped table-hover table-bordered">
        <thead>
          <tr>
            {headers.map(({ key, text }) => {
              return (
                <td
                  key={key}
                  className={[
                    "clickable",
                    key === sort.column ? "sortable" : "",
                    key === sort.column && sort.direction === "desc"
                      ? ""
                      : "desc",
                  ].join(" ")}
                  onClick={() => sortTable(key)}
                >
                  {text}
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody key={tableKey}>
          {sortedData
            .slice(
              (currentPage - 1) *
                (isNaN(rowsPerPage) ? data.length : rowsPerPage),
              currentPage * (isNaN(rowsPerPage) ? data.length : rowsPerPage)
            )
            .map((row, index) => {
              const rowIsClickable = onRowClick !== null && isRowClickable(row);
              return (
                <tr
                  key={
                    row.id ||
                    row.signupid ||
                    row._id ||
                    row.key ||
                    `row-${index}`
                  }
                  className={rowIsClickable ? "clickable" : ""}
                  onClick={() => (rowIsClickable ? onRowClick(row) : null)}
                >
                  {headers.map(({ key }) => (
                    <td key={key}>
                      {row[key] instanceof Date
                        ? // Format date in Central Time
                          formatCentralTime(row[key])
                        : // If the headers[key].render is a function, call it to render the data
                        headers.find((header) => header.key === key).render
                        ? headers
                            .find((header) => header.key === key)
                            .render(row, extra)
                        : row[key]}
                    </td>
                  ))}
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
};

export default SortableTable;
