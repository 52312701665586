import React from "react";
import JumbotronHeader from "./components/jumbotron-header";

const links = [
  {
    title: "Jump to Episode",
    link: [
      {
        title: "Instructors Corner, Episode 1: Brian's Radar",
        link: "#episode-1-radar",
      },
      {
        title: "Instructors Corner, Episode 2: Training Facility. Redefined!",
        link: "#episode-2-training-facility-redefined",
      },
      {
        title: "Instructors Corner, Episode 3: Shooting 750 Yards!",
        link: "#episode-3-750-yards",
      },
      {
        title: "Instructors Corner, Episode 4: Women and Guns!",
        link: "#episode-4-women-and-guns",
      },
      {
        title: "Instructors Corner, Episode 5: New Shooter Advice",
        link: "#episode-5-new-shooter-advice",
      },
      {
        title: "Instructors Corner, Episode 6: Filming Your Training",
        link: "#episode-6-filming-your-training",
      },
      {
        title: "PBGT Podcast, Episode 1: Arming Teachers",
        link: "#episode-1-arming-teachers",
      },
    ],
  },
  {
    title: "RSS Feed",
    link: "/podcast-rss",
  },
];

const PodcastEpisode = ({
  id,
  title,
  subtitle,
  year,
  description,
  img_src,
  audio_src,
  backgroundColor = "",
}) => (
  <section
    className={`container-fluid section-padding ${backgroundColor}`}
    id={id}
  >
    <div className="row">
      <div className="col-md-1"></div>
      <div className="col-sm-12 col-md-11">
        <div className="row">
          <div className="col-md-4">
            <a href={"#" + id} className="block" style={{ margin: 15 }}>
              <img
                src={img_src}
                data-src={img_src}
                className="hidden-xs hidden-sm img-thumbnail media-object"
              />
            </a>
          </div>
          <div className="col-md-8">
            <h2 className="media-heading" style={{ marginBottom: 15 }}>
              {title}
              <br />
              {subtitle} <small>{year}</small>
            </h2>
            <p>{description}</p>
          </div>
        </div>

        <div
          className="d-flex"
          style={{
            borderRadius: 10,
            padding: 3,
            border: "2px solid #f0ad4e",
          }}
        >
          <audio controls className="media-object" style={{ width: "100%" }}>
            <source src={audio_src} type="audio/mpeg" />
          </audio>
        </div>
      </div>
    </div>
  </section>
);

const Podcast = () => {
  return (
    <>
      <JumbotronHeader title="Podcast" links={links}>
        <div className="row">
          <div className="col-xs-12">
            <h1 className="pbgt-yellow">PointBlank Podcasts</h1>
            <h3>
              Listen to us "Shootin' the Shit," where our instructors discuss a
              few short topics about PointBlank, the gun range and firearms
              training. Enjoy!
            </h3>
            <p>
              <a rel="alternate" type="application/rss+xml" href="/podcast-rss">
                <img
                  src="/images/assets/rss-feed-icon-32x32.png"
                  alt="PointBlank Podcast Rss Feed Icon"
                />
              </a>
            </p>
          </div>
        </div>
      </JumbotronHeader>

      <PodcastEpisode
        id="episode-1-radar"
        title="Instructors Corner, Episode 1"
        subtitle="Brian's Radar"
        year="2018"
        description="Brian discusses the term radar. This is Vance & Brian's unspoken language. They communicate through body language, gestures, facial expressions, tone of voice and more! Listen to how this father and son team work so well together."
        img_src="/audio/ep1/episode-1-radar.jpg"
        audio_src="/audio/ep1/episode-1-radar.mp3"
      />

      <PodcastEpisode
        id="episode-2-training-facility-redefined"
        title="Instructors Corner, Episode 2"
        subtitle="Training Facility. Redefined!"
        year="2018"
        description="Brian, Vance and Ken discuss PointBlank's indoor and outdoor training facilities, features and benefits PointBlank has built over the years. From the 750 yard range to the design ideas behind making a safer range. Listen now!"
        img_src="/audio/ep2/episode-2-training-facility-redefined.jpg"
        audio_src="/audio/ep2/episode-2-training-facility-redefined.mp3"
        backgroundColor="bg-grey"
      />

      <PodcastEpisode
        id="episode-3-750-yards"
        title="Instructors Corner, Episode 3"
        subtitle="Shooting 750 Yards!"
        year="2018"
        description="Brian, Vance and Ken discuss PointBlank's outdoor gun range featuring the 750 yard long range precision rifle range. Listen now!"
        img_src="/audio/ep3/episode-3-750-yards.jpg"
        audio_src="/audio/ep3/episode-3-750-yards.mp3"
      />

      <PodcastEpisode
        id="episode-4-women-and-guns"
        title="Instructors Corner, Episode 4"
        subtitle="Women & Guns!"
        year="2018"
        description="Brian, Vance and Ken discuss female students in firearms training. At PointBlank we help a lot of women gain confidence and have fun shooting firearms. Listen now!"
        img_src="/audio/ep4/episode-4-women-and-guns.jpg"
        audio_src="/audio/ep4/episode-4-women-and-guns.mp3"
        backgroundColor="bg-grey"
      />

      <PodcastEpisode
        id="episode-5-new-shooter-advice"
        title="Instructors Corner, Episode 5"
        subtitle="New Shooter Advice"
        year="2018"
        description="Brian, Vance and Ken discuss advice they'd give to novice shooters just getting into the sport. Don't feel like you can't do this, you absolutely can - and you'll have fun too. Listen now!"
        img_src="/audio/ep5/episode-5-new-shooter-advice.jpg"
        audio_src="/audio/ep5/episode-5-new-shooter-advice.mp3"
      />

      <PodcastEpisode
        id="episode-6-filming-your-training"
        title="Instructors Corner, Episode 6"
        subtitle="Filming Your Training"
        year="2018"
        description="Brian, Vance and Ken discuss how PointBlank films each class and gives each student a video of their training a few weeks after each outdoor course. Each student can continue learning and best of all its included in the training fee! Listen now."
        img_src="/audio/ep6/episode-6-filming-your-training.jpg"
        audio_src="/audio/ep6/episode-6-filming-your-training.mp3"
      />

      <PodcastEpisode
        id="episode-1-arming-teachers"
        title="PBGT Podcast, Episode 1"
        subtitle="Arming Teachers"
        year="2018"
        description="Brian, Vance and Ken discuss arming teachers in schools, helping to prevent mass shootings in schools and more in this longer format podcast. Listen now!"
        img_src="/audio/ep7/episode-1-arming-teachers.jpg"
        audio_src="/audio/ep7/episode-1-arming-teachers.mp3"
      />
    </>
  );
};

export default Podcast;
