import React from "react";
import { Link } from "react-router-dom";
import JumbotronHeader from "./components/jumbotron-header";

const GearSubmissionSuccess = () => {
  return (
    <JumbotronHeader title="Submission Successful">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <div className="mt-5 mb-5">
              <i
                className="bi bi-check-circle-fill text-success"
                style={{ fontSize: "5rem" }}
              ></i>
              <h1 className="pbgt-yellow mt-4">Thank You!</h1>
              <h2>Your gear information has been successfully submitted.</h2>
              <p className="lead mt-4">
                The PointBlank training team will review your information to
                better prepare for your class.
              </p>
              <p className="mt-5">
                If you have any questions, please don't hesitate to contact us.
              </p>
              <div className="mt-5">
                <Link to="/" className="btn btn-lg pbgt-yellow-btn me-3">
                  Return to Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </JumbotronHeader>
  );
};

export default GearSubmissionSuccess;
