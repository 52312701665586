import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useJwt } from "../../../jwt-provider";
import { createToast } from "./common";
import AdminHeader from "./admin-header";
import API from "../../../api";
import { handleApiError } from "./error-utils";

const AdminLogin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { login } = useJwt();
  const [submitDisabled, setSubmitDisabled] = React.useState(false);

  const handleSubmit = async (e) => {
    setSubmitDisabled(true);
    // Get the username and password from the form, and then use API.post to send the data to the server
    e.preventDefault();
    const username = document.getElementById("username").value;
    const password = document.getElementById("password").value;

    try {
      const response = await API.post("login", { username, password });
      login(response.jwt);

      // Now redirect to the ?return parameter
      const urlParams = new URLSearchParams(location.search);
      const returnPath = urlParams.get("return");
      navigate(returnPath || "/--admin-schedule");
    } catch (error) {
      // Add an error toast
      handleApiError(error, createToast, "Error logging in");
    }

    setSubmitDisabled(false);
  };

  return (
    <>
      <AdminHeader>
        <div className="container">
          <div className="row">
            <div className="col-sm-4" />
            <div className="col-xs-12 col-sm-8">
              <h1 className="pbgt-yellow">Admin Login</h1>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="username">Username</label>
                  <input type="text" className="form-control" id="username" />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                  />
                </div>
                <br />
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={submitDisabled}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </AdminHeader>
    </>
  );
};

export default AdminLogin;
