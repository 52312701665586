import React from "react";

const RangeRulesExpectations = () => (
  <div className="row section-padding">
    <div className="col-sm-2"></div>
    <div className="col-xs-12 col-sm-8">
      <h2 style={{ fontSize: 36 }}>Range Rules and Expectations:</h2>

      <p className="pbgt-body-text">
        <b>Meals Provided!</b> Students can expect hearty meals, beverages, and
        snacks at no additional cost.
      </p>
      <p className="pbgt-body-text">
        <b>Dry Facility.</b> Absolutely <b>NO</b> drugs or alcohol are allowed
        on the premises.
      </p>
      <p className="pbgt-body-text">
        <b>Videoography:</b> Each class is videoed (except CCW) and videos are
        sent approximately 4 weeks after your training event.
      </p>
      <p className="pbgt-body-text">
        <b>Closed Facility.</b> Each class gets our full attention, there are{" "}
        <b>NO</b> public hours at our facility. Call us to reserve time for a
        private event.
      </p>
    </div>
    <div className="col-sm-2"></div>
  </div>
);

export default RangeRulesExpectations;
