import React from "react";
import { Link } from "react-router-dom";
import JumbotronHeader from "../components/jumbotron-header";
import Testimonials from "../components/testimonials";
import WhyPointBlank from "../components/why-pointblank";
import SignupButtons from "../components/signup-buttons";
import RangeRulesExpectations from "../components/range-rules-expectations";
import { useSchedule } from "../../../schedule-provider";
import { filter_schedule, getClassCost } from "../../../common";

const links = [{ title: "Contact", link: "/contact" }];

const CLASS_ID = 29;

const InstructorTraining = () => {
  const { schedule, activeClasses } = useSchedule();
  const dates = filter_schedule(schedule, CLASS_ID);

  return (
    <>
      <JumbotronHeader
        title="Certified Firearms Instructor Training"
        links={links}
      >
        <div className="row">
          <h1 className="pbgt-yellow">
            Certified Firearms Instructor Training
          </h1>
          <div className="col-md-6 col-lg-6">
            <p className="pbgt-body-text">
              Start a rewarding career in firearms training by becoming a
              Certified Firearms Instructor. This is an in-depth course with a
              minimum of 24 hours of combined classroom and live fire
              instruction.
            </p>
            <p className="pbgt-body-text">
              This course includes ongoing training and support to all
              instructors in the PointBlank family. Course cost varies,
              depending upon students current skillset, to get a quote or
              explore our financing options,{" "}
              <Link to="/contact" className="pbgt-yellow">
                contact us
              </Link>
              .
            </p>
            <SignupButtons class_id={CLASS_ID} />
          </div>
          <div className="col-md-6 col-lg-6 pbgt-gear-list">
            <h2>Learning Goals:</h2>
            <ul className="pbgt-body-text">
              <li>Safely and confidently instruct individuals/groups.</li>
              <li>Diagnose and fix common shooter issues.</li>
              <li>Present materials to students multiple ways.</li>
              <li>Understand personality types.</li>
              <li>Set up and conduct live fire instructor demos.</li>
              <li>Med Kit requirements</li>
              <li>Create a business plan for success.</li>
              <li>Advertising/Marketing tips &amp; tricks</li>
            </ul>
          </div>
        </div>
      </JumbotronHeader>
      <RangeRulesExpectations />
    </>
  );
};

export default InstructorTraining;
