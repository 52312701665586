import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const JumbotronHeader = ({ title, links = [], children }) => {
  const location = useLocation();

  // Refresh the scrolls when the component mounts
  useEffect(() => {
    // const body = document.querySelector('[data-bs-spy="scroll"]');
    // let scrollSpy = bootstrap.ScrollSpy.getInstance(body);
    // if (scrollSpy !== undefined && scrollSpy !== null) {
    //   scrollSpy.dispose();
    // }
    // scrollSpy = new bootstrap.ScrollSpy(body, {
    //   target: "#jumbotron-header-navbar",
    // });
  }, []);

  // Scroll to the element with the ID matching the hash in the URL
  useEffect(() => {
    // if (location.hash) {
    //   const element = document.querySelector(location.hash);
    //   if (element) {
    //     element.scrollIntoView({ behavior: "smooth" });
    //   }
    // }
  }, [location]);

  const handleAnchorClick = (event) => {
    const targetId = event.currentTarget.getAttribute("href");
    if (targetId.startsWith("#")) {
      event.preventDefault();
      const targetElement = document.querySelector(targetId);
      if (targetElement) {
        const offsetPosition =
          targetElement.getBoundingClientRect().top + window.scrollY - 200;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    const anchorLinks = document.querySelectorAll('a[href^="#"]');
    anchorLinks.forEach((anchor) => {
      anchor.addEventListener("click", handleAnchorClick);
    });

    return () => {
      anchorLinks.forEach((anchor) => {
        anchor.removeEventListener("click", handleAnchorClick);
      });
    };
  }, []);

  return (
    <>
      <nav className="navbar navbar-default navbar-expand-lg pbgt-logo-bg sticky-top sticky-offset">
        <div className="container-fluid jumbotronHeaderContainer">
          <div className="navbar-header navbar-brand">
            <h2 className="navbar-title">{title}</h2>
          </div>
          <button
            className="navbar-toggler navbar-toggle"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#jumbotronHeaderNavbar"
            aria-controls="#jumbotronHeaderNavbar"
            aria-expanded="false"
            aria-label="Toggle Navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="jumbotron-header-navbar"
          >
            <ul className="nav navbar-nav navbar-right">
              {links.map(({ title, link }, i) =>
                Array.isArray(link) ? (
                  <li key={i} className="dropdown">
                    <Link
                      to="#"
                      className="dropdown-toggle"
                      data-bs-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {title}
                      <span className="caret"></span>
                    </Link>
                    <ul
                      className="dropdown-menu"
                      style={{ position: "absolute" }}
                    >
                      {link.map(({ link, title }, j) => (
                        <li key={j}>
                          {link.startsWith("#") ? (
                            <a
                              className="nav-item"
                              href={link}
                              onClick={handleAnchorClick}
                            >
                              {title}
                            </a>
                          ) : (
                            <Link to={link}>{title}</Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </li>
                ) : (
                  <li key={i} className={`nav-item`}>
                    {link.startsWith("#") ? (
                      <a href={link} onClick={handleAnchorClick}>
                        {title}
                      </a>
                    ) : (
                      <Link
                        className={location.pathname === link ? " active" : ""}
                        to={link}
                      >
                        {title}
                      </Link>
                    )}
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </nav>
      <div id="quickevents" className="jumbotron jumbotron-dark">
        {children}
      </div>
    </>
  );
};

export default JumbotronHeader;
