import React from "react";
import { Routes, Route } from "react-router-dom";

import { pages } from "./routes";
import { JwtProvider } from "../jwt-provider";
import JwtEnforcer from "./pages/admin/jwt-enforcer";

const Body = () => {
  return (
    <Routes>
      {pages.map((page) => (
        <Route
          key={page.path}
          path={page.path}
          element={
            page.isAdmin ? (
              <JwtProvider>
                <JwtEnforcer>
                  <page.component />
                </JwtEnforcer>
              </JwtProvider>
            ) : (
              <page.component />
            )
          }
        />
      ))}
      <Route path="*" element={<div>Page not found</div>} />
    </Routes>
  );
};

export default Body;
