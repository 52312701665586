import React from "react";
import { Link } from "react-router-dom";
import JumbotronHeader from "../components/jumbotron-header";
import Testimonials from "../components/testimonials";
import WhyPointBlank from "../components/why-pointblank";
import Payment from "../components/payment";
import Pricing from "../components/pricing";
import PhotoCarousel from "../components/photo-carousel";
import UpcomingClassDates from "../components/upcoming-class-dates";
import { useSchedule } from "../../../schedule-provider";
import { filter_schedule, getClassCost } from "../../../common";
import SignupButtons from "../components/signup-buttons";
import RangeRulesExpectations from "../components/range-rules-expectations";

const links = [
  { title: "Schedule", link: "#schedule" },
  { title: "Sign Up", link: "#sign-up" },
  { title: "Testimonials", link: "#testimonials" },
];

const photos = [
  {
    src: "/images/slides/vehicle-tactics-1.jpg",
    alt: "Is a car cover or concealment? Find out in our Vehicle Tactics classs!",
  },
  {
    src: "/images/slides/vehicle-tactics-2.jpg",
    alt: "Learn to Shoot From Various Vehicles.",
  },
  {
    src: "/images/slides/vehicle-tactics-3.jpg",
    alt: "Learn to Use Your Vehicle for Cover and Concealment.",
  },
  {
    src: "/images/slides/vehicle-tactics-4.jpg",
    alt: "We Shoot Different Types of Glass Using FMJ, JLB, JHP, and Self-Defense Ammo In Our Vehicle Tactics Course!",
  },
  {
    src: "/images/slides/vehicle-tactics-5.jpg",
    alt: "Vehicle Egress Tactics, Shooting from the 'V', and Skip Fire All Taught In Our Course!",
  },
];

const CLASS_ID = 16;

const VehicleTactics = () => {
  const { schedule, activeClasses } = useSchedule();
  const dates = filter_schedule(schedule, CLASS_ID);
  const cost = getClassCost(activeClasses, CLASS_ID);

  return (
    <>
      <JumbotronHeader title="Vehicle Tactics" links={links}>
        <div className="row">
          <h1 className="pbgt-yellow">Vehicle Tactics</h1>
          <div className="col-md-6 col-lg-6">
            <p className="pbgt-body-text">
              The Minnesota Carry Permit holder spends an average of 1 hour per
              day in a vehicle. This is NOT a beginner class and students should
              have a solid drawstroke and understanding of handgun fundamentals
              including safety. We recommend taking our{" "}
              <Link to="/intermediate-pistol-training" className="pbgt-yellow">
                Intermediate Pistol
              </Link>{" "}
              class before attending this class.
            </p>
            <p className="pbgt-body-text">
              Courses of fire include shooting from a vehicle, dealing with
              passengers, shooting through doors, shooting different types of
              glass, exit strategies, use of cover, and much more.
            </p>
            <p className="pbgt-body-text">
              If you carry a pistol for self defense and you drive a car, you
              NEED this class!
            </p>
            <p className="pbgt-body-text">
              This is an outdoor course. Students should prepare for weather
              conditions appropriate for the season.
            </p>
            <SignupButtons class_id={CLASS_ID} />
          </div>
          <div className="col-md-6 pbgt-gear-list pbgt-body-text">
            <h2>Gear List</h2>
            <ul>
              <li>Usable Safe Holster</li>
              <li>Electronic Ear Muffs preferred</li>
              <li>Handgun</li>
              <li>Multiple magazines</li>
              <li>Weather Appropriate Clothing</li>
              <li>250 rounds ammo</li>
              <li>Magazine Pouch</li>
              <li>Safety Glasses</li>
              <li>Backup firearm optional</li>
            </ul>
          </div>
        </div>
      </JumbotronHeader>
      <RangeRulesExpectations />

      <div className="container-fluid text-center bg-grey pbgt-carousel section-padding">
        <div className="row">
          <div className="col-xs-12">
            <h1>Vehicle Tactics Photos</h1>
            <PhotoCarousel photos={photos} />
          </div>
        </div>
      </div>
      <UpcomingClassDates
        title="Vehicle Tactics"
        description="Vehicle Tactics"
        dates={dates}
        cost={cost}
      />

      <Payment dates={dates} />

      <Testimonials />
    </>
  );
};

export default VehicleTactics;
