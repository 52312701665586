import React from "react";
import { Link } from "react-router-dom";
import JumbotronHeader from "./components/jumbotron-header";

const links = [
  { title: "Training Facility. Redefined.", link: "#training" },
  { title: "Range Video", link: "#video" },
];

const OutdoorRange = () => {
  return (
    <>
      <JumbotronHeader title="Private Training Facility" links={links}>
        <div className="row">
          <h1 className="pbgt-yellow">
            Private Training Facility - 5 ranges up to 750 Yards
          </h1>
          <div className="col-md-8 col-lg-8">
            <h2>Training Facility. Redefined.</h2>
            <h4>
              Our goal is to provide the best training possible. Our private
              facility has an abundance of features to offer beginner,
              intermediate, or advanced training for all students. We offer
              classes, including{" "}
              <Link to="/precision-rifle-training">
                Long-Range Precision Rifle
              </Link>
              , Handgun, AR15,{" "}
              <Link to="/mn-carry-permit">MN Carry Permit</Link>,{" "}
              <Link to="/contact">P.O.S.T Credit classes</Link>, Low-Light
              Engagements, Vehicle Tactics, Shotgun, and Rappel Tower training
              as well as custom courses.
            </h4>

            <h4>
              Our Outdoor Training Facility features a beautiful timberframe
              classroom, 5 ranges up to 750 yards (including a shoothouse), a
              heated indoor handgun range, a Life-Flight Helicopter Pad, and a
              43-foot rappel tower. We also have a brand-new, beautiful
              timberframe lodge and campground for overnight classes.
            </h4>

            <h4>
              PointBlank Gun Training has a strict no-alcohol and no-drugs
              policy.{" "}
              <strong className="pbgt-yellow">
                Any student found to be violating this policy will be
                immediately removed from the range and forfeit their training
                fees.
              </strong>
            </h4>
          </div>
          <div className="col-md-4 col-lg-3 pbgt-gear-list">
            <h3>Outdoor Facility Features</h3>
            <ul className="ul-disc-padd">
              <li>Range 1 - CQB Training and Tactics with Shoothouse</li>
              <li>Range 2 - Basic Practice</li>
              <li>Range 3 - CQB/IDPA Stages</li>
              <li>Range 4 - AR15 Range, 100 Yards</li>
              <li>Range 5 - Up to 750 Yard Precision Rifle Range</li>
              <li>Heated Indoor Handgun Range</li>
              <li>43-foot Rappel Tower</li>
              <li>Meals, Camping, and Lodging area</li>
              <li>Full Sized Bathroom Facilities</li>
              <li>Outdoor Gazebo with Wood-Fired Grill</li>
              <li>Shaded Canopies</li>
              <li>Life-Flight Helicopter Pad</li>
            </ul>
          </div>
        </div>
      </JumbotronHeader>

      <div id="training" className="container-fluid section-padding">
        <div className="row">
          <div className="col-sm-1"></div>
          <div className="col-sm-10">
            <br />
            <h3>Training Facility. Redefined.</h3>
            <p className="pbgt-body-text">
              <i>We are redefining what you expect in a training facility.</i>{" "}
              Our students do not setup, teardown, cleanup, or worry about meals
              and lodging - that's our job, so you can focus on your training.
              PointBlank Gun Training ranges are{" "}
              <strong>
                <i>private</i>
              </strong>{" "}
              and{" "}
              <strong>
                <i>exclusive</i>
              </strong>
              , ensuring you get the best training with no distractions.
              <br />
              <br />
              Videography is one of the most underutilized tools in the
              industry. PointBlank Gun Training provides each student with a
              training video highlighting their experience.
              <br />
              <br />
              <strong>Safety</strong> is our primary concern. Our facility has
              many safety features, including a Life Flight Helicopter Pad,
              A.E.D. and Med Kits on-site. Classes are held under any safe
              weather conditions.
            </p>
          </div>
        </div>
      </div>
      <div
        id="video"
        className="container-fluid bg-grey text-center section-padding"
      >
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-xs-12 col-md-8">
            <h3>Outdoor Training Facility Overview Video</h3>

            <div className="ratio ratio-16x9">
              <iframe
                src="//www.youtube.com/embed/lqNWW5xt7gw"
                allowFullScreen
              ></iframe>
            </div>
            <h4>
              Check out this video to see our Outdoor Training Facility in
              action!
            </h4>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </>
  );
};

export default OutdoorRange;
