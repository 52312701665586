import React from "react";
import { Link } from "react-router-dom";
import JumbotronHeader from "./components/jumbotron-header";

const links = [
  { title: "Brian Walsh", link: "#brian-walsh" },
  { title: "Jamie Boesen", link: "#jaime-boesen" },
  { title: "Vance Walsh", link: "#vance-walsh" },
];

const Instructors = () => {
  return (
    <>
      <JumbotronHeader title="Instructors" links={links}>
        <div className="row">
          <div className="col-md-12 col-lg-8">
            <h1 className="pbgt-yellow">Multiple Quality Instructors</h1>
            <p>
              PointBlank is constantly evolving by listening to student
              feedback. Our Male and Female instructors all work together to
              help each student with hands on training. This gives our students
              the best value for your training dollar. PointBlank can provide
              these services to students through both indoor and outdoor classes
              as well as custom private events.
              <br />
              <br />
              PointBlank's Instructors have been trained by some of the best in
              the industry, including Louis Awerbuck, Dave Spaulding, Rob
              Sheppard and Larry Vickers.
            </p>
          </div>
          <div className="hidden-md col-md-0 col-lg-4">
            <span className="fa-solid fa-check logo"></span>
          </div>
        </div>
      </JumbotronHeader>

      <div id="brian-walsh" className="container-fluid section-padding">
        <div className="row">
          <div className="xs-hidden col-sm-1" />
          <div className="col-xs-12 col-sm-4">
            <h2>Brian Walsh</h2>
            <p>
              {" "}
              Brian, pistol instructor and Glock Armorer, is well versed in the
              fundamentals of long range marksmanship and defensive handgun
              shooting techniques.
              <br />
              <br />
              Brian helps each student by diagnosing their shooting fundamentals
              for the{" "}
              <Link to="/precision-rifle-training">
                Precision Rifle Course
              </Link>{" "}
              at PointBlank's 750 Yard Range.
              <br />
              <br />
              Brian is the lead instructor for the Pistol courses offered at
              PointBlank.
            </p>
          </div>
          <div className="col-sm-7">
            <img
              className="center-block"
              src="/images/staff/brian.jpg"
              data-src="/images/staff/brian.jpg"
              alt="Brian Walsh Owner and Instructor at PointBlank Gun Training"
            />
          </div>
        </div>
      </div>

      <div
        id="jaime-boesen"
        className="container-fluid bg-grey section-padding"
      >
        <div className="row">
          <div className="xs-hidden col-sm-1" />
          <div className="col-sm-8 col-md-6 col-lg-4">
            <img
              className="center-block"
              src="/images/staff/jaime.jpg"
              data-src="/images/staff/jaime.jpg"
              alt="Jaime Boesen Female Instructor at PointBlank Gun Training"
            />
          </div>
          <div className="col-sm-4">
            <h2>Jaime Boesen</h2>
            <p>
              {" "}
              Jaime, a pistol instructor, Glock Armorer and shooting enthusiast
              brings a female perspective to the classroom. A powerful addition
              to the PointBlank team, she adds a comfort level to the many
              female students, especially in the{" "}
              <Link to="/mn-carry-permit">Permit to Carry Course</Link>.
            </p>
          </div>
        </div>
      </div>

      <div id="vance-walsh" className="container-fluid section-padding">
        <div className="row">
          <div className="xs-hidden col-sm-1" />
          <div className="col-xs-12 col-sm-4">
            <h2>Vance Walsh</h2>
            <p>
              {" "}
              Vance, a Glock Armorer, with an excellent handgun and precision
              rifle skill set, adds a younger outlook that our youthful students
              connect with.
              <br />
              <br />
              Vance is the lead instructor for the{" "}
              <Link to="/precision-rifle-training">
                Precision Rifle Course
              </Link>{" "}
              at PointBlank's 750 Yard Outdoor Gun Range. He teaches all of the
              ballistic formula's and calculations necessary to hit targets at
              extended distances. His technological expertise allows PointBlank
              to operate a custom built wireless camera system for long range
              shooting. This system, built by Vance, provides instant feedback
              on all shot placements and measures all necessary ballistic
              variables for accurate shot hits at any distance.
            </p>
          </div>
          <div className="col-sm-7">
            <img
              className="center-block"
              src="/images/staff/vance.jpg"
              data-src="/images/staff/vance.jpg"
              alt="Vance Walsh Instructor at PointBlank Gun Training"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Instructors;
