import React, { useState, useRef, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment-timezone";

import UpcomingClassDates from "./components/upcoming-class-dates";
import { useSchedule } from "../../schedule-provider";
import { filter_schedule, formatDate, formatCentralTime } from "../../common";
import { findRoute } from "../routes";

// Set moment to use Central Time
moment.tz.setDefault("America/Chicago");
const localizer = momentLocalizer(moment);

const classColors = {
  "Private Training": "#E0152B",
  "Range Closed": "#000000",
};

// Add CSS for hoverable agenda items
const styles = `
  .agenda-item-hoverable:hover {
    background-color: rgba(0, 0, 0, 0.05) !important;
    cursor: pointer !important;
  }
  
  .agenda-item-non-hoverable {
    cursor: default !important;
  }
`;

const Schedule = () => {
  const { schedule } = useSchedule();
  const [currentView, setCurrentView] = useState("month");
  const [popup, setPopup] = useState({
    visible: false,
    event: null,
    position: { top: 0, left: 0 },
  });
  const popupRef = useRef(null);

  const eventPropGetter = (event) => {
    // In agenda view, apply different styling
    if (currentView === "agenda") {
      // For Range Closed and Private Training events in agenda, don't add hover effects
      if (
        event.title === "Range Closed" ||
        event.title === "Private Training"
      ) {
        return {
          style: { backgroundColor: "transparent" },
          className: "agenda-item-non-hoverable",
        };
      }

      // For other events in agenda, add hoverable class for styling
      return {
        style: { backgroundColor: "transparent" },
        className: "agenda-item-hoverable",
      };
    }

    // If it's a special class type (Private Training or Range Closed), use its color
    if (classColors[event.title]) {
      // For Range Closed and Private Training events, change cursor to default instead of pointer
      if (
        event.title === "Range Closed" ||
        event.title === "Private Training"
      ) {
        return {
          style: {
            backgroundColor: classColors[event.title],
            cursor: "default",
          },
        };
      }
      return { style: { backgroundColor: classColors[event.title] } };
    }

    // For regular classes, use gray if full, green if open
    const backgroundColor = event.open_slots <= 0 ? "#808080" : "#28a745";
    return {
      style: { backgroundColor },
    };
  };

  const calendar_events = schedule.map((s) => ({
    id: s.index,
    title: s.class_title,
    start: new Date(s.start_date),
    end: new Date(s.end_date),
    open_slots: Math.max(0, s.open_slots), // Ensure open_slots is never negative
  }));

  const onSelectEvent = (event, e) => {
    // Don't show popup for Range Closed or Private Training events
    if (event.title === "Range Closed" || event.title === "Private Training") {
      return;
    }

    const rect = e.target.getBoundingClientRect();
    // Don't include #sign-up for full classes
    const link =
      event.open_slots <= 0
        ? findRoute(event.title)
        : `${findRoute(event.title)}#sign-up`;

    setPopup({
      visible: true,
      event: { ...event, link },
      position: {
        top: rect.bottom + window.scrollY + 2,
        left: rect.left + window.scrollX,
      },
    });
  };

  const closePopup = () => {
    setPopup({ visible: false, event: null, position: { top: 0, left: 0 } });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closePopup();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    document.title = "Schedule";
  }, []);

  return (
    <>
      {/* Add style tag for custom CSS */}
      <style>{styles}</style>
      <div id="calendar" className="container-fluid section-padding">
        <div className="row">
          <div className="col-xs-12 col-md-1"></div>
          <div className="col-xs-12 col-md-10">
            <Calendar
              localizer={localizer}
              events={calendar_events}
              startAccessor="start"
              endAccessor="end"
              defaultDate={new Date()}
              style={{ height: 750 }}
              eventPropGetter={eventPropGetter}
              onSelectEvent={onSelectEvent}
              views={["month", "agenda"]}
              onView={(view) => setCurrentView(view)}
              view={currentView}
            />
          </div>
        </div>
      </div>
      {popup.visible && (
        <div
          ref={popupRef}
          className="popup"
          style={{
            position: "absolute",
            top: popup.position.top,
            left: popup.position.left,
            backgroundColor: "white",
            border: "1px solid #ccc",
            padding: "10px",
            zIndex: 1000,
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
          }}
        >
          <button
            onClick={closePopup}
            className="btn btn-close"
            style={{ float: "right", cursor: "pointer" }}
          ></button>
          <h4>{popup.event.title}</h4>
          <div>Start: {formatCentralTime(popup.event.start)}</div>
          <div>End: {formatCentralTime(popup.event.end)}</div>
          <div>Open Slots: {popup.event.open_slots}</div>
          <Link className="btn btn-primary" to={popup.event.link}>
            {popup.event.open_slots <= 0 ? "View Class" : "Signup"}
          </Link>
          {/* Add more event details here */}
        </div>
      )}
    </>
  );
};

export default Schedule;
