import React from "react";

const slideshowImages = [
  {
    image: "/images/new-photos/Facility Pics.jpg",
  },
  {
    image: "/images/new-photos/Facility Pics 2.jpg",
  },
  {
    image: "/images/new-photos/Facility Pics 5.jpg",
  },
  {
    image: "/images/new-photos/Facility Pics 4.jpg",
  },
  {
    image: "/images/new-photos/PRS2D.jpg",
  },
  {
    image: "/images/new-photos/Facility Pics 3.jpg",
  },
  {
    image: "/images/new-photos/Facility Pic 9.jpg",
  },
  {
    image: "/images/new-photos/Campfire.jpg",
  },
];

const OutdoorRangeSlideshow = ({ backgroundColor = "bg-grey" }) => (
  <div
    id="outdoor-range-slideshow"
    className={`container-fluid text-center ${backgroundColor} pbgt-carousel section-padding`}
  >
    <div
      id="outdoorRangeCarousel"
      className="carousel slide text-center"
      data-bs-ride="carousel"
      data-bs-pause="false"
    >
      <div
        className="carousel-indicators"
        style={{
          bottom: -40,
        }}
      >
        {slideshowImages.map((slide, index) => (
          <button
            key={index}
            type="button"
            data-bs-target="#outdoorRangeCarousel"
            data-bs-slide-to={index}
            className={index === 0 ? "active" : ""}
            aria-label={`Slide ${index + 1}`}
            style={{
              width: "12px",
              height: "12px",
              borderRadius: "50%",
              border: "none",
              margin: "0 5px",
            }}
          ></button>
        ))}
      </div>

      <div className="carousel-inner">
        {slideshowImages.map((slide, index) => (
          <div
            key={index}
            className={`carousel-item ${index === 0 ? "active" : ""}`}
          >
            <img
              src={slide.image}
              alt={`Outdoor Range Photo ${index + 1}`}
              style={{ height: 600 }}
            />
          </div>
        ))}
      </div>

      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#outdoorRangeCarousel"
        data-bs-slide="prev"
        aria-label="Previous slide"
      >
        <span
          className="carousel-control-prev-icon"
          aria-hidden="true"
          style={{ filter: "invert(1)" }}
        ></span>
        <span className="visually-hidden">Previous</span>
      </button>

      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#outdoorRangeCarousel"
        data-bs-slide="next"
        aria-label="Next slide"
      >
        <span
          className="carousel-control-next-icon"
          aria-hidden="true"
          style={{ filter: "invert(1)" }}
        ></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
    <br />
  </div>
);

export default OutdoorRangeSlideshow;
