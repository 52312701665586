import React from "react";

const testimonials = [
  {
    image: "/images/new-photos/Customer-Success-1.jpg",
  },
  {
    image: "/images/new-photos/Customer-Success-2.jpg",
  },
];

const Testimonials = ({ backgroundColor = "bg-grey" }) => (
  <div
    id="testimonials"
    className={`container-fluid text-center ${backgroundColor} pbgt-carousel section-padding`}
  >
    <h2>Customer Success Photos</h2>
    <div
      id="myCarousel"
      className="carousel slide text-center"
      data-bs-ride="carousel"
      data-bs-pause="false"
    >
      <div
        className="carousel-indicators"
        style={{
          bottom: -40,
        }}
      >
        {testimonials.map((testimonial, index) => (
          <button
            key={index}
            type="button"
            data-bs-target="#myCarousel"
            data-bs-slide-to={index}
            className={index === 0 ? "active" : ""}
            aria-label={`Slide ${index + 1}`}
            style={{
              width: "12px",
              height: "12px",
              borderRadius: "50%",
              border: "none",
              margin: "0 5px",
            }}
          ></button>
        ))}
      </div>

      <div className="carousel-inner">
        {testimonials.map((testimonial, index) => (
          <div
            key={index}
            className={`carousel-item ${index === 0 ? "active" : ""}`}
          >
            <img
              src={testimonial.image}
              alt={`Customer Success Photo ${index + 1}`}
              style={{ height: 400 }}
            />
          </div>
        ))}
      </div>

      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#myCarousel"
        data-bs-slide="prev"
        aria-label="Previous slide"
      >
        <span
          className="carousel-control-prev-icon"
          aria-hidden="true"
          style={{ filter: "invert(1)" }}
        ></span>
        <span className="visually-hidden">Previous</span>
      </button>

      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#myCarousel"
        data-bs-slide="next"
        aria-label="Next slide"
      >
        <span
          className="carousel-control-next-icon"
          aria-hidden="true"
          style={{ filter: "invert(1)" }}
        ></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
    <br />
  </div>
);

export default Testimonials;
