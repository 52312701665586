import React from "react";

// This file creates a provider that will store the JWT in the context
// so that it can be accessed by any component that needs it. If a JWT
// is not found in the context, it will attempt to retrieve it from local storage.
// If the JWT is not found in local storage, the user is not logged in.
// The provider will provide a function to set the JWT and store it in local storage.
// The provider will also provide a function to log the user out.

const JwtContext = React.createContext();

// A function that decodes the JWT
const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const JwtProvider = ({ children }) => {
  const [jwt, setJwt] = React.useState(parseJwt(localStorage.getItem("jwt")));

  const login = (jwt) => {
    localStorage.setItem("jwt", jwt);
    setJwt(parseJwt(jwt));
  };

  const logout = () => {
    localStorage.removeItem("jwt");
    setJwt(null);
  };

  const loggedIn = jwt !== null;

  return (
    <JwtContext.Provider value={{ jwt, loggedIn, login, logout }}>
      {children}
    </JwtContext.Provider>
  );
};

// Now write the hook to use the context
export const useJwt = () => {
  const context = React.useContext(JwtContext);
  if (!context) {
    throw new Error("useJwt must be used within a JwtProvider");
  }
  return context;
};
