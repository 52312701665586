import React, { useEffect, useState } from "react";
import { createToast } from "./common";
import { handleApiError } from "./error-utils";
import API from "../../../api";

const updateClassInfo = async (id, data) => {
  try {
    const response = await API.patch(`classes/${id}`, data);
    createToast("Class updated successfully");
    return response;
  } catch (e) {
    handleApiError(e, createToast, "Error updating class");
    return false;
  }
};

const EditClass = ({ classInfo, updateSelectedClass, back }) => {
  const [formUpdated, setFormUpdated] = useState(false);
  const [title, setTitle] = useState(classInfo.class_title);
  const [days, setDays] = useState(classInfo.days);
  const [hoursPerDay, setHoursPerDay] = useState(classInfo.hours_per_day);
  const [allDay, setAllDay] = useState(classInfo.all_day);
  const [defaultCost, setDefaultCost] = useState(classInfo.default_cost);
  const [gearList, setGearList] = useState(classInfo.gear_list);
  const [active, setActive] = useState(classInfo.active);
  const [callForPricing, setCallForPricing] = useState(
    classInfo.call_for_pricing
  );
  const [link, setLink] = useState(classInfo.link || "");

  useEffect(() => {
    if (
      title !== classInfo.class_title ||
      days !== classInfo.days ||
      hoursPerDay !== classInfo.hours_per_day ||
      allDay !== classInfo.all_day ||
      defaultCost !== classInfo.default_cost ||
      gearList !== classInfo.gear_list ||
      active !== classInfo.active ||
      callForPricing !== classInfo.call_for_pricing ||
      link !== (classInfo.link || "")
    ) {
      setFormUpdated(true);
    }
  }, [
    title,
    days,
    hoursPerDay,
    allDay,
    defaultCost,
    gearList,
    active,
    callForPricing,
    link,
  ]);

  const confirmBack = () => {
    if (formUpdated) {
      if (
        window.confirm(
          "Form edits have been made, are you sure you want to leave this page?"
        )
      ) {
        back();
      }
    } else {
      back();
    }
  };

  return (
    <>
      <button onClick={confirmBack} className="btn btn-light">
        Back
      </button>
      <form>
        <div className="form-group">
          <label>Class Title</label>
          <input
            type="text"
            className="form-control"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
        </div>
        <div className="form-group">
          <label>Days</label>
          <input
            type="number"
            className="form-control"
            onChange={(e) => setDays(e.target.value)}
            value={days}
          />
        </div>
        <div className="form-group">
          <label>Hours Per Day</label>
          <input
            type="number"
            className="form-control"
            onChange={(e) => setHoursPerDay(e.target.value)}
            value={hoursPerDay}
          />
        </div>
        <div className="form-check mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="allDay"
            onChange={(e) => setAllDay(e.target.checked)}
            checked={allDay}
          />
          <label htmlFor="allDay" className="form-check-label">
            All Day
          </label>
        </div>
        <div className="form-group">
          <label>Default Cost</label>
          <input
            type="number"
            className="form-control"
            onChange={(e) => setDefaultCost(e.target.value)}
            value={defaultCost}
          />
        </div>
        <div className="form-check mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="callForPricing"
            onChange={(e) => setCallForPricing(e.target.checked)}
            checked={callForPricing}
          />
          <label htmlFor="callForPricing" className="form-check-label">
            Call For Pricing
          </label>
        </div>
        <div className="form-group">
          <label>Gear List</label>
          <select
            className="form-control"
            onChange={(e) => setGearList(e.target.value)}
            value={gearList}
          >
            <option value="none">None</option>
            <option value="long_range">Long Range Rifle</option>
            <option value="standard_rifle">AR15 Carbine</option>
          </select>
        </div>
        <div className="form-group">
          <label>URL Path</label>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                https://pointblankguntraining.com
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="/class-path"
              onChange={(e) => setLink(e.target.value)}
              value={link}
            />
            <div className="input-group-append">
              <button
                type="button"
                className="btn btn-outline-secondary"
                disabled={!link}
                onClick={() =>
                  window.open(
                    `https://pointblankguntraining.com${link}`,
                    "_blank"
                  )
                }
              >
                Test Link
              </button>
            </div>
          </div>
          <small className="form-text text-muted">
            The direct URL path to this class (e.g., /ar15-training).{" "}
            <strong>Note:</strong> This link is used only in emails sent to
            registrants and does NOT update the actual routes in the site.
          </small>
        </div>
        <div className="form-check mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="active"
            onChange={(e) => setActive(e.target.checked)}
            checked={active}
          />
          <label htmlFor="active" className="form-check-label">
            Active
          </label>
        </div>
        <br />
        <button
          type="submit"
          className="btn btn-primary"
          onClick={async (e) => {
            e.preventDefault();

            const data = {
              class_title: title,
              class_cost: defaultCost,
              days,
              hours_per_day: hoursPerDay,
              all_day: allDay,
              gear_list: gearList,
              active,
              call_for_pricing: callForPricing,
              link: link || null,
            };

            const result = await updateClassInfo(classInfo.class_id, data);

            if (result) {
              // Create an updated class object with all properties
              const updatedClass = {
                ...classInfo,
                class_title: title,
                default_cost: defaultCost,
                days,
                hours_per_day: hoursPerDay,
                all_day: allDay,
                gear_list: gearList,
                active,
                call_for_pricing: callForPricing,
                link: link || null,
              };

              // Update the parent component
              updateSelectedClass(updatedClass);

              back();
            }
          }}
        >
          Submit
        </button>
      </form>
    </>
  );
};

export default EditClass;
