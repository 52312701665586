import React from "react";
import { Link } from "react-router-dom";
import JumbotronHeader from "../components/jumbotron-header";
import Testimonials from "../components/testimonials";
import WhyPointBlank from "../components/why-pointblank";
import Payment from "../components/payment";
import Pricing from "../components/pricing";
import UpcomingClassDates from "../components/upcoming-class-dates";
import { filter_schedule, getClassCost } from "../../../common";
import { useSchedule } from "../../../schedule-provider";
import SignupButtons from "../components/signup-buttons";
import RangeRulesExpectations from "../components/range-rules-expectations";

const links = [
  { title: "Schedule", link: "#schedule" },
  { title: "Sign Up", link: "#sign-up" },
  { title: "Testimonials", link: "#testimonials" },
];

const CLASS_ID = 4;

const IntermediatePistol = () => {
  const { schedule, activeClasses } = useSchedule();
  const dates = filter_schedule(schedule, CLASS_ID);
  const cost = getClassCost(activeClasses, CLASS_ID);

  return (
    <>
      <JumbotronHeader title="Intermediate Pistol Training" links={links}>
        <div className="row">
          <h1 className="pbgt-yellow">Intermediate Pistol</h1>
          <div className="col-md-6 col-lg-6">
            <p className="pbgt-body-text">
              This class is for the student looking to develop their handgun
              skills. Go beyond basic operation and start using your firearm in
              practical situations, emphasizing accuracy, trigger control, and
              shot placement; then continue with skills that include
              single-handed shooting, drawing from a holster, and using cover
              and concealment. Expect to shoot 200-250 rounds with hands-on
              instruction including instructor demos.
            </p>
            <p className="pbgt-body-text">
              This class will be hosted outdoors, so please check the gear list
              for required items.
            </p>
            <SignupButtons class_id={CLASS_ID} />
          </div>
          <div className="col-md-6 pbgt-gear-list pbgt-body-text">
            <h2>Gear List</h2>
            <ul>
              <li>Usable Safe Holster</li>
              <li>Electronic Ear Muffs preferred</li>
              <li>Handgun</li>
              <li>Multiple magazines</li>
              <li>Weather Appropriate Clothing</li>
              <li>200 to 250 Rounds of Ammo</li>
              <li>Magazine Pouch</li>
              <li>Flashlight with Usable Holster</li>
              <li>Backup Firearm Optional</li>
            </ul>
          </div>
        </div>
      </JumbotronHeader>

      <RangeRulesExpectations />

      <UpcomingClassDates
        title="Intermediate Pistol Training"
        description="Intermediate Pistol Training"
        dates={dates}
        cost={cost}
      />

      <Payment dates={dates} />

      <Testimonials />
    </>
  );
};

export default IntermediatePistol;
