import React from "react";
import { usePayment } from "../payment-provider";
import JumbotronHeader from "./components/jumbotron-header";

const links = [];

const ThankYou = () => {
  const { thankYouData } = usePayment();
  return (
    <JumbotronHeader title="Thank you for you purchase!">
      <div className="row">
        <div className="col-sm-12">
          <p className="pbgt-body-text">
            We look forward to seeing you in class! Please see the information
            below for your purchase details.
          </p>
          <p className="pbgt-body-text" style={{ fontSize: 25 }}>
            {thankYouData?.gear_list_link_id ? (
              <>
                <strong>Gear Questionnaire:</strong> To effectively prepare for
                this class, please fill out the{" "}
                <a
                  className="pbgt-yellow"
                  href={`${window.location.origin}/gear-list/${thankYouData?.gear_list_link_id}`}
                >
                  Gear Questionnaire.
                </a>
              </>
            ) : null}
          </p>
          <p className="pbgt-body-text">
            <strong>Order ID:</strong> {thankYouData?.orderId}
            <br />
            <strong>Receipt URL:</strong>{" "}
            <a className="pbgt-yellow" href={thankYouData?.receiptUrl}>
              {thankYouData?.receiptUrl}
            </a>
            <br />
            <strong>Paid Amount:</strong> $
            {thankYouData?.paidAmount?.toFixed(2)}
            <br />
            <strong>Remaining Balance:</strong> $
            {thankYouData?.balance?.toFixed(2)}
            <br />
          </p>
        </div>
      </div>
    </JumbotronHeader>
  );
};

export default ThankYou;
