import React from "react";
import { Link } from "react-router-dom";
import JumbotronHeader from "../components/jumbotron-header";
import Testimonials from "../components/testimonials";
import RangeRulesExpectations from "../components/range-rules-expectations";
import WhyPointBlank from "../components/why-pointblank";
import Payment from "../components/payment";
import Pricing from "../components/pricing";
import UpcomingClassDates from "../components/upcoming-class-dates";
import { useSchedule } from "../../../schedule-provider";
import { filter_schedule, getClassCost } from "../../../common";
import SignupButtons from "../components/signup-buttons";

const links = [
  { title: "Schedule", link: "#schedule" },
  { title: "Sign Up", link: "#sign-up" },
  { title: "Testimonials", link: "#testimonials" },
];

const CLASS_ID = 17;

const AdvancedSevenFiftyRifle = () => {
  const { schedule, activeClasses } = useSchedule();
  const dates = filter_schedule(schedule, CLASS_ID);
  const cost = getClassCost(activeClasses, CLASS_ID);

  return (
    <>
      <JumbotronHeader
        title="750-Yard Advanced Precision Rifle & Hunting Training"
        links={links}
      >
        <div className="row">
          <h1 className="pbgt-yellow">
            750-Yard Advanced Precision Rifle & Hunting Training
          </h1>
          <div className="col-md-8 col-lg-6">
            <p className="pbgt-body-text">
              This class will test the rifle shooter's ability to measure and
              range objects with their reticle, shoot targets at unknown
              distances, shoot multiple targets at high angles, and shoot with
              time constraints. This is a low round count class, with a bonus
              stage at 750 yards. Our class has a 50% pass rate, including LEO,
              military, and civilian marksmen. It is not designed to be easy! If
              you leave with a certificate and plaque in your hand, you are
              shooting at the advanced level.
            </p>

            <SignupButtons class_id={CLASS_ID} />
          </div>
          <div className="col-md-6 col-lg-6 pbgt-gear-list">
            <div className="pull-left">
              <h2>Gear List</h2>
              <ul className="pbgt-body-text">
                <li>Precision Rifle / Hunting Rifle</li>
                <li>Electronic Ear Muffs preferred</li>
                <li>Ear Plugs under Electronic Muffs preferred</li>
                <li>Weather Appropriate Clothing</li>
                <li>Backup firearm optional</li>
                <li>Sling recommended</li>
                <li>
                  30 to 50 Rounds of Ammo (round counts vary per student based
                  on rifle weight and caliber)
                </li>
              </ul>
            </div>
          </div>
        </div>
      </JumbotronHeader>

      <RangeRulesExpectations />

      <UpcomingClassDates
        title="Advanced Precision Rifle & Hunting Training"
        description="Advanced Precision Rifle & Hunting Training"
        dates={dates}
        cost={cost}
      />

      <Payment dates={dates} />

      <Testimonials />
    </>
  );
};

export default AdvancedSevenFiftyRifle;
