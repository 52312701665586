import React from "react";

const PhotoCarousel = ({ photos }) => {
  return (
    <div
      id="photoCarousel"
      className="carousel slide text-center"
      data-bs-ride="carousel"
      data-bs-pause="false"
    >
      <div className="carousel-inner">
        {photos.map(({ src, alt }, index) => (
          <div
            key={index}
            className={`carousel-item ${index === 0 ? "active" : ""}`}
          >
            <figure>
              <img
                src={src}
                data-src={src}
                alt={alt}
                className="img-fluid center-block"
              />
            </figure>
          </div>
        ))}
      </div>

      <br />

      <div className="carousel-indicators">
        {photos.map((photo, index) => (
          <button
            key={index}
            type="button"
            data-bs-target="#photoCarousel"
            data-bs-slide-to={index}
            className={index === 0 ? "active" : ""}
          ></button>
        ))}
      </div>

      <a
        className="left carousel-control"
        href="#photoCarousel"
        role="button"
        data-slide="prev"
      >
        <span className="fa-solid fa-chevron-left" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </a>
      <a
        className="right carousel-control"
        href="#photoCarousel"
        role="button"
        data-slide="next"
      >
        <span className="fa-solid fa-chevron-right" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </a>
    </div>
  );
};

export default PhotoCarousel;
