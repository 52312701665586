import React, { useState, useEffect } from "react";
import { createToast } from "../admin/common";
import { handleApiError } from "../admin/error-utils";
import API from "../../../api";
import { formatDate } from "../../../common";
import { formatDateToLocalISOString } from "./common";
import moment from "moment-timezone";

const ClassDateModal = ({
  id,
  mode = "create",
  classInfo = [],
  defaults = {
    index: -1,
    class_type: "-1",
    total_cost: 500,
    deposit_cost: 250,
    current_students: 0,
    total_students: 6,
    start_date: new Date().toISOString().split("T")[0] + "T08:00",
    end_date: new Date().toISOString().split("T")[0] + "T08:00",
    live: true,
    memo: "",
  },
  onSuccess = () => {},
  resetDefaults,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [classTypes, setClassTypes] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  // Default values for the form
  const [index, setIndex] = useState(defaults.index);
  const [classType, setClassType] = useState(defaults.class_type);
  const [classDateRestrictions, setClassDateRestrictions] = useState({
    days: 1,
    hours_per_day: 8,
    all_day: false,
  });
  const [totalCost, setTotalCost] = useState(defaults.total_cost);
  const [depositCost, setDepositCost] = useState(defaults.deposit_cost);
  const [currentStudents, setCurrentStudents] = useState(
    defaults.current_students
  );
  const [totalStudents, setTotalStudents] = useState(defaults.total_students);
  const [startDate, setStartDate] = useState(defaults.start_date);
  const [endDate, setEndDate] = useState(defaults.end_date);
  const [live, setLive] = useState(defaults.live);
  const [memo, setMemo] = useState(defaults.memo);

  // Track whether the user has manually changed the start date
  const [userChangedStartDate, setUserChangedStartDate] = useState(false);

  // Track if this is the initial mount
  const [initialMount, setInitialMount] = useState(true);

  // useEffect to track initial mount
  useEffect(() => {
    // After first render, mark as no longer initial mount
    if (initialMount) {
      setInitialMount(false);
    }
  }, []);

  const onChangeClassType = (e) => {
    const class_info = classInfo.find(
      (c) => c.class_id === parseInt(e.target.value)
    );

    // Always update class date restrictions as they're needed for both create and edit
    setClassDateRestrictions({
      days: class_info.days,
      hours_per_day: class_info.hours_per_day,
      all_day: class_info.all_day,
    });

    setClassType(e.target.value);
    setTotalCost(parseFloat(class_info.default_cost).toFixed(2));
    setDepositCost((parseFloat(class_info.default_cost) * 0.5).toFixed(2));

    // Don't auto-recalculate dates for edit mode - only for create mode
    if (mode === "create") {
      // For create mode, we can set default dates based on current time
      const now = new Date();
      const formattedDate = formatDateToLocalISOString(now).substring(0, 16);
      setStartDate(formattedDate);
      // End date will be calculated by the useEffect that watches startDate
    }
  };

  // Handler for start date changes
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    setUserChangedStartDate(true);
  };

  // Update end date when start date changes or class restrictions change
  useEffect(() => {
    // Skip on initial mount to prevent shifting dates when first opening the edit modal
    if (initialMount && mode === "edit") {
      return;
    }

    // In create mode, always update end date
    // In edit mode, only update if the user actually changed the start date
    if (mode === "create" || (mode === "edit" && userChangedStartDate)) {
      let _startDate = new Date(startDate);
      let _endDate = new Date(endDate);

      if (classDateRestrictions.days > 0) {
        // If the class has a specific number of days, set the end date to be
        // the start date + days - 1 (inclusive of start day)
        const start = new Date(_startDate);
        const end = new Date(start);
        end.setDate(end.getDate() + classDateRestrictions.days - 1);
        _endDate = formatDateToLocalISOString(end);
        setEndDate(_endDate);
      }

      // After setting days, check for hours per day
      if (classDateRestrictions.hours_per_day > 0) {
        // If the class has a specific number of hours per day, set the end time
        // based on the start time + hours per day
        const start = new Date(startDate); // Use original start date
        const end = new Date(start);
        end.setHours(start.getHours() + classDateRestrictions.hours_per_day);

        // If we already updated the end date based on days, keep that date but use the time from this calculation
        if (classDateRestrictions.days > 0) {
          const existingEnd = new Date(_endDate);
          end.setFullYear(existingEnd.getFullYear());
          end.setMonth(existingEnd.getMonth());
          end.setDate(existingEnd.getDate());
        }

        _endDate = formatDateToLocalISOString(end);
        setEndDate(_endDate);
      }

      if (classDateRestrictions.all_day) {
        // If the class is all day, start time should be 00:00 and end time should be 23:59
        const start = new Date(_startDate);
        start.setHours(0);
        start.setMinutes(0);
        _startDate = formatDateToLocalISOString(start);
        const end = new Date(_endDate);
        end.setHours(23);
        end.setMinutes(59);
        _endDate = formatDateToLocalISOString(end);
        setStartDate(_startDate);
        setEndDate(_endDate);
      }
    }
  }, [
    classDateRestrictions,
    startDate,
    mode,
    userChangedStartDate,
    initialMount,
  ]);

  useEffect(() => {
    if (classInfo.length > 0) {
      if (mode === "create") {
        // For new class creation, use first class type as default
        onChangeClassType({ target: { value: classInfo[0].class_id } });
      } else if (mode === "edit" && classType === defaults.class_type) {
        // In edit mode, just set class date restrictions without changing dates
        const class_info = classInfo.find(
          (c) => c.class_id === parseInt(classType)
        );
        if (class_info) {
          setClassDateRestrictions({
            days: class_info.days,
            hours_per_day: class_info.hours_per_day,
            all_day: class_info.all_day,
          });
        }
      }
    }
  }, [classInfo, mode]);

  // Reset form values when resetDefaults changes or in edit mode
  useEffect(() => {
    if (mode === "edit") {
      // Reset the form to the defaults if the defaults change
      setIndex(defaults.index);
      setClassType(defaults.class_type);
      setTotalCost(defaults.total_cost);
      setDepositCost(defaults.deposit_cost);
      setCurrentStudents(defaults.current_students);
      setTotalStudents(defaults.total_students);
      setStartDate(defaults.start_date);
      setEndDate(defaults.end_date);
      setLive(defaults.live);
      setMemo(defaults.memo);
      setSubmitting(false);

      // Load class restrictions for the selected class but don't recalculate dates
      if (classInfo.length > 0) {
        const selectedClass = classInfo.find(
          (c) => c.class_id === parseInt(defaults.class_type)
        );
        if (selectedClass) {
          setClassDateRestrictions({
            days: selectedClass.days,
            hours_per_day: selectedClass.hours_per_day,
            all_day: selectedClass.all_day,
          });
        }
      }
    }
  }, [resetDefaults, mode === "edit" ? defaults : null]);

  const submitClassDate = async () => {
    setSubmitting(true);

    const data = {};
    data.class_id = parseInt(classType);
    data.total_cost = parseFloat(totalCost);
    data.deposit_cost = parseFloat(depositCost);
    data.current_students = parseInt(currentStudents);
    data.total_students = parseInt(totalStudents);

    // For consistent API data, ensure dates are stored with timezone info
    const formatDateWithTimezone = (dateStr) => {
      // The datetime-local input gives us a string like "2023-10-05T14:30"
      // We need to parse this as Central Time, but not convert it

      // Parse the date string as Central Time WITHOUT converting the time
      return moment.tz(dateStr, "America/Chicago").format();
    };

    data.start_date = new Date(formatDateWithTimezone(startDate));
    data.end_date = new Date(formatDateWithTimezone(endDate));
    data.live = live ? 1 : 0;
    data.memo = memo;

    try {
      let response = null;
      if (mode === "create") {
        response = await API.put("schedule", data);
        createToast(`Created class ${response.id}`, "Success");
      } else if (mode === "edit") {
        response = await API.patch(`schedule/${index}`, data);
        createToast(`Updated class ${index}`, "Success");
      } else {
        throw new Error("Invalid mode");
      }

      // Now dismiss the modal
      const modal = bootstrap.Modal.getInstance(document.getElementById(id));
      modal.hide();
      onSuccess(response.id);
    } catch (error) {
      // Handle error appropriately
      handleApiError(error, createToast, "Error submitting class date");
    }

    setSubmitting(false);
  };

  const handleDeleteClick = () => {
    setShowDeleteConfirmation(true);
  };

  const handleDeleteConfirm = async () => {
    setSubmitting(true);
    try {
      await API.delete(`schedule/${index}`);
      createToast(`Deleted class ${index}`, "Success");

      // Hide the confirmation and modal
      setShowDeleteConfirmation(false);
      const modal = bootstrap.Modal.getInstance(document.getElementById(id));
      modal.hide();
      onSuccess();
    } catch (error) {
      handleApiError(error, createToast, "Error deleting class");
    }
    setSubmitting(false);
  };

  const handleDeleteCancel = () => {
    setShowDeleteConfirmation(false);
  };

  return (
    <>
      <div
        className="modal fade"
        id={id}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="classDateModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id={`${id}-classDateModalLabel`}>
                {mode === "create"
                  ? "Create New"
                  : mode === "edit"
                  ? "Edit"
                  : "ERROR"}{" "}
                Class Date
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form
                className="schedule"
                id={`${id}-classDateForm`}
                onSubmit={submitClassDate}
              >
                <div className="date_cont">
                  <label htmlFor="class-type" className="form-label">
                    Class Type:
                  </label>
                  <select
                    id={`${id}-class-type`}
                    name="class_type"
                    className="form-control"
                    value={classType}
                    onChange={onChangeClassType}
                  >
                    {classInfo.map((classType) => (
                      <option
                        key={classType.class_id}
                        value={classType.class_id}
                      >
                        {classType.class_title}
                      </option>
                    ))}
                  </select>

                  <label htmlFor="total-cost" className="form-label">
                    Total Cost $:
                  </label>
                  <input
                    className="form-control"
                    id={`${id}-total-cost`}
                    name="total_cost"
                    type="number"
                    min="0"
                    max="9999"
                    step="any"
                    value={totalCost}
                    onChange={(e) => setTotalCost(e.target.value)}
                  />

                  <label htmlFor="deposit-cost" className="form-label">
                    Deposit Cost $:
                  </label>
                  <input
                    className="form-control"
                    id={`${id}-deposit-cost`}
                    name="deposit_cost"
                    type="number"
                    min="0"
                    max="9999"
                    step="any"
                    value={depositCost}
                    onChange={(e) => setDepositCost(e.target.value)}
                  />

                  <label htmlFor="start-date" className="form-label">
                    Start Date (DD-MM-YYYY):
                  </label>
                  <input
                    type="datetime-local"
                    id={`${id}-start-date`}
                    name="start_date"
                    className="form-control"
                    value={startDate}
                    onChange={handleStartDateChange}
                  />

                  <label htmlFor="end-date" className="form-label">
                    End Date (DD-MM-YYYY):
                  </label>
                  <input
                    type="datetime-local"
                    id={`${id}-end-date`}
                    name="end_date"
                    className="form-control"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                  />

                  <label htmlFor="current-students" className="form-label">
                    Current Students:
                  </label>
                  <input
                    id={`${id}-current-students`}
                    name="current_students"
                    className="form-control"
                    type="number"
                    min="0"
                    value={currentStudents}
                    onChange={(e) => setCurrentStudents(e.target.value)}
                  />

                  <label htmlFor="total-students" className="form-label">
                    Total Students:
                  </label>
                  <input
                    id={`${id}-total-students`}
                    name="total_students"
                    className="form-control"
                    type="number"
                    min="0"
                    value={totalStudents}
                    onChange={(e) => setTotalStudents(e.target.value)}
                  />

                  <div className="form-check" style={{ marginTop: 8 }}>
                    <label htmlFor="live" className="form-check-label">
                      Date is Published &amp; Public
                    </label>
                    <input
                      id={`${id}-live`}
                      name="live"
                      className="form-check-input"
                      type="checkbox"
                      checked={live}
                      onChange={(e) => setLive(e.target.checked)}
                    />
                  </div>
                  <div className="form-text">
                    If checked, this date will show up live on the website for
                    the public to see. If not-checked, this is a PRIVATE or
                    UNPUBLISHED date. Private classes can only be signed up for
                    if we directly give out the link.
                  </div>

                  <label htmlFor="memo" className="form-label">
                    Memo:
                  </label>
                  <textarea
                    id={`${id}-memo`}
                    name="memo"
                    className="form-control"
                    value={memo}
                    onChange={(e) => setMemo(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              {mode === "edit" && (
                <button
                  type="button"
                  className="btn btn-danger me-auto"
                  onClick={handleDeleteClick}
                  disabled={submitting}
                >
                  Delete
                </button>
              )}
              <button
                type="button"
                className="btn btn-primary"
                disabled={submitting}
                onClick={submitClassDate}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      {showDeleteConfirmation && (
        <div
          className="modal fade show"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
          tabIndex="-1"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Delete</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleDeleteCancel}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <p>
                  Are you sure you want to delete this class? This action cannot
                  be undone.
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleDeleteCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDeleteConfirm}
                  disabled={submitting}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ClassDateModal;
