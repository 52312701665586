import React from "react";
import { Link } from "react-router-dom";
import JumbotronHeader from "./components/jumbotron-header";

const Map = () => {
  return (
    <>
      <JumbotronHeader title="Directions to our World-class Range">
        <div className="row">
          <div className="col-xs-12">
            <h1 className="pbgt-yellow">PointBlank Location</h1>
            <p>
              PointBlank Gun Training's Range is located at 4859 Red River Trail
              SW, Pillager MN, 56473. This is approximately 15 minutes west of
              Baxter, MN.
            </p>
            <br />
            <Link to="/outdoor-facility">
              <span className="btn btn-light btn-lg pbgt-yellow-btn">
                Learn More about the Outdoor Range
              </span>
            </Link>
          </div>
        </div>
      </JumbotronHeader>
      <div id="indoor" className="container-fluid section-padding">
        <div className="row">
          <div className="col-xs-12">
            <center>
              <div
                className="googlemap"
                style={{
                  background:
                    "#fff url('/images/assets/45cal.min.gif') no-repeat center center",
                }}
              >
                <iframe
                  className="center-block"
                  style={{
                    minWidth: "75%",
                    background: "transparent",
                    minHeight: 430,
                    border: "none",
                  }}
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5509.028627590802!2d-94.186477!3d46.339336!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0000000000000000%3A0x55c619e673ba8e5d!2sPOINTBLANK+Gun+Training%2C+LLC!5e0!3m2!1sen!2sus!4v1432969034116"
                ></iframe>
              </div>
            </center>
          </div>
        </div>
      </div>
    </>
  );
};

export default Map;
