export const createToast = (
  message,
  title = "Notification",
  isError = false,
  autohide = true,
  delay = 5000
) => {
  // Create the toast element
  const toastEl = document.createElement("div");
  toastEl.classList.add("toast");
  toastEl.setAttribute("role", "alert");
  toastEl.setAttribute("aria-live", "assertive");
  toastEl.setAttribute("aria-atomic", "true");

  if (autohide) {
    toastEl.setAttribute("data-bs-autohide", "true");
    toastEl.setAttribute("data-bs-delay", delay);
  } else {
    toastEl.setAttribute("data-bs-autohide", "false");
  }

  // Create the inner HTML of the toast
  toastEl.innerHTML = `
    <div class="toast-header${isError ? " toast-error" : ""}">
      <strong class="me-auto">${title}</strong>
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body">
      ${message}
    </div>
  `;

  // Append to the toast container
  document.getElementById("toastContainer").appendChild(toastEl);

  // Initialize the toast and show it
  const toast = new bootstrap.Toast(toastEl);
  toast.show();
};
