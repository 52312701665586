import React from "react";
import { Link } from "react-router-dom";
import JumbotronHeader from "../components/jumbotron-header";
import Testimonials from "../components/testimonials";
import { filter_schedule } from "../../../common";
import { useSchedule } from "../../../schedule-provider";
import RangeRulesExpectations from "../components/range-rules-expectations";

const links = [
  { title: "Schedule", link: "#schedule" },
  { title: "Sign Up", link: "#sign-up" },
  { title: "Testimonials", link: "#testimonials" },
];

const CLASS_ID = 33;

const POSTCredits = () => {
  const { schedule } = useSchedule();
  const dates = filter_schedule(schedule, CLASS_ID);

  return (
    <>
      <JumbotronHeader
        title="P.O.S.T. Approved Law Enforcement Classes"
        links={links}
      >
        <div className="row">
          <h1 className="pbgt-yellow">
            P.O.S.T. Approved Law Enforcement Classes
          </h1>
          <div className="col-md-6 col-lg-6">
            <p className="pbgt-body-text">
              These courses are approved by the P.O.S.T. Board for continuing
              education credits. Please{" "}
              <Link to="/contact" className="pbgt-yellow">
                contact us
              </Link>{" "}
              for class dates and pricing.
            </p>
            <p className="pbgt-body-text">
              This class will be hosted outdoors, so please check the gear list
              for required items.
            </p>
            <Link to="/contact">
              <span className="btn btn-lg pbgt-yellow-btn">Contact Us</span>
            </Link>
          </div>
          <div className="col-md-6 pbgt-gear-list pbgt-body-text">
            <h2>Sample Classes</h2>
            <ul>
              <li>Long-Range Precision Rifle</li>
              <li>Handgun</li>
              <li>AR15 Carbine</li>
            </ul>
          </div>
        </div>
      </JumbotronHeader>

      <RangeRulesExpectations />

      <Testimonials />
    </>
  );
};

export default POSTCredits;
