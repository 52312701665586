import React, { useEffect } from "react";
import API from "./api";

// A provider that is used to fetch the schedule from the API and then provides
// an object that can be used to access current classes.

const ScheduleContext = React.createContext();

// Provider should refresh the schedule every 5 minutes
export const ScheduleProvider = ({ children }) => {
  const [schedule, setSchedule] = React.useState([]);
  const [activeClasses, setActiveClasses] = React.useState([]);
  const [lastRefresh, setLastRefresh] = React.useState(new Date());

  // Refresh the schedule every 5 minutes
  useEffect(() => {
    const interval = setInterval(() => {
      setLastRefresh(new Date());
    }, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(async () => {
    const schedule = await API.get("schedule/public");
    setSchedule(schedule);
    const activeClasses = await API.get("classes/active");
    setActiveClasses(activeClasses);
  }, [lastRefresh]);

  return (
    <ScheduleContext.Provider value={{ schedule, activeClasses }}>
      {children}
    </ScheduleContext.Provider>
  );
};

export const useSchedule = () => {
  return React.useContext(ScheduleContext);
};
