import React from "react";
import AdminHeader from "./admin-header";

const AdminStats = () => {
  return (
    <>
      <AdminHeader title="Statistics">
        Stats on available details. This will continue to improve with time. An
        entire list of newsletter emails is available to copy and paste into
        your email client.
      </AdminHeader>
    </>
  );
};

export default AdminStats;
