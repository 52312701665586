import React from "react";

const Loading = ({
  height = 200,
  width = "100%",
  paddingRight = 90,
  paddingTop = 125,
  marginTop = 0,
  marginLeft = 0,
  fontSize = 30,
}) => {
  return (
    <div
      style={{
        background:
          "#fff url('/images/assets/45cal.min.gif') no-repeat center center",
        width,
        height,
        backgroundSize: "contain",
      }}
    >
      <center style={{ paddingTop, paddingRight, marginLeft, marginTop }}>
        <h3 style={{ fontSize }}>Loading...</h3>
      </center>
    </div>
  );
};

export default Loading;
