import React from "react";
import { Link } from "react-router-dom";
import { useState, useRef } from "react";
import Modal from "react-modal";
import { useSchedule } from "../../../schedule-provider";

const GIFT_CARD_URL = import.meta.env.VITE_SQUARE_GIFT_CARD_URL;
const COUNTDOWN_LENGTH = 10;

const SignupButtons = ({ class_id }) => {
  const { activeClasses } = useSchedule();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [countdown, setCountdown] = useState(COUNTDOWN_LENGTH);
  const countdownIntervalRef = useRef(null);

  const _class = activeClasses.find((c) => c.class_id === parseInt(class_id));
  const class_cost = _class?.default_cost;
  const call_for_pricing = _class?.call_for_pricing;

  const handleModalClose = () => {
    if (countdownIntervalRef.current) {
      clearInterval(countdownIntervalRef.current);
      countdownIntervalRef.current = null;
    }
    setModalIsOpen(false);
    setCountdown(COUNTDOWN_LENGTH);
  };

  const handleSquareupClick = (e) => {
    e.preventDefault();
    setModalIsOpen(true);
    setCountdown(COUNTDOWN_LENGTH);

    if (countdownIntervalRef.current) {
      clearInterval(countdownIntervalRef.current);
    }

    countdownIntervalRef.current = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(countdownIntervalRef.current);
          countdownIntervalRef.current = null;
          setModalIsOpen(false);
          window.location.href = GIFT_CARD_URL;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };

  return (
    <>
      {call_for_pricing ? (
        <Link to="/contact">
          <span className="btn btn-lg pbgt-yellow-btn">Call for Pricing</span>
        </Link>
      ) : (
        <>
          <a href="#sign-up">
            <span className="btn btn-lg pbgt-yellow-btn">Sign Up Now!</span>
          </a>
          &nbsp;&nbsp;&nbsp;
          <a href={GIFT_CARD_URL} onClick={handleSquareupClick}>
            <span className="btn btn-lg pbgt-yellow-btn">
              Buy a Gift Certificate
            </span>
          </a>
        </>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleModalClose}
        style={{
          content: {
            width: "50%",
            height: "fit-content",
            margin: "auto",
            padding: "20px",
            borderRadius: "10px",
            textAlign: "center",
          },
        }}
      >
        <p className="pbgt-body-text">
          We are redirecting you to our payment partner to purchase this gift
          card. If you would like to buy a gift card for the value of this
          class, the cost is ${class_cost}.
        </p>
        <p>
          Redirecting in {countdown} seconds...
          <br />
          Or redirect now: <a href={GIFT_CARD_URL}>SquareUp</a>
        </p>
        <button className="btn btn-secondary" onClick={handleModalClose}>
          Cancel
        </button>
      </Modal>
    </>
  );
};

export default SignupButtons;
