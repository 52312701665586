import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSchedule } from "../schedule-provider";
import { formatDate } from "../common";
import { pages } from "./routes";

const find_class_path = (class_id) => {
  const page = pages.find((page) => page.class_id === class_id);
  return page ? page.path : "";
};

const Footer = () => {
  const { schedule } = useSchedule();
  const location = useLocation();
  const path = `${location.pathname}${
    location.hash?.length > 0 ? location.hash : ""
  }`;

  const currentDate = new Date();
  const upcomingEvents = schedule
    .filter(
      (event) =>
        new Date(event.start_date) > currentDate &&
        !event.class_title.toLowerCase().includes("private training") &&
        !event.class_title.toLowerCase().includes("range closed")
    )
    .sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
    .slice(0, 5);

  return (
    <>
      <div className="usa-color-line"></div>
      <footer className="container-fluid">
        <div className="row">
          <div className="col-sm-3"></div>
          <div className="col-sm-4">
            <h3>Connect with PointBlank</h3>
            <ul className="contact-info">
              <li>
                <a
                  href="tel:+12188202793"
                  onClick={() => trackConv("phone", 0)}
                >
                  <img
                    src="/images/template/phone-16x16.png"
                    data-src="/images/template/phone-16x16.png"
                    alt="Call PointBlank Gun Training at 218-820-2793"
                  />
                  &nbsp;Phone: 218-820-2793
                </a>
              </li>
              <li>
                <a
                  href="mailto:info@pointblankguntraining.com"
                  title="Stay in Contact via E-Mail"
                >
                  <img
                    src="/images/template/email-16x16.png"
                    data-src="/images/template/email-16x16.png"
                    alt="Email PointBlank Gun Training at info@PointBlankGunTraining.com"
                  />
                  &nbsp;Info@PointBlankGunTraining.com
                </a>
              </li>
              <li>
                <a
                  href="https://facebook.com/pointblankguntraining"
                  title="Like PointBlank Gun Training On Facebook!"
                  className="clear block"
                  target="_blank"
                >
                  <img
                    src="/images/template/facebook-16x16.png"
                    data-src="/images/template/facebook-16x16.png"
                    alt="Like PointBlank Gun Training on Facebook.com/pointblankguntraining"
                  />
                  &nbsp;Facebook Page
                </a>
              </li>

              <li>
                <a
                  href="https://youtube.com/c/PointblankguntrainingMN"
                  title="Watch PointBlank On Youtube!"
                  className="clear block"
                  target="_blank"
                >
                  <img
                    src="/images/template/youtube-16x16.png"
                    data-src="/images/template/youtube-16x16.png"
                    alt="Watch PointBlank Gun Training on Youtube"
                  />
                  &nbsp;Youtube Channel
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-4">
            <h3>Upcoming Training Events</h3>
            <ul>
              {upcomingEvents.map((event, i) => (
                <li key={i} itemScope="" itemType="https://schema.org/Event">
                  <Link
                    itemProp="url"
                    to={`${find_class_path(event.class_id)}#schedule`}
                    title={event.class_title}
                  >
                    <span itemProp="name">{event.class_title} Class</span> on{" "}
                    <span itemProp="startDate" content={event.start_date}>
                      {formatDate(event.start_date)}
                    </span>
                  </Link>
                </li>
              ))}

              <li>
                <Link to="/schedule">See The Full Class Schedule</Link>
              </li>
            </ul>
          </div>
          {/*
            <div className="col-sm-4">
            <div className="thumbnail center well-small text-center">
              <h2>Newsletter</h2>

              <p>Subscribe to our Newsletter and stay tuned.</p>

              <form action="/newsletter-signup" method="post">
                <div className="input-group pbgt-newsletter-email">
                  <span className="input-group-text">
                    <i className="fa-solid fa-envelope"></i>
                  </span>
                  <input
                    className="form-control"
                    id="pbgt-newsletter-email"
                    name="email"
                    placeholder="Email"
                    type="email"
                    required=""
                  />
                </div>
                <br />
                <input
                  type="submit"
                  name="submit"
                  value="Subscribe Now!"
                  className="btn btn-large pbgt-yellow-btn"
                />
              </form>
            </div>
          </div>
          */}
        </div>
        <div className="text-center clear">
          <Link
            to={path}
            className="fa-solid fa-chevron-up no-hover-underline"
            onClick={() => window.scrollTo(0, 0)}
          ></Link>
          <br />
          <br />
          <h5>
            PointBlank Gun Training, LLC
            <br />
            4859 Red River Trail SW, Pillager MN, 56473
            <br />
            Training students since 2008
          </h5>
        </div>
        <div
          id="toastContainer"
          className="toast-container position-fixed bottom-0 end-0 p-3"
        ></div>
      </footer>
    </>
  );
};

export default Footer;
