import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import JumbotronHeader from "./components/jumbotron-header";
import API from "../../api";
import { formatCentralTime } from "../../common";

const GearEntry = () => {
  const { linkId } = useParams();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [classInfo, setClassInfo] = useState(null);
  const [formData, setFormData] = useState({
    fullName: "",
    rifle: {
      manufacturer: "",
      make: "",
      model: "",
      action: "not_sure",
      barrelLength: "",
      barrelTwist: "",
    },
    scope: {
      manufacturer: "",
      make: "",
      model: "",
      boreAxis: "",
      turretUnits: "not_sure",
      turretValue: "",
      magMin: "",
      magMax: "",
      objDiameter: "",
      focalPlane: "not_sure",
    },
    ammo: {
      manufacturer: "",
      boxName: "",
      boxNumber: "",
      caliber: "",
      weight: "",
      bcG1: "",
      bcG7: "",
      muzzleVelocity: "",
      bulletLength: "",
    },
    comments: "",
  });
  const [formError, setFormError] = useState("");

  useEffect(() => {
    // If there's a link ID, we need to fetch the class info
    if (linkId) {
      const fetchClassInfo = async () => {
        try {
          // Direct call to the class-info endpoint
          const response = await API.get(`gear/${linkId}/class-info`);

          if (!response) {
            setFormError(
              "Unable to load class information. Please try again later."
            );
            return;
          }

          const {
            class_id,
            date_id,
            start_date,
            start_time,
            class_name,
            class_title,
            class_description,
            gear_list,
          } = response;

          setClassInfo({
            class_id,
            date_id,
            start_date,
            start_time,
            class_name,
            class_title,
            class_description,
            gear_list,
          });
        } catch (error) {
          console.error("Error fetching class info:", error);
          setFormError(
            "Unable to load class information. Please try again later."
          );
        }
      };

      fetchClassInfo();
    }
  }, [linkId]);

  const handleChange = (category, field, value) => {
    if (category) {
      setFormData({
        ...formData,
        [category]: {
          ...formData[category],
          [field]: value,
        },
      });
    } else {
      setFormData({
        ...formData,
        [field]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!formData.fullName.trim()) {
      setFormError("Please enter your name");
      return;
    }

    if (!classInfo) {
      setFormError(
        "Unable to determine class information. Please try again later."
      );
      return;
    }

    setIsSubmitting(true);
    setFormError("");

    try {
      const requestData = {
        class_id: classInfo.class_id,
        date_id: classInfo.date_id,
        gear_list_link_id: linkId,
        full_name: formData.fullName,
        rifle_data: formData.rifle,
        scope_data: formData.scope,
        ammo_data: formData.ammo,
        comments: formData.comments,
      };

      // Use API wrapper instead of direct axios calls
      const response = await API.post("gear/save", requestData);

      if (response.success) {
        // Redirect to a thank you page
        navigate("/gear-submission-success");
      } else {
        setFormError(
          "There was an error submitting your information. Please try again."
        );
      }
    } catch (error) {
      console.error("Error submitting gear info:", error);
      setFormError(
        "There was an error submitting your information. Please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  // Render a shorter form for standard_rifle gear
  const renderStandardRifleForm = () => {
    return (
      <>
        <h3 className="mt-4 text-primary">Rifle:</h3>
        <div className="mb-3">
          <label htmlFor="rifle-manf" className="form-label">
            <i className="bi bi-gear me-2"></i>Manufacturer/Brand
          </label>
          <input
            className="form-control"
            id="rifle-manf"
            name="rifle-manf"
            type="text"
            value={formData.rifle.manufacturer}
            onChange={(e) =>
              handleChange("rifle", "manufacturer", e.target.value)
            }
            aria-label="Rifle manufacturer"
          />
        </div>

        <h3 className="mt-4 text-primary">Optic:</h3>
        <div className="mb-3">
          <label htmlFor="scope-manf" className="form-label">
            <i className="bi bi-bullseye me-2"></i>Optic Details
          </label>
          <input
            className="form-control"
            id="scope-manf"
            name="scope-manf"
            type="text"
            value={formData.scope.manufacturer}
            onChange={(e) =>
              handleChange("scope", "manufacturer", e.target.value)
            }
            aria-label="Scope manufacturer"
          />
        </div>

        <h3 className="mt-4 text-primary">Caliber:</h3>
        <div className="mb-3">
          <label htmlFor="ammo-caliber" className="form-label">
            <i className="bi bi-bullseye me-2"></i>Caliber
          </label>
          <input
            className="form-control"
            id="ammo-caliber"
            name="ammo-caliber"
            type="text"
            value={formData.ammo.caliber}
            onChange={(e) => handleChange("ammo", "caliber", e.target.value)}
            aria-label="Ammunition caliber"
          />
        </div>

        <h3 className="mt-4 text-primary">Comments</h3>
        <div className="mb-3">
          <label htmlFor="comments" className="form-label">
            <i className="bi bi-chat-dots me-2"></i>Comments
          </label>
          <textarea
            className="form-control"
            style={{ minHeight: 150 }}
            placeholder="Add any comments or concerns you have about the info you've supplied here."
            id="comments"
            name="comments"
            value={formData.comments}
            onChange={(e) => handleChange(null, "comments", e.target.value)}
            aria-label="Additional comments"
          ></textarea>
        </div>
      </>
    );
  };

  // Render the full form for long_range gear
  const renderLongRangeForm = () => {
    return (
      <>
        <h3 className="mt-4 text-primary">Rifle:</h3>
        <div className="mb-3">
          <label htmlFor="rifle-manf" className="form-label">
            <i className="bi bi-gear me-2"></i>Manufacturer
          </label>
          <input
            className="form-control"
            id="rifle-manf"
            name="rifle-manf"
            type="text"
            value={formData.rifle.manufacturer}
            onChange={(e) =>
              handleChange("rifle", "manufacturer", e.target.value)
            }
            aria-label="Rifle manufacturer"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="rifle-make" className="form-label">
            <i className="bi bi-gear me-2"></i>Make
          </label>
          <input
            className="form-control"
            id="rifle-make"
            name="rifle-make"
            type="text"
            value={formData.rifle.make}
            onChange={(e) => handleChange("rifle", "make", e.target.value)}
            aria-label="Rifle make"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="rifle-model" className="form-label">
            <i className="bi bi-gear me-2"></i>Model
          </label>
          <input
            className="form-control"
            id="rifle-model"
            name="rifle-model"
            type="text"
            value={formData.rifle.model}
            onChange={(e) => handleChange("rifle", "model", e.target.value)}
            aria-label="Rifle model"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="rifle-action" className="form-label">
            <i className="bi bi-gear me-2"></i>Select Action Type
          </label>
          <select
            id="rifle-action"
            name="rifle-action"
            className="form-select"
            value={formData.rifle.action}
            onChange={(e) => handleChange("rifle", "action", e.target.value)}
            aria-label="Rifle action type"
          >
            <option value="not_sure">Not Sure</option>
            <option value="bolt">Bolt Action</option>
            <option value="break">Break Barrel</option>
            <option value="semi_auto">Semi Auto</option>
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="rifle-barrel-length" className="form-label">
            <i className="bi bi-gear me-2"></i>Barrel Length in Inches
          </label>
          <input
            className="form-control"
            id="rifle-barrel-length"
            name="rifle-barrel-length"
            type="text"
            value={formData.rifle.barrelLength}
            onChange={(e) =>
              handleChange("rifle", "barrelLength", e.target.value)
            }
            aria-label="Rifle barrel length"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="rifle-barrel-twist" className="form-label">
            <i className="bi bi-gear me-2"></i>Barrel Twist Rate in Inches (eg 1
            in 10)
          </label>
          <input
            className="form-control"
            id="rifle-barrel-twist"
            name="rifle-barrel-twist"
            type="text"
            value={formData.rifle.barrelTwist}
            onChange={(e) =>
              handleChange("rifle", "barrelTwist", e.target.value)
            }
            aria-label="Rifle barrel twist rate"
          />
        </div>

        <h3 className="mt-4 text-primary">Scope:</h3>
        <div className="mb-3">
          <label htmlFor="scope-manf" className="form-label">
            <i className="bi bi-bullseye me-2"></i>Manufacturer
          </label>
          <input
            className="form-control"
            id="scope-manf"
            name="scope-manf"
            type="text"
            value={formData.scope.manufacturer}
            onChange={(e) =>
              handleChange("scope", "manufacturer", e.target.value)
            }
            aria-label="Scope manufacturer"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="scope-make" className="form-label">
            <i className="bi bi-bullseye me-2"></i>Make
          </label>
          <input
            className="form-control"
            id="scope-make"
            name="scope-make"
            type="text"
            value={formData.scope.make}
            onChange={(e) => handleChange("scope", "make", e.target.value)}
            aria-label="Scope make"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="scope-model" className="form-label">
            <i className="bi bi-bullseye me-2"></i>Model
          </label>
          <input
            className="form-control"
            id="scope-model"
            name="scope-model"
            type="text"
            value={formData.scope.model}
            onChange={(e) => handleChange("scope", "model", e.target.value)}
            aria-label="Scope model"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="scope-bore-axis" className="form-label">
            <i className="bi bi-bullseye me-2"></i>Bore Axis aka Sight Height
            (inches)
          </label>
          <input
            className="form-control"
            id="scope-bore-axis"
            name="scope-bore-axis"
            type="text"
            value={formData.scope.boreAxis}
            onChange={(e) => handleChange("scope", "boreAxis", e.target.value)}
            aria-label="Scope bore axis"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="scope-turret-units" className="form-label">
            <i className="bi bi-bullseye me-2"></i>Select Turret Units of
            Adjustment
          </label>
          <select
            id="scope-turret-units"
            name="scope-turret-units"
            className="form-select"
            value={formData.scope.turretUnits}
            onChange={(e) =>
              handleChange("scope", "turretUnits", e.target.value)
            }
            aria-label="Scope turret units"
          >
            <option value="not_sure">Not Sure</option>
            <option value="moa">Minute of Angle (MOA)</option>
            <option value="iphy">Inch Per Hundred Yards (IPHY)</option>
            <option value="mill">Milliradians (MIL)</option>
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="scope-turret-value" className="form-label">
            <i className="bi bi-bullseye me-2"></i>Turret Adjustment Click Value
          </label>
          <input
            className="form-control"
            id="scope-turret-value"
            name="scope-turret-value"
            type="text"
            value={formData.scope.turretValue}
            onChange={(e) =>
              handleChange("scope", "turretValue", e.target.value)
            }
            aria-label="Scope turret value"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="scope-mag-min" className="form-label">
            <i className="bi bi-bullseye me-2"></i>Min Magnification
          </label>
          <input
            className="form-control"
            id="scope-mag-min"
            name="scope-mag-min"
            type="text"
            value={formData.scope.magMin}
            onChange={(e) => handleChange("scope", "magMin", e.target.value)}
            aria-label="Scope minimum magnification"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="scope-mag-max" className="form-label">
            <i className="bi bi-bullseye me-2"></i>Max Magnification
          </label>
          <input
            className="form-control"
            id="scope-mag-max"
            name="scope-mag-max"
            type="text"
            value={formData.scope.magMax}
            onChange={(e) => handleChange("scope", "magMax", e.target.value)}
            aria-label="Scope maximum magnification"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="scope-obj-diameter" className="form-label">
            <i className="bi bi-bullseye me-2"></i>Objective Lens Diameter
          </label>
          <input
            className="form-control"
            id="scope-obj-diameter"
            name="scope-obj-diameter"
            type="text"
            value={formData.scope.objDiameter}
            onChange={(e) =>
              handleChange("scope", "objDiameter", e.target.value)
            }
            aria-label="Scope objective lens diameter"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="scope-focal-plane" className="form-label">
            <i className="bi bi-bullseye me-2"></i>Scope Glass Configuration
          </label>
          <select
            id="scope-focal-plane"
            name="scope-focal-plane"
            className="form-select"
            value={formData.scope.focalPlane}
            onChange={(e) =>
              handleChange("scope", "focalPlane", e.target.value)
            }
            aria-label="Scope focal plane"
          >
            <option value="not_sure">Not Sure</option>
            <option value="ffp">First Focal Plane (FFP)</option>
            <option value="sfp">Second Focal Plane (SFP)</option>
            <option value="fixed">
              Fixed Magnification (Single Magnification, no zoom adjustment)
            </option>
          </select>
        </div>

        <h3 className="mt-4 text-primary">Ammunition:</h3>
        <div className="mb-3">
          <label htmlFor="ammo-manf" className="form-label">
            <i className="bi bi-bullseye me-2"></i>Manufacturer
          </label>
          <input
            className="form-control"
            id="ammo-manf"
            name="ammo-manf"
            type="text"
            value={formData.ammo.manufacturer}
            onChange={(e) =>
              handleChange("ammo", "manufacturer", e.target.value)
            }
            aria-label="Ammunition manufacturer"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="ammo-box-name" className="form-label">
            <i className="bi bi-bullseye me-2"></i>Box Name
          </label>
          <input
            className="form-control"
            id="ammo-box-name"
            name="ammo-box-name"
            type="text"
            value={formData.ammo.boxName}
            onChange={(e) => handleChange("ammo", "boxName", e.target.value)}
            aria-label="Ammunition box name"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="ammo-box-number" className="form-label">
            <i className="bi bi-bullseye me-2"></i>Box Number
          </label>
          <input
            className="form-control"
            id="ammo-box-number"
            name="ammo-box-number"
            type="text"
            value={formData.ammo.boxNumber}
            onChange={(e) => handleChange("ammo", "boxNumber", e.target.value)}
            aria-label="Ammunition box number"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="ammo-caliber" className="form-label">
            <i className="bi bi-bullseye me-2"></i>Caliber
          </label>
          <input
            className="form-control"
            id="ammo-caliber"
            name="ammo-caliber"
            type="text"
            value={formData.ammo.caliber}
            onChange={(e) => handleChange("ammo", "caliber", e.target.value)}
            aria-label="Ammunition caliber"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="ammo-weight" className="form-label">
            <i className="bi bi-bullseye me-2"></i>Weight of Bullet (grains -
            gr)
          </label>
          <input
            className="form-control"
            id="ammo-weight"
            name="ammo-weight"
            type="text"
            value={formData.ammo.weight}
            onChange={(e) => handleChange("ammo", "weight", e.target.value)}
            aria-label="Ammunition weight"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="ammo-bc-g1" className="form-label">
            <i className="bi bi-bullseye me-2"></i>G1 Ballistic Coefficient
          </label>
          <input
            className="form-control"
            id="ammo-bc-g1"
            name="ammo-bc-g1"
            type="text"
            value={formData.ammo.bcG1}
            onChange={(e) => handleChange("ammo", "bcG1", e.target.value)}
            aria-label="Ammunition G1 ballistic coefficient"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="ammo-bc-g7" className="form-label">
            <i className="bi bi-bullseye me-2"></i>G7 Ballistic Coefficient
          </label>
          <input
            className="form-control"
            id="ammo-bc-g7"
            name="ammo-bc-g7"
            type="text"
            value={formData.ammo.bcG7}
            onChange={(e) => handleChange("ammo", "bcG7", e.target.value)}
            aria-label="Ammunition G7 ballistic coefficient"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="ammo-muzzle-velocity" className="form-label">
            <i className="bi bi-bullseye me-2"></i>Muzzle Velocity
          </label>
          <input
            className="form-control"
            id="ammo-muzzle-velocity"
            name="ammo-muzzle-velocity"
            type="text"
            value={formData.ammo.muzzleVelocity}
            onChange={(e) =>
              handleChange("ammo", "muzzleVelocity", e.target.value)
            }
            aria-label="Ammunition muzzle velocity"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="ammo-bullet-length" className="form-label">
            <i className="bi bi-bullseye me-2"></i>Bullet Length (inches)
          </label>
          <input
            className="form-control"
            id="ammo-bullet-length"
            name="ammo-bullet-length"
            type="text"
            value={formData.ammo.bulletLength}
            onChange={(e) =>
              handleChange("ammo", "bulletLength", e.target.value)
            }
            aria-label="Ammunition bullet length"
          />
        </div>

        <h3 className="mt-4 text-primary">Comments</h3>
        <div className="mb-3">
          <label htmlFor="comments" className="form-label">
            <i className="bi bi-chat-dots me-2"></i>Comments
          </label>
          <textarea
            className="form-control"
            style={{ minHeight: 250 }}
            placeholder="Add any comments or concerns you have about the info you've supplied here."
            id="comments"
            name="comments"
            value={formData.comments}
            onChange={(e) => handleChange(null, "comments", e.target.value)}
            aria-label="Additional comments"
          ></textarea>
        </div>
      </>
    );
  };

  return (
    <>
      <JumbotronHeader title="Gear Questionnaire">
        <div className="row">
          <div className="col-12">
            <h1 className="pbgt-yellow">
              {classInfo?.class_title}: Tell Us About Your Gear
            </h1>
            {classInfo && (
              <div className="text-center">
                <h2>{classInfo.class_title}</h2>
                <p>
                  {formatCentralTime(new Date(classInfo.start_date), {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </p>
              </div>
            )}

            <p className="pbgt-body-text">
              Enter the details about the type of rifle, ammo and scope you plan
              to use in class. By having this data ahead of time we can better
              prepare for specific training needs.
            </p>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <h2 className="pbgt-yellow">Instructions</h2>
            <p className="pbgt-body-text">
              Enter as many details below as you can. If you do not know an
              answer, simply leave it blank.
            </p>
          </div>
        </div>
      </JumbotronHeader>
      <div className="row section-padding">
        <div className="col-md-2 col-lg-3"></div>
        <div className="col-md-8 col-lg-6">
          <form
            id="gear-list-form"
            className="pbgt-gear-list-form"
            onSubmit={handleSubmit}
          >
            <div className="mb-3">
              <label htmlFor="full-name" className="form-label fw-bold">
                <i className="bi bi-person me-2"></i>Your Name
              </label>
              <input
                className="form-control"
                id="full-name"
                name="full-name"
                type="text"
                value={formData.fullName}
                onChange={(e) => handleChange(null, "fullName", e.target.value)}
                required
                aria-label="Your name"
              />
            </div>

            {classInfo?.gear_list === "standard_rifle"
              ? renderStandardRifleForm()
              : renderLongRangeForm()}

            {formError && (
              <div className="alert alert-danger mt-3" role="alert">
                {formError}
              </div>
            )}

            <button
              className="btn btn-lg btn-primary w-100 mt-4 mb-4"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Sending..." : "Send to PointBlank"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default GearEntry;
