import React from "react";
import { Link } from "react-router-dom";
import JumbotronHeader from "../components/jumbotron-header";
import Testimonials from "../components/testimonials";
import RangeRulesExpectations from "../components/range-rules-expectations";
import Payment from "../components/payment";
import UpcomingClassDates from "../components/upcoming-class-dates";
import { useSchedule } from "../../../schedule-provider";
import { filter_schedule, getClassCost } from "../../../common";
import SignupButtons from "../components/signup-buttons";

const links = [
  { title: "Schedule", link: "#schedule" },
  { title: "Sign Up", link: "#sign-up" },
  { title: "Testimonials", link: "#testimonials" },
];

const CLASS_ID = 5;

const ShotgunSelfDefense = () => {
  const { schedule, activeClasses } = useSchedule();
  const dates = filter_schedule(schedule, CLASS_ID);
  const cost = getClassCost(activeClasses, CLASS_ID);

  return (
    <>
      <JumbotronHeader title="Shotgun Self-Defense" links={links}>
        <div className="row">
          <h1 className="pbgt-yellow">Shotgun Self-Defense</h1>
          <div className="col-md-6 col-lg-6">
            <h2>
              If YOU have a Shotgun for Home protection this class is for YOU!
            </h2>
            <p className="pbgt-body-text">
              The shotgun is one of the most versatile weapon platforms due to
              its ability to shoot a large selection of ammo and damage
              potential. Many Americans use a shotgun for home self-defense.
              Learn how to more effectively use the shotgun in and around your
              home. With proper ammo selection, techniques to manage recoil,
              patterning, and weapon retention, you'll improve your family's
              safety in and around your home.
            </p>
            <p className="pbgt-body-text">
              This is an outdoor course. Students should prepare for weather
              conditions appropriate for the season.
            </p>
            <SignupButtons class_id={CLASS_ID} />
          </div>
          <div className="col-md-6 col-lg-6">
            <h2>Gear List</h2>
            <ul className="pbgt-body-text">
              <li>Shotgun (Pump or Semi-Automatic)</li>
              <li>Sling</li>
              <li>Electronic Ear Muffs preferred</li>
              <li>Weather Appropriate Clothing</li>
              <li>200 Rounds of Birdshot</li>
              <li>25 Rounds of Buckshot</li>
              <li>10 Rounds of Slugs</li>
              <li>Backup Shotgun optional</li>
            </ul>
          </div>
        </div>
      </JumbotronHeader>

      <RangeRulesExpectations />

      <UpcomingClassDates
        title="Shotgun Self-Defense"
        description="Shotgun Self-Defense"
        dates={dates}
        cost={cost}
      />

      <Payment dates={dates} />

      <Testimonials />
    </>
  );
};

export default ShotgunSelfDefense;
