import React from "react";
import { Link } from "react-router-dom";

import JumbotronHeader from "./components/jumbotron-header";

const links = [{ title: "Video Gallery", link: "/video-gallery#" }];
const photos = [
  {
    src: "/images/gallery/range-1.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #1",
  },
  {
    src: "/images/gallery/range-2.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #2",
  },
  {
    src: "/images/gallery/range-3.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #3",
  },
  {
    src: "/images/gallery/range-4.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #4",
  },
  {
    src: "/images/gallery/range-5.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #5",
  },
  {
    src: "/images/gallery/range-6.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #6",
  },
  {
    src: "/images/gallery/range-7.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #7",
  },
  {
    src: "/images/gallery/range-8.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #8",
  },
  {
    src: "/images/gallery/range-9.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #9",
  },
  {
    src: "/images/gallery/range-10.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #10",
  },
  {
    src: "/images/gallery/range-11.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #11",
  },
  {
    src: "/images/gallery/range-12.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #12",
  },
  {
    src: "/images/gallery/range-13.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #13",
  },
  {
    src: "/images/gallery/range-14.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #14",
  },
  {
    src: "/images/gallery/range-15.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #15",
  },
  {
    src: "/images/gallery/range-16.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #16",
  },
  {
    src: "/images/gallery/range-17.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #17",
  },
  {
    src: "/images/gallery/range-18.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #18",
  },
  {
    src: "/images/gallery/range-19.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #19",
  },
  {
    src: "/images/gallery/range-20.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #20",
  },
  {
    src: "/images/gallery/range-21.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #21",
  },
  {
    src: "/images/gallery/range-22.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #22",
  },
  {
    src: "/images/gallery/range-23.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #23",
  },
  {
    src: "/images/gallery/range-24.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #24",
  },
  {
    src: "/images/gallery/range-25.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #25",
  },
  {
    src: "/images/gallery/range-26.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #26",
  },
  {
    src: "/images/gallery/range-27.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #27",
  },
  {
    src: "/images/gallery/range-28.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #28",
  },
  {
    src: "/images/gallery/range-29.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #29",
  },
  {
    src: "/images/gallery/range-30.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #30",
  },
  {
    src: "/images/gallery/range-31.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #31",
  },
  {
    src: "/images/gallery/range-32.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #32",
  },
  {
    src: "/images/gallery/range-33.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #33",
  },
  {
    src: "/images/gallery/range-34.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #34",
  },
  {
    src: "/images/gallery/range-35.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #35",
  },
  {
    src: "/images/gallery/range-36.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #36",
  },
  {
    src: "/images/gallery/range-37.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #37",
  },
  {
    src: "/images/gallery/range-38.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #38",
  },
  {
    src: "/images/gallery/range-39.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #39",
  },
  {
    src: "/images/gallery/range-40.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #40",
  },
  {
    src: "/images/gallery/range-41.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #41",
  },
  {
    src: "/images/gallery/range-42.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #42",
  },
  {
    src: "/images/gallery/range-43.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #43",
  },
  {
    src: "/images/gallery/range-44.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #44",
  },
  {
    src: "/images/gallery/range-45.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #45",
  },
  {
    src: "/images/gallery/range-46.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #46",
  },
  {
    src: "/images/gallery/range-47.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #47",
  },
  {
    src: "/images/gallery/range-48.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #48",
  },
  {
    src: "/images/gallery/range-49.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #49",
  },
  {
    src: "/images/gallery/range-50.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #50",
  },
  {
    src: "/images/gallery/range-51.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #51",
  },
  {
    src: "/images/gallery/range-52.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #52",
  },
  {
    src: "/images/gallery/range-53.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #53",
  },
  {
    src: "/images/gallery/range-54.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #54",
  },
  {
    src: "/images/gallery/range-55.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #55",
  },
  {
    src: "/images/gallery/range-56.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #56",
  },
  {
    src: "/images/gallery/range-57.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #57",
  },
  {
    src: "/images/gallery/range-58.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #58",
  },
  {
    src: "/images/gallery/range-59.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #59",
  },
  {
    src: "/images/gallery/range-60.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #60",
  },
  {
    src: "/images/gallery/range-61.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #61",
  },
  {
    src: "/images/gallery/range-62.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #62",
  },
  {
    src: "/images/gallery/range-63.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #63",
  },
  {
    src: "/images/gallery/range-64.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #64",
  },
  {
    src: "/images/gallery/range-65.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #65",
  },
  {
    src: "/images/gallery/range-66.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #66",
  },
  {
    src: "/images/gallery/range-67.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #67",
  },
  {
    src: "/images/gallery/range-68.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #68",
  },
  {
    src: "/images/gallery/range-69.jpg",
    alt: "Favorite Images from the PointBlank Outdoor Range. Image #69",
  },
  {
    src: "/images/gallery/shootnight1_med.jpg",
    alt: "Shoot Night 2016 - Photo 1",
  },
  {
    src: "/images/gallery/robshep1_med.jpg",
    alt: "Rob Shepperd 2 Day Combat Pistol Event - Photo 1",
  },
  {
    src: "/images/gallery/robshep2_med.jpg",
    alt: "Rob Shepperd 2 Day Combat Pistol Event - Photo 2",
  },
  {
    src: "/images/gallery/robshep3_med.jpg",
    alt: "Rob Shepperd 2 Day Combat Pistol Event - Photo 3",
  },
  {
    src: "/images/gallery/robshep4_med.jpg",
    alt: "Rob Shepperd 2 Day Combat Pistol Event - Photo 4",
  },
  {
    src: "/images/gallery/robshep5_med.jpg",
    alt: "Rob Shepperd 2 Day Combat Pistol Event - Photo 5",
  },
  {
    src: "/images/gallery/robshep6_med.jpg",
    alt: "Rob Shepperd 2 Day Combat Pistol Event - Photo 6",
  },
  {
    src: "/images/gallery/robshep7_med.jpg",
    alt: "Rob Shepperd 2 Day Combat Pistol Event - Photo 7",
  },
  {
    src: "/images/gallery/robshep8_med.jpg",
    alt: "Rob Shepperd 2 Day Combat Pistol Event - Photo 8",
  },
  {
    src: "/images/gallery/robshep9_med.jpg",
    alt: "Rob Shepperd 2 Day Combat Pistol Event - Photo 9",
  },
  {
    src: "/images/gallery/robshep10_med.jpg",
    alt: "Rob Shepperd 2 Day Combat Pistol Event - Photo 10",
  },
  {
    src: "/images/gallery/robshep11_med.jpg",
    alt: "Rob Shepperd 2 Day Combat Pistol Event - Photo 11",
  },
  {
    src: "/images/gallery/robshep12_med.jpg",
    alt: "Rob Shepperd 2 Day Combat Pistol Event - Photo 12",
  },
  {
    src: "/images/gallery/robshep13_med.jpg",
    alt: "Rob Shepperd 2 Day Combat Pistol Event - Photo 13",
  },
  {
    src: "/images/gallery/robshep14_med.jpg",
    alt: "Rob Shepperd 2 Day Combat Pistol Event - Photo 14",
  },
  {
    src: "/images/gallery/robshep15_med.jpg",
    alt: "Rob Shepperd 2 Day Combat Pistol Event - Photo 15",
  },
  {
    src: "/images/gallery/robshep16_med.jpg",
    alt: "Rob Shepperd 2 Day Combat Pistol Event - Photo 16",
  },
  {
    src: "/images/gallery/robshep17_med.jpg",
    alt: "Rob Shepperd 2 Day Combat Pistol Event - Photo 17",
  },
  {
    src: "/images/gallery/robshep18_med.jpg",
    alt: "Rob Shepperd 2 Day Combat Pistol Event - Photo 18",
  },
  {
    src: "/images/gallery/robshep19_med.jpg",
    alt: "Rob Shepperd 2 Day Combat Pistol Event - Photo 19",
  },
];

const PhotoGallery = () => {
  return (
    <>
      <JumbotronHeader title="Photo Gallery" links={links}>
        <div className="row">
          <div className="col-xs-12">
            <center>
              <h1 className="pbgt-yellow">Photo Gallery</h1>
            </center>

            <div
              id="galleryCarousel"
              className="carousel slide text-center"
              data-bs-ride="carousel"
              data-bs-pause="false"
            >
              <div className="carousel-inner" role="listbox">
                {photos.map(({ src, alt }, i) => (
                  <div
                    key={i}
                    className={`carousel-item ${i === 0 ? "active" : ""}`}
                  >
                    <img
                      src={src}
                      data-src={src}
                      alt={alt}
                      className="img-fluid center-block"
                    />
                  </div>
                ))}
                <button
                  className="carousel-control-prev pbgt-yellow"
                  type="button"
                  data-bs-target="#galleryCarousel"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#galleryCarousel"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon pbgt-yellow"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </JumbotronHeader>
    </>
  );
};

export default PhotoGallery;
