import axios from "axios";

const getApiUrl = () => {
  // Check if we're in development mode using Vite's environment variables
  if (import.meta.env.DEV) {
    return "http://localhost:7171/api";
  }

  // For production, use the current protocol, hostname and port
  const { protocol, hostname, port } = window.location;
  const portString = port ? `:${port}` : '';
  return `${protocol}//${hostname}${portString}/api`;
};

const API_URL = getApiUrl();
const API = {};

// For each request, if there is a jwt in localStorage, attach is to an authorization bearer
const getHeaders = () => {
  const jwt = localStorage.getItem("jwt");
  return jwt ? { Authorization: `Bearer ${jwt}` } : {};
};

API.get = async (route, args = []) => {
  try {
    const response = await axios.get(
      `${API_URL}/${route}${args.length > 0 ? "?" + args.join("&") : ""}`,
      { headers: getHeaders() }
    );
    return response.data;
  } catch (error) {
    // Handle error appropriately
    console.error("Error fetching data:", error);
    throw error;
  }
};

API.post = async (route, data) => {
  try {
    const response = await axios.post(`${API_URL}/${route}`, data, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    // Handle error appropriately
    console.error("Error posting data:", error);
    throw error;
  }
};

API.put = async (route, data) => {
  try {
    const response = await axios.put(`${API_URL}/${route}`, data, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    // Handle error appropriately
    console.error("Error putting data:", error);
    throw error;
  }
};

API.delete = async (route) => {
  try {
    const response = await axios.delete(`${API_URL}/${route}`, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    // Handle error appropriately
    console.error("Error deleting data:", error);
    throw error;
  }
};

API.patch = async (route, data) => {
  try {
    const response = await axios.patch(`${API_URL}/${route}`, data, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    // Handle error appropriately
    console.error("Error patching data:", error);
    throw error;
  }
};

export default API;
