import React from "react";
import { Link } from "react-router-dom";

import SignupButtons from "../components/signup-buttons";
import JumbotronHeader from "../components/jumbotron-header";
import Testimonials from "../components/testimonials";
import RangeRulesExpectations from "../components/range-rules-expectations";
import Payment from "../components/payment";
import Pricing from "../components/pricing";
import UpcomingClassDates from "../components/upcoming-class-dates";
import { useSchedule } from "../../../schedule-provider";
import { filter_schedule, getClassCost } from "../../../common";

const links = [
  { title: "Schedule", link: "#schedule" },
  { title: "Sign Up", link: "#sign-up" },
  { title: "Testimonials", link: "#testimonials" },
];

const CLASS_ID = 32;

const FiveHundredRifle = () => {
  const { schedule, activeClasses } = useSchedule();
  const dates = filter_schedule(schedule, CLASS_ID);
  const cost = getClassCost(activeClasses, CLASS_ID);

  return (
    <>
      <JumbotronHeader
        title="500-Yard Precision Rifle & Hunting Training"
        links={links}
      >
        <div className="row">
          <h1 className="pbgt-yellow">
            500-Yard Precision &amp; Hunting Rifle Training
          </h1>
          <div className="col-md-8 col-lg-6">
            <p className="pbgt-body-text">
              This class is designed for the hunter with limited shot distances
              that utilize smaller caliber, short-barreled, semi-auto rifles and
              rifles equipped with limited power optics, such as 3x9
              magnification and fixed parallax.
            </p>

            <p className="pbgt-body-text">
              Students will develop confidence in their skill set and their
              equipment using our hands-on teachings and live fire exercises at
              distances up to 500 yards. Our class covers topics such as ammo
              selection, internal/external ballistics, bullet trajectory, optics
              adjustment, wind compensation, Coriolis effect and spin drift.
              Each student receives a custom computer-printed drop chart for
              their specific rifle/ammo combination.
            </p>

            <p className="pbgt-body-text">
              Private Dates Available,{" "}
              <strong>
                <Link to="/contact" className="pbgt-yellow">
                  Contact Us For Details
                </Link>
              </strong>
            </p>

            <p className="pbgt-body-text">
              This class is conducted with a minimum of 2 instructors and is
              limited to 6 students.
            </p>

            <SignupButtons class_id={CLASS_ID} />
          </div>
          <div className="col-md-6 col-lg-6 pbgt-gear-list">
            <div className="pull-left">
              <h2>Gear List</h2>
              <ul className="pbgt-body-text">
                <li>Precision Rifle / Hunting Rifle</li>
                <li>Electronic Ear Muffs preferred</li>
                <li>Ear Plugs under Electronic Muffs preferred</li>
                <li>Weather Appropriate Clothing</li>
                <li>Backup firearm optional</li>
                <li>
                  50 to 150 Rounds of Ammo (round counts vary per student based
                  on rifle weight and caliber)
                </li>
              </ul>
            </div>
          </div>
        </div>
      </JumbotronHeader>

      <RangeRulesExpectations />

      <div className="container-fluid bg-grey section-padding">
        <div className="row">
          <br />
          <div className="col-sm-2"></div>
          <div className="col-xs-12 col-sm-8">
            <h3>What to Expect from our Long Range Class</h3>

            <div className="ratio ratio-16x9">
              <iframe
                src="//www.youtube.com/embed/CVBlnNIpOiA"
                allowFullScreen
              ></iframe>
            </div>
            <p className="pbgt-body-text">
              Here's what to expect from our Precision Rifle Class.
            </p>
            <p className="pbgt-body-text">
              We utilize a custom wireless 3-camera shot spotting system with
              ballistic prediction software. Students often place first round
              hits at 500 yards within 10 inches of the bullseye! This is well
              inside the kill zone of large game animals such as Elk, Bear and
              Moose. This maximizes your Training Time and Value - come see how
              PointBlank can improve your next hunting trip!
            </p>
          </div>
          <div className="col-sm-2"></div>
        </div>
      </div>
      <div className="container-fluid section-padding">
        <div className="row">
          <br />
          <div className="col-sm-2"></div>
          <div className="col-xs-12 col-sm-8">
            <h3>What to Look for in a Precision Rifle</h3>

            <div className="ratio ratio-16x9">
              <iframe
                src="//www.youtube.com/embed/0PvrD93HaWU"
                allowFullScreen
              ></iframe>
            </div>
            <p className="pbgt-body-text">
              Here's what to look for in a Precision Rifle.
            </p>
            <p className="pbgt-body-text">
              Are you wondering what gear you should bring to class? Besides
              looking over our Gear List, watch the video above.
            </p>
          </div>
          <div className="col-sm-2"></div>
        </div>
      </div>

      <UpcomingClassDates
        title="500-Yard Precision Rifle & Hunting Training"
        description="500-Yard Precision Rifle & Hunting Training"
        dates={dates}
        cost={cost}
      />

      <Payment dates={dates} />

      <Testimonials />
    </>
  );
};

export default FiveHundredRifle;
