/**
 * Utility functions for error handling and parsing Zod validation errors
 */

/**
 * Parses Zod validation errors from an API response
 * @param {Object} error - The error object from axios catch block
 * @returns {string} Formatted error message combining all validation issues
 */
export const parseZodError = (error) => {
  try {
    // Check if this is an axios error with response data
    if (error.response && error.response.data) {
      const { data } = error.response;

      // Check if this matches the Zod error format
      if (
        data.details &&
        data.details.issues &&
        Array.isArray(data.details.issues)
      ) {
        // Format all the validation issues into a single message
        const issueMessages = data.details.issues.map((issue) => {
          // Extract the field from the path if available
          let field = "";
          if (issue.path && issue.path.length > 0) {
            // Get the last element in the path array as the field name
            field = issue.path[issue.path.length - 1];
            // Format field name for better display (capitalize, remove underscores)
            field = field
              .replace(/_/g, " ")
              .replace(/\b\w/g, (l) => l.toUpperCase());
            field = `"${field}"`;
          }

          return `${field ? `${field}: ` : ""}${issue.message}`;
        });

        // Join all issue messages into a single string
        return `Validation Error: ${issueMessages.join(", ")}`;
      }

      // If there's an error message in the response but not in Zod format
      if (data.error) {
        return `Error: ${data.error}`;
      }
    }

    // Fallback to the basic error message
    return error.message || "An unknown error occurred";
  } catch (parseError) {
    // In case parsing fails, return a generic message
    console.error("Error parsing Zod error:", parseError);
    return "An error occurred while processing your request";
  }
};

/**
 * Helper function for handling API errors with proper error messages
 * @param {Object} error - The error object from axios catch block
 * @param {Function} createToast - The toast creation function
 * @param {string} fallbackMessage - Fallback message if error can't be parsed
 */
export const handleApiError = (
  error,
  createToast,
  fallbackMessage = "An error occurred"
) => {
  const errorMessage = parseZodError(error);
  createToast(errorMessage, "Error", true);
  console.error(fallbackMessage, error);
};
