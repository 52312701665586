import React from "react";
import JumbotronHeader from "../components/jumbotron-header";

const links = [
  {
    title: "Edit Classes",
    link: "/--admin-classes",
  },
  {
    title: "Edit Schedule",
    link: "/--admin-schedule",
  },
  {
    title: "Edit Signups",
    link: "/--admin-signups",
  },
  {
    title: "Signup Stats",
    link: "/--admin-stats",
  },
];

const AdminHeader = ({ title, children }) => {
  return (
    <>
      <JumbotronHeader title="Admin" links={links}>
        <div className="row">
          <div className="col-md-12 col-lg-8">
            <h1 className="pbgt-yellow">{title}</h1>
            {children}
          </div>
        </div>
      </JumbotronHeader>
    </>
  );
};

export default AdminHeader;
