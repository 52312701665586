import React from "react";
import { Link } from "react-router-dom";
import JumbotronHeader from "./components/jumbotron-header";

const links = [{ title: "Site Map", link: "#site-map" }];

const ContactUs = () => {
  return (
    <>
      <JumbotronHeader title="Contact Us" links={links}>
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <h1 className="pbgt-yellow">CONTACT US</h1>

            <div className="pbgt-body-text">
              <ul>
                <li>
                  Email:{" "}
                  <a href="mailto:info@pointblankguntraining.com">
                    Info@PointBlankGunTraining.com
                  </a>
                </li>
                <li>
                  Phone:{" "}
                  <a
                    href="tel:12188202793"
                    onClick={() => trackConv("phone", 0)}
                  >
                    218-820-2793
                  </a>
                </li>
                <li>
                  YouTube:{" "}
                  <a
                    href="https://www.youtube.com/channel/UCBMVjxcvify70ayjctM5ZMQ"
                    title="Watch our latest training videos at YouTube!"
                    target="_blank"
                  >
                    PointBlank on Youtube
                  </a>
                </li>
                <li>
                  Facebook:{" "}
                  <a
                    href="https://facebook.com/pointblankguntraining"
                    title="Like Us On Facebook!"
                    target="_blank"
                  >
                    Facebook.com/PointBlankGunTraining
                  </a>
                </li>
              </ul>
              <br />
              <p className="padd10" style={{ marginLeft: 20 }}>
                PointBlank Gun Training, LLC
                <br />
                4859 Red River Trail SW
                <br />
                Pillager MN, 56473
              </p>
            </div>
          </div>
          <div className="col-md-0 col-lg-4">
            <h2>Training Facility. Redefined.</h2>
            <span style={{ fontStyle: "italic", fontSize: 25 }}>
              Redefining what you expect in a training facility.
            </span>
          </div>
        </div>
      </JumbotronHeader>
      <div id="sitemap" class="container-fluid section-padding">
        <div class="row">
          <div class="col-xs-12">
            <h2>Not Finding What You Are Looking for?</h2>

            <p>
              Check out the{" "}
              <Link to="/site-map" title="View our Site Map to find more!">
                Site Map
              </Link>{" "}
              for all the relevant links to our Training Schedule, Classes,
              Outdoor Facility Features and More!
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
