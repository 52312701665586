import React from "react";
import { Link } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
const localizer = momentLocalizer(moment);

import { useSchedule } from "../../../schedule-provider";
import { filter_schedule, getClassCost } from "../../../common";
import JumbotronHeader from "../components/jumbotron-header";
import Testimonials from "../components/testimonials";
import Payment from "../components/payment";
import UpcomingClassDates from "../components/upcoming-class-dates";
import RangeRulesExpectations from "../components/range-rules-expectations";
import SignupButtons from "../components/signup-buttons";

const links = [
  { title: "Schedule", link: "#schedule" },
  { title: "Sign Up", link: "#sign-up" },
  { title: "Testimonials", link: "#testimonials" },
];

const CLASS_ID = 1;

const MNPermitToCarry = () => {
  const { schedule, activeClasses } = useSchedule();
  const dates = filter_schedule(schedule, CLASS_ID);
  const cost = getClassCost(activeClasses, CLASS_ID);

  const events = dates.map((s) => ({
    id: s.index,
    title: s.class_title,
    start: new Date(s.date_start),
    end: new Date(s.date_end),
  }));

  return (
    <>
      <JumbotronHeader title="MN Permit to Carry" links={links}>
        <div className="row">
          <h1 className="pbgt-yellow">Minnesota Permit to Carry</h1>
          <div className="col-md-8 col-lg-8">
            <p className="pbgt-body-text">
              Welcoming all students from the novice to the experienced permit
              holder. Our class is the most informational carry class you'll
              find - we cover hundreds of topics.
            </p>
            <ul className="pbgt-body-text">
              <li>
                Our shooting instruction is just as comprehensive as our
                classroom teaching
              </li>
              <li>
                Every student gets the{" "}
                <a
                  href="https://www.gunlawguide.com/"
                  target="_blank"
                  className="link-highlight"
                >
                  Traveler's Guide
                </a>{" "}
                and multiple handouts
              </li>
              <li>Spacious private classroom</li>
              <li>Excellent instructor demonstrations</li>
              <li>Hearty, homemade meals &amp; beverages included</li>
              <li>Private individual &amp; group classes available</li>
            </ul>
            <SignupButtons class_id={CLASS_ID} />
          </div>
          <div className="col-md-4">
            <h3>What to Bring</h3>
            <p className="pbgt-body-text">
              If you do not own any of the equipment listed below, we can
              provide it at no additional cost.
            </p>
            <ul className="pbgt-body-text">
              <li>Handgun</li>
              <li>50 rounds of ammunition</li>
              <li>Holster</li>
              <li>Hearing Protection</li>
              <li>Eye Protection</li>
            </ul>
          </div>
        </div>
      </JumbotronHeader>

      <RangeRulesExpectations />

      <UpcomingClassDates
        title="Permit to Carry"
        description="MN Permit to Carry Class"
        dates={dates}
        cost={cost}
      />

      <Payment dates={dates} />
      <Testimonials backgroundColor="white" />
    </>
  );
};

export default MNPermitToCarry;
