import React from "react";
import { Link } from "react-router-dom";

import Testimonials from "./components/testimonials";
import OutdoorRangeSlideshow from "./components/outdoor-range-slideshow";
const Home = () => {
  return (
    <>
      <div className="container-fluid section-padding bg-dark">
        <div className="row">
          <div className="col-12 text-center mb-3">
            <h2 className="pbgt-yellow">Featured New Classes in 2025</h2>
          </div>
          <div className="col-md-6">
            <div className="card mb-3">
              <div className="row g-0 d-flex">
                <div className="col-md-4 position-relative p-0">
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src="/images/new-photos/500 yard Precision Rifle.jpg"
                      className="rounded-start"
                      alt="500-Yard Precision Rifle Training"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h3 className="card-title pbgt-yellow mb-1">
                      500-Yard Precision Rifle Training
                    </h3>
                    <p className="card-text mb-2">
                      Master long-range shooting with our precision rifle
                      training. Perfect for hunters looking to extend their
                      effective range with confidence.
                    </p>
                    <Link
                      to="/500-yard-prt"
                      className="btn btn-light pbgt-yellow-btn"
                      style={{ width: 140 }}
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card mb-3">
              <div className="row g-0 d-flex">
                <div className="col-md-4 position-relative p-0">
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src="/images/new-photos/Red Dot.jpg"
                      className="rounded-start"
                      alt="Red Dot Pistol Training"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h3 className="card-title pbgt-yellow mb-1">
                      Red Dot Pistol Training
                    </h3>
                    <p className="card-text mb-2">
                      Specialized training for pistols with red dot optics.
                      Learn proper zeroing, acquisition techniques, and shooting
                      in varied conditions.
                    </p>
                    <Link
                      to="/red-dot-pistol-training"
                      className="btn btn-light pbgt-yellow-btn"
                      style={{ width: 140 }}
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="jumbotron jumbotron-dark">
        <div className="row">
          <h1 className="pbgt-yellow align-center">
            Public &amp; Private Firearms Training
          </h1>

          <div className="col-xs-12 col-sm-4 text-center">
            <Link to="/mn-carry-permit">
              <h2 className="pbgt-yellow">MN Carry Permit Classes</h2>
              <img
                src="/images/new-photos/Permit to Carry.jpg"
                alt="Carry Permit Classes are at PointBlank all year"
                className="img-responsive center-block"
              />
              <span className="btn btn-light btn-lg pbgt-yellow-btn">
                Signup Today!
              </span>
            </Link>
          </div>
          <div className="col-xs-12 col-sm-4 text-center">
            <Link to="/precision-rifle-training">
              <h2 className="pbgt-yellow">
                Precision &amp; Hunting Rifle Training
              </h2>
              <img
                src="/images/new-photos/PRS.jpg"
                alt="Precision &amp; Hunting Rifle Training up to 750 yards available now!"
                className="img-responsive center-block"
              />
              <span className="btn btn-light btn-lg pbgt-yellow-btn">
                Signup Today!
              </span>
            </Link>
          </div>
          <div className="col-xs-12 col-sm-4 text-center">
            <Link to="/2-day-precision-rifle-training">
              <h2 className="pbgt-yellow">2 Day Precision Rifle Training</h2>
              <img
                src="/images/new-photos/PRS2D.jpg"
                alt="2 Day Precision &amp; Hunting Rifle Training up to 750 yards available now!"
                className="img-responsive center-block"
              />
              <span className="btn btn-light btn-lg pbgt-yellow-btn">
                Signup Today!
              </span>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-4 text-center">
            <Link to="tel:+12188202793">
              <h2 className="pbgt-yellow">LEO P.O.S.T. Classes</h2>
              <img
                src="/images/new-photos/LEO POST.jpg"
                alt="Law Enforcement P.O.S.T Credit classes available for Pistol &amp; Long Range Precision Rifle training, up to 750 yards! Call 218-820-2793 today for more information."
                className="img-responsive center-block"
              />
              <span className="btn btn-light btn-lg pbgt-yellow-btn">
                Call Today!
              </span>
            </Link>
          </div>
          <div className="col-xs-12 col-sm-4 text-center">
            <Link to="/contact">
              <h2 className="pbgt-yellow">LEO Classes Include</h2>
              <h3 style={{ color: "#fff" }}>P.O.S.T. Credit Classes!</h3>
              <h5>
                PointBlank Gun Training offers classes for Law Enforcement and
                Conservation Officers seeking P.O.S.T credits. Classes include
                Pistol, AR15 Carbine, Precision Rifle, MN Carry Permit, and
                Shotgun classes. Contact us today to schedule your training.
              </h5>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <ul className="pbgt-gear-list">
                  <li>Advanced Handgun: 8 Cr.</li>
                  <li>Advanced Handgun 2-Day: 16 Cr.</li>
                  <li>Handgun/AR15 Skill Maint.: 8 Cr.</li>
                  <li>Long Range Precision Rifle: 8 Cr.</li>
                </ul>
                <ul className="pbgt-gear-list">
                  <li>Advanced Long Range Precision Rifle: 8 Cr.</li>
                  <li>1/2 Day Precision Rifle: 4 Cr.</li>
                  <li>Understanding MN Carry Permit Classes: 8 Cr.</li>
                  <li>Tactical Shotgun: 8 Cr.</li>
                </ul>
              </div>
              <div style={{ height: 45 }}>&nbsp;</div>
              <span className="btn btn-light btn-lg pbgt-yellow-btn">
                Call Today!
              </span>
            </Link>
          </div>
          <div className="col-xs-12 col-sm-4 text-center">
            <Link to="/advanced-pistol-training">
              <h2 className="pbgt-yellow">Advanced Pistol Training</h2>
              <img
                src="/images/new-photos/Firearms Ins Training.jpg"
                alt="Firearms Instructor Training classes available now!"
                className="img-responsive center-block"
              />
              <span className="btn btn-light btn-lg pbgt-yellow-btn">
                Signup Today!
              </span>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-4 text-center">
            <Link to="/ar15-training">
              <h2 className="pbgt-yellow">AR15 &amp; Carbine Training</h2>
              <img
                src="/images/new-photos/AR15 Carbine.jpg"
                alt="AR15 &amp; Carbine Training classes available now!"
                className="img-responsive center-block"
              />
              <span className="btn btn-light btn-lg pbgt-yellow-btn">
                Signup Today!
              </span>
            </Link>
          </div>
          <div className="col-xs-12 col-sm-4 text-center">
            <Link to="/outdoor-facility">
              <h2 className="pbgt-yellow">Outdoor Range Features</h2>
              <img
                src="/images/new-photos/Facility Pics.jpg"
                alt="Our Outdoor Private Range has many features to offer"
                className="img-responsive center-block"
              />
              <span className="btn btn-light btn-lg pbgt-yellow-btn">
                Range Videos
              </span>
            </Link>
          </div>
          <div className="col-xs-12 col-sm-4 text-center">
            <Link to="/video-gallery">
              <h2 className="pbgt-yellow">Class Photos</h2>
              <img
                src="/images/new-photos/Facility Pics 4.jpg"
                alt="View our Photo and Video Gallery to relive past training"
                className="img-responsive center-block"
              />
              <span className="btn btn-light btn-lg pbgt-yellow-btn">
                Photo Gallery
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="container-fluid section-padding">
        <div className="row">
          <div className="align-center">
            <h2 className="">
              Benefits to Training With &nbsp;
              <img
                src="/images/template/point-blank-gun-training-logo-black-mobile.png"
                alt="PointBlank Gun Training, LLC"
                style={{ height: 30 }}
              />
            </h2>
            <h3 style={{ fontStyle: "italic" }}>
              Redefining what you expect in a training facility!
            </h3>
          </div>

          <div className="col-xs-12 col-sm-4 text-center">
            <h2 className="pbgt-yellow">Timberframe Lodge</h2>
            <img
              src="/images/new-photos/Facility Pics 5.jpg"
              alt="Bunkhouse Lodging available at no additional cost!"
              className="img-responsive img-thumbnail center-block"
            />
            <h4>On-site Lodging provided at no additional fee!</h4>
          </div>
          <div className="col-xs-12 col-sm-4 text-center">
            <h2 className="pbgt-yellow">Training Video</h2>
            <img
              src="/images/new-photos/Intermediate Pistol.jpg"
              alt="We film every class so you can take the training home with you!"
              className="img-responsive img-thumbnail center-block"
            />
            <h4>
              A training video highlighting your training experience provided at
              no additional fee!
            </h4>
          </div>
          <div className="col-xs-12 col-sm-4 text-center">
            <h2 className="pbgt-yellow">Food &amp; Beverages</h2>
            <img
              src="/images/new-photos/Food.jpg"
              alt="Food and Beverages included at no additional cost!"
              className="img-responsive img-thumbnail center-block"
            />
            <h4>Amazing, wood-fired meals included at no additional fee!</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-4 text-center">
            <h2 className="pbgt-yellow">Safety</h2>
            <img
              src="/images/cdn/home/safety.jpg"
              alt="Safety is paramount on our range!"
              className="img-responsive img-thumbnail center-block"
            />
            <h4>
              Your safety is our top priority with an on-site Life-Flight
              helicopter pad, A.E.D. and emergency kits at every range!
            </h4>
          </div>
          <div className="col-xs-12 col-sm-4 text-center">
            <h2 className="pbgt-yellow">Camping</h2>
            <img
              src="/images/new-photos/Campfire.jpg"
              alt="Parking available for campers!"
              className="img-responsive img-thumbnail center-block"
            />
            <h4>On-site parking available for Campers!</h4>
          </div>
          <div className="col-xs-12 col-sm-4 text-center">
            <h2 className="pbgt-yellow">Full Training Time</h2>
            <img
              src="/images/new-photos/Certificate.jpg"
              alt="Full Training Time"
              className="img-responsive img-thumbnail center-block"
            />
            <h4>
              Class length reflects actual <b>training time</b>. <br />
              In an 8 hour class, you get 8 hours of <b>training!</b>
            </h4>
          </div>
        </div>
      </div>
      <div id="video" className="container-fluid text-center">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-xs-12 col-md-8">
            <h2 className="pbgt-yellow">Outdoor Training Facility Overview</h2>

            <OutdoorRangeSlideshow backgroundColor="white" />
            <h4></h4>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
      <Testimonials />

      <div className="usa-color-line"></div>
    </>
  );
};

export default Home;
