import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Layout from "./layout/layout";
import ScrollToTop from "./scroll-to-top";

// Hack for some race conditions
var bootstrap = undefined;

// Component to normalize URLs with double slashes
function URLNormalizer() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the URL has double slashes
    const pathname = location.pathname;

    // Regex to match consecutive slashes (after the first one)
    const hasDoubleSlash = /\/\/+/.test(pathname);

    if (hasDoubleSlash) {
      // Normalize the path - replace consecutive slashes with a single slash
      const normalizedPath = pathname.replace(/\/\/+/g, "/");

      // Only redirect if the normalized path is different
      if (normalizedPath !== pathname) {
        const newUrl = `${normalizedPath}${location.search}${location.hash}`;
        navigate(newUrl, { replace: true });
      }
    }
  }, [location, navigate]);

  return null;
}

function App() {
  return (
    <Router>
      <URLNormalizer />
      <ScrollToTop />
      <Layout />
    </Router>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
