import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useJwt } from "../../../jwt-provider";

// useJwt to get the JWT from the provider. If it is null or the expiration is in the past, redirect to the login page.
// If the JWT is valid, render the children.

const JwtEnforcer = ({ children }) => {
  const { jwt, logout } = useJwt();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // if the current page is not the --admin-login page and the JWT is null or expired, redirect to the login page
    if (
      (jwt === null || Date.now() >= jwt.exp * 1000) &&
      location.pathname !== "/--admin-login"
    ) {
      // Log out the user if the JWT is expired
      logout();

      // Redirect to the login page with a "return" query parameter to redirect back to the current page after login
      // Use react router to navigate to the login page so that it doesn't trigger a full page reload
      navigate("/--admin-login?return=" + location.pathname, {
        replace: true,
      });
    }
  }, [jwt, location.pathname]);

  return <>{children}</>;
};

export default JwtEnforcer;
