import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import API from "../../../api";
import { usePayment } from "../../payment-provider";

const LOGO_URL = `https://${window.location.hostname}/images/cdn/point-blank-t-shirt-back-white.png`;
const GAP = 8;

// Payment Processing Overlay Component
const PaymentProcessingOverlay = () => (
  <div
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 9999,
    }}
  >
    <div
      className="spinner-border text-white mb-3"
      role="status"
      style={{ width: "3rem", height: "3rem" }}
    >
      <span className="visually-hidden">Loading...</span>
    </div>
    <h3 className="text-white">Payment Processing...</h3>
  </div>
);

const _submitPayment = async (
  token,
  verifiedBuyer,
  selectedDateId,
  paymentAmount,
  billingContact,
  numberOfStudents
) => {
  // Send the token to your server to process the payment
  const response = await API.post("payments", {
    ...token,
    verifiedBuyer,
    selectedDateId,
    amount: paymentAmount,
    billingContact,
    numberOfStudents,
  });

  return response;
};

// Skeleton loader components
const ButtonSkeleton = ({ width, height }) => (
  <div
    className="skeleton-loader"
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      width: width || "100%",
      height: height || "48px",
      backgroundColor: "#e9ecef",
      borderRadius: "4px",
      animation: "pulse 1.5s infinite",
      zIndex: 1,
    }}
  />
);

const CardSkeleton = () => (
  <div
    className="skeleton-loader"
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "70px",
      backgroundColor: "#e9ecef",
      borderRadius: "4px",
      animation: "pulse 1.5s infinite",
      zIndex: 1,
    }}
  />
);

const PBPaymentForm = ({
  scheduleId,
  paymentAmount,
  numberOfStudents = 1,
  billingContact,
  _paymentDetails,
}) => {
  const { payments, setThankYouData } = usePayment();
  const navigate = useNavigate();
  const [card, setCard] = useState(null);
  const [googlePayHandle, setGooglePayHandle] = useState(null);
  const [applePayHandle, setApplePayHandle] = useState(null);
  const [giftCardHandle, setGiftCardHandle] = useState(null);
  const [payWithGiftCard, setPayWithGiftCard] = useState(false);
  const [loading, setLoading] = useState(true);
  const [paymentProcessing, setPaymentProcessing] = useState(false);

  // Add specific loading states for each payment method
  const [googlePayLoading, setGooglePayLoading] = useState(true);
  const [applePayLoading, setApplePayLoading] = useState(true);
  const [cardLoading, setCardLoading] = useState(true);
  const [giftCardLoading, setGiftCardLoading] = useState(true);

  // Add CSS for animation
  useEffect(() => {
    const style = document.createElement("style");
    style.textContent = `
      @keyframes pulse {
        0% { opacity: 0.6; }
        50% { opacity: 1; }
        100% { opacity: 0.6; }
      }
    `;
    document.head.appendChild(style);
    return () => document.head.removeChild(style);
  }, []);

  const toggleGiftCard = async (e) => {
    e.preventDefault();
    // Clean up existing forms before switching
    if (payWithGiftCard && giftCardHandle) {
      try {
        await giftCardHandle.destroy();
        setGiftCardHandle(null);
      } catch (error) {
        console.error("Error destroying gift card form:", error);
      }
    } else if (!payWithGiftCard && card) {
      try {
        await card.destroy();
        setCard(null);
      } catch (error) {
        console.error("Error destroying card form:", error);
      }
    }

    // Toggle the payment method
    setPayWithGiftCard(!payWithGiftCard);
  };

  const verificationDetails = {
    amount: `${billingContact.amount}`,
    currencyCode: "USD",
    intent: "CHARGE",
    billingContact: {
      familyName: billingContact.lastName,
      givenName: billingContact.firstName,
      email: billingContact.email,
      country: billingContact.country,
      city: billingContact.city,
      addressLines: [billingContact.address],
      postalCode: billingContact.postalCode,
      phone: billingContact.phone,
    },
  };

  const paymentDetails = {
    amount: _paymentDetails.amount,
    label: _paymentDetails.class_name,
    id: _paymentDetails.class_id,
    imageUrl: _paymentDetails.image_url ?? LOGO_URL,
    pending: true,
    productUrl: _paymentDetails.product_url,
  };

  const submitPayment = async (token, verifiedBuyer, selectedDateId) => {
    const result = await _submitPayment(
      token,
      verifiedBuyer,
      selectedDateId,
      paymentAmount,
      billingContact,
      numberOfStudents
    );
    if (result?.success) {
      setThankYouData(result.data.payment);
      navigate("/thank-you");
    }
  };

  const buildPaymentRequest = (payments) => {
    return payments.paymentRequest({
      countryCode: "US",
      currencyCode: "USD",
      total: {
        amount: `${paymentAmount}`,
        label: "Total",
      },
    });
  };

  const processPayment = async () => {
    setPaymentProcessing(true);
    try {
      const token = await card.tokenize();
      const verificationResults = await payments.verifyBuyer(
        token.token,
        verificationDetails
      );
      await submitPayment(
        token,
        verificationResults,
        scheduleId,
        billingContact
      );
    } catch (error) {
      console.error("Error processing payment:", error);
    }
    setPaymentProcessing(false);
  };

  const processGooglePay = async () => {
    setPaymentProcessing(true);
    try {
      const token = await googlePayHandle.tokenize();
      if (token?.status !== "Cancel") {
        const verificationResults = await payments.verifyBuyer(
          token.token,
          verificationDetails
        );
        await submitPayment(
          token,
          verificationResults,
          scheduleId,
          billingContact
        );
      }
    } catch (error) {
      console.error("Error processing payment:", error);
    }
    setPaymentProcessing(false);
  };

  const processApplePay = async () => {
    setPaymentProcessing(true);
    try {
      const token = await applePayHandle.tokenize();
      if (token?.status !== "Cancel") {
        const verificationResults = await payments.verifyBuyer(
          token.token,
          verificationDetails
        );
        await submitPayment(
          token,
          verificationResults,
          scheduleId,
          billingContact
        );
      }
    } catch (error) {
      console.error("Error processing payment:", error);
    }
    setPaymentProcessing(false);
  };

  const processGiftCard = async () => {
    setPaymentProcessing(true);
    try {
      const token = await giftCardHandle.tokenize();
      if (token?.status !== "Cancel") {
        await submitPayment(token, null, scheduleId, billingContact);
      }
    } catch (error) {
      console.error("Error processing payment:", error);
    }
    setPaymentProcessing(false);
  };

  useEffect(async () => {
    setLoading(true);
    setGooglePayLoading(true);
    setApplePayLoading(true);

    const paymentRequest = buildPaymentRequest(payments);

    // Initialize Google Pay
    try {
      const googlePay = await payments.googlePay(paymentRequest);
      await googlePay.attach("#google-pay-button", {
        buttonColor: "black",
        buttonSizeMode: "fill",
        buttonType: "long",
      });
      setGooglePayHandle(googlePay);
      // Small delay to ensure the UI has time to render
      setTimeout(() => setGooglePayLoading(false), 300);
    } catch (error) {
      console.error("Error loading Google Pay:", error);
      setGooglePayLoading(false);
    }

    // Initialize Apple Pay
    try {
      const applePay = await payments.applePay(paymentRequest);
      setApplePayHandle(applePay);
      // Small delay to ensure the UI has time to render
      setTimeout(() => setApplePayLoading(false), 300);
    } catch (error) {
      console.error("Error loading Apple Pay:", error);
      setApplePayLoading(false);
    }

    setLoading(false);
  }, []);

  useEffect(async () => {
    if (payWithGiftCard) {
      setGiftCardLoading(true);
      // Initialize Gift Card Payments
      try {
        const giftCard = await payments.giftCard();
        await giftCard.attach("#gift-card-container");
        setGiftCardHandle(giftCard);
        // Small delay to ensure the UI has time to render
        setTimeout(() => setGiftCardLoading(false), 300);
      } catch (error) {
        console.error("Error loading Gift Card Payments:", error);
        setGiftCardLoading(false);
      }
    } else {
      setCardLoading(true);
      // Initialize Card Payments
      try {
        const card = await payments.card();
        setCard(card);
        await card.attach("#card-container");
        // Small delay to ensure the UI has time to render
        setTimeout(() => setCardLoading(false), 300);
      } catch (error) {
        console.error("Error loading Card Payments:", error);
        setCardLoading(false);
      }
    }
  }, [payWithGiftCard]);

  return (
    <>
      {paymentProcessing && <PaymentProcessingOverlay />}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          gap: GAP,
          marginBottom: GAP,
        }}
      >
        <div
          style={{
            position: "relative",
            width: `calc(50% - ${GAP / 2}px)`,
            height: 48,
          }}
        >
          {googlePayLoading && <ButtonSkeleton width="100%" height="100%" />}
          <div
            id="google-pay-button"
            onClick={(e) => {
              e.preventDefault();
              processGooglePay();
            }}
            style={{
              display: "inline-block",
              width: "100%",
              height: "100%",
              visibility: googlePayLoading ? "hidden" : "visible",
            }}
          ></div>
        </div>

        <div
          style={{
            position: "relative",
            width: `calc(50% - ${GAP / 2}px)`,
            height: 48,
          }}
        >
          {applePayLoading && <ButtonSkeleton width="100%" height="100%" />}
          <div
            id="apple-pay-button"
            className=""
            role="button"
            style={{
              ApplePayButtonStyle: "white",
              ApplePayButtonType: "plain",
              cursor: "pointer",
              display: "inline-block",
              height: "100%",
              width: "100%",
              WebkitAppearance: "-apple-pay-button",
              visibility: applePayLoading ? "hidden" : "visible",
            }}
            onClick={(e) => {
              e.preventDefault();
              processApplePay();
            }}
          ></div>
        </div>
      </div>

      <div style={{ display: payWithGiftCard ? "none" : "block" }}>
        <div
          style={{
            position: "relative",
            height: "100px",
          }}
        >
          {cardLoading && <CardSkeleton />}
          <div
            id="card-container"
            style={{
              visibility: cardLoading ? "hidden" : "visible",
            }}
          ></div>
        </div>
        <div style={{ marginTop: "30px" }}>
          <button
            type="submit"
            disabled={paymentProcessing || cardLoading}
            onClick={(e) => {
              e.preventDefault();
              processPayment();
            }}
            className="btn btn-default center-block btn-lg pbgt-yellow-btn"
            style={{ marginBottom: "10px", width: "100%" }}
          >
            {paymentProcessing ? "Processing payment..." : "Sign Up and Pay"}
          </button>

          <button
            onClick={toggleGiftCard}
            className="btn btn-default center-block btn-lg pbgt-yellow-btn"
            style={{ width: "100%" }}
          >
            Pay With Gift Card
          </button>
        </div>
      </div>

      <div style={{ display: payWithGiftCard ? "block" : "none" }}>
        <div
          style={{
            position: "relative",
            height: "100px",
          }}
        >
          {giftCardLoading && <CardSkeleton />}
          <div
            id="gift-card-container"
            style={{
              visibility: giftCardLoading ? "hidden" : "visible",
            }}
          ></div>
        </div>
        <div style={{ marginTop: "30px" }}>
          <button
            onClick={(e) => {
              e.preventDefault();
              processGiftCard();
            }}
            id="gift-card-button"
            type="button"
            disabled={paymentProcessing || giftCardLoading}
            className="btn btn-default center-block btn-lg pbgt-yellow-btn"
            style={{ marginBottom: "10px", width: "100%" }}
          >
            {paymentProcessing
              ? "Processing payment..."
              : "Sign Up and Pay with Gift Card"}
          </button>

          <button
            onClick={toggleGiftCard}
            className="btn btn-default center-block btn-lg pbgt-yellow-btn"
            style={{ width: "100%" }}
          >
            Pay With Credit Card
          </button>
        </div>
      </div>

      <div id="payment-status-container"></div>
    </>
  );
};

export default PBPaymentForm;
