import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import API from "../../api";
import JumbotronHeader from "./components/jumbotron-header";

/**
 * Email Verification Page Component
 * This component handles the email verification process by validating the verification code
 * from the URL parameter against the backend API.
 */
const VerifyEmail = () => {
  const { code } = useParams();
  const [verificationState, setVerificationState] = useState({
    isLoading: true,
    isVerified: false,
    message: "",
    error: "",
    email: "",
  });

  // Verify the email on component mount
  useEffect(() => {
    const verifyEmail = async () => {
      if (!code) {
        setVerificationState({
          isLoading: false,
          isVerified: false,
          error: "No verification code provided.",
          message: "",
        });
        return;
      }

      try {
        const response = await API.get(`verify-email/${code}`);

        setVerificationState({
          isLoading: false,
          isVerified: true,
          message: response.message,
          error: "",
          email: response.email || "",
        });
      } catch (error) {
        // Handle different error responses
        const errorMessage =
          error.response?.data?.message ||
          "An error occurred while verifying your email. Please try again later.";

        setVerificationState({
          isLoading: false,
          isVerified: false,
          error: errorMessage,
          message: "",
        });
      }
    };

    verifyEmail();
  }, [code]);

  // Render loading state
  if (verificationState.isLoading) {
    return (
      <>
        <JumbotronHeader title="Verifying Your Email" />
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card p-4 my-5 text-center">
              <div className="spinner-border text-primary mb-3" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <h3>Please wait while we verify your email...</h3>
            </div>
          </div>
        </div>
      </>
    );
  }

  // Render success state
  if (verificationState.isVerified) {
    return (
      <>
        <JumbotronHeader title="Email Verified" />
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card p-4 my-5">
              <div className="text-center mb-4">
                <i
                  className="bi bi-check-circle-fill text-success"
                  style={{ fontSize: "4rem" }}
                ></i>
              </div>
              <h3 className="text-center text-success mb-4">Success!</h3>
              <p className="lead text-center">{verificationState.message}</p>
              {verificationState.email && (
                <p className="text-center">
                  Email: <strong>{verificationState.email}</strong>
                </p>
              )}
              <div className="d-flex justify-content-center mt-4">
                <Link to="/" className="btn btn-outline-primary">
                  Return to Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  // Render error state
  return (
    <>
      <JumbotronHeader title="Email Verification Failed" />
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card p-4 my-5">
            <div className="text-center mb-4">
              <i
                className="bi bi-exclamation-triangle-fill text-danger"
                style={{ fontSize: "4rem" }}
              ></i>
            </div>
            <h3 className="text-center text-danger mb-4">
              Verification Failed
            </h3>
            <p className="lead text-center">{verificationState.error}</p>
            <div className="d-flex justify-content-center mt-4">
              <Link to="/contact" className="btn btn-primary me-3">
                Contact Support
              </Link>
              <Link to="/" className="btn btn-outline-primary">
                Return to Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
