import React from "react";
import { Link } from "react-router-dom";
import JumbotronHeader from "./components/jumbotron-header";

const links = [
  { title: "Payments", link: "#payments" },
  { title: "Refunds", link: "#refunds" },
  { title: "Code of Conduct", link: "#code-of-conduct" },
  { title: "Health and Medical", link: "#health-and-medical" },
];

const Policies = () => {
  return (
    <>
      <JumbotronHeader title="PointBlank Policies" links={links}>
        <div className="row">
          <div className="col-md-12 col-lg-8">
            <h1 className="pbgt-yellow">PointBlank Gun Training Policies</h1>
            <h4>
              Below you will find all of our Policies. These policies are
              subject to change at any time, without notice.
            </h4>
            <ul className="scrollTo">
              <li>
                <a href="#payment">Payment Policy</a>
              </li>
              <li>
                <a href="#refund">Refund Policy</a>
              </li>
              <li>
                <a href="#conduct">Code of Conduct Policy</a>
              </li>
              <li>
                <a href="#medical">Health and Medical Policy</a>
              </li>
            </ul>
          </div>
          <div className="hidden-xs hidden-sm hidden-md col-md-0 col-lg-4">
            <span className="fa-solid fa-th-list logo"></span>
          </div>
        </div>
      </JumbotronHeader>
      <div id="payments" class="container-fluid section-padding">
        <div class="row">
          <div class="col-xs-12">
            <h2>Payment Policy</h2>
            <p>
              All prices and fees are listed in U.S. Dollars. Prices are updated
              every calendar year. Prices and dates are subject to change
              without notice. For current prices, please refer to the PointBlank
              website at https://pointblankguntraining.com.
            </p>
            <p>
              Fifty (50) percent of the total course training fee will be due
              sixty (60) days prior to the schedule program arrival date. If
              this payment is not received by the deadline, PointBlank will
              consider your application withdrawn, your enrollment will be
              cancelled, and all cancellation penalties will apply. The
              remainder of the course training fee will be due in full thirty
              (30) days prior to the program arrival date. PointBlank will not
              be able to guarantee space in the training course or on-site
              accommodations until full payment has been received. If a
              participant wishes to sign up after the stated payment deadlines,
              full payment will be due upon successful signup, and all standard
              cancellation and refund policies apply.
            </p>
            <p>
              PointBlank accepts the following payment methods: cash, credit
              card, check and online via SquareUp payment processing. The data
              that you provide during this transaction will only be used for the
              purpose of recording your payment. PointBlank will abide by
              applicable data protection laws and regulations, ensure that the
              data is used for no other purpose, and is not disclosed to any
              third party unless there is a legal obligation for that
              disclosure.
            </p>
            <p></p>
          </div>
        </div>
      </div>

      <div id="refunds" class="container-fluid section-padding">
        <div class="row">
          <div class="col-xs-12">
            <h2>Refund &amp; Cancellation Policy</h2>
            <p>
              A deposit of half of the course training fee is required at the
              time of registration. The full training course registration fee is
              required to be paid in full 60 days prior to the class start date.
              All refunds are subject to a processing fee. All Fees paid are
              non-refundable 60 days or less prior to the training course start
              date.
            </p>
            <p>
              All course training cancellations and refund requests must be made
              in writing to PointBlank (by email, fax, or mail). Phone calls
              will not be accepted. The date of receipt of the refund request
              will determine the refund amount payable to the withdrawing
              participant. If a participant does not notify PointBlank about
              her/his withdrawal in writing, and does not arrive on-site on the
              scheduled program arrival date, no refunds will be issued.
            </p>
            <p>
              PointBlank reserves the right to change or cancel a training
              course at any time. Although PointBlank endeavors to run each of
              its training courses as scheduled, in the event that PointBlank
              cancels a training course prior to commencement of the course
              (excluding instances of Force Majeure), participants will receive
              a full refund of all fees and deposits, minus the processing fees.
            </p>
            <p>
              All refunds will be issued back to the original form of payment
              within 6-8 weeks of notification of cancellation. PointBlank
              undertakes no liability if this refund period is exceeded. If
              payment was made by a third-party (such as a parent or legal
              guardian) on behalf of a participant, the refund will be issued to
              the third-party.
            </p>
            <p>
              No refund will be issued if the participant is dismissed from the
              program as a result of a violation of PointBlank’s Code of Conduct
              or violation of law, including, but not limited to a range safety
              violation. Any expenses incurred by PointBlank in dismissing a
              participant from the program for such violations will be the
              responsibility of the participant.
            </p>
            <p>
              If a participant fails to submit required paperwork by the
              assigned deadlines and/or does not respond to PointBlank
              communications, she/he may be considered withdrawn from the
              training course and not eligible for a refund.
            </p>
            <p>
              If a participant does not fully take part in any included
              PointBlank training activities, no refunds will be issued for the
              unused services.
            </p>
          </div>
        </div>
      </div>

      <div id="code-of-conduct" class="container-fluid section-padding">
        <div class="row">
          <div class="col-xs-12">
            <h2>Code of Conduct &amp; Safety Policy</h2>
            <p>
              I certify that I will follow all safety rules as described by
              PointBlank including but not limited to the standard rules of
              firearms safety, and any other rules described by PointBlank
              either in person or in writing. The following rules are the main
              rules to firearms safety and does not constitute a complete rule
              list used at PointBlank training events:
            </p>
            <ul>
              <li>
                All firearms are to be pointed in a safe direction at all times.
                This includes away from people and other objects not designed to
                be fired upon or shot.
              </li>
              <li>
                I am to keep my finger off all firearm triggers until instructed
                otherwise.
              </li>
              <li>
                I am to keep all firearms unloaded until instructed otherwise.
              </li>
              <li>
                I will declare all firearms in my possession to any PointBlank
                staff member at any time during any training event. Failure to
                respond to a PointBlank staff member's request for firearm and
                weapon locations will result in my immediate removal from the
                training event and/or property. I will forfeit all payments and
                fees in this condition.
              </li>
            </ul>
            <p>
              If my behavior at any time is deemed by PointBlank, in its
              reasonable discretion, to be endangering myself or others, I
              understand that I may be dismissed from the training course.
            </p>
            <p>
              If I engage in any of the following behaviors on PointBlank
              properties OR at an event hosted by PointBlank (even if PointBlank
              is not the property owner), I will be immediately dismissed from
              the PointBlank course:
            </p>
            <ul>
              <li>Causing harm to myself or others;</li>
              <li>
                Physical, verbal, sexual, or psychological harassment or
                assault;
              </li>
              <li>
                Participating in criminal or unlawful behavior, including but
                not limited to theft, physical assault, the use of illegal
                drugs, and intoxication;
              </li>
              <li>
                Consumption of Alcohol or Drugs, whether legal or illegal;
              </li>
              <li>
                Possession or use of firearms or other weapons prior to
                PointBlank's express permission to do so.
              </li>
              <li>
                Disruption or obstruction of any training course activities or
                events.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div id="health-and-medical" class="container-fluid">
        <div class="row">
          <div class="col-xs-12">
            <h2>Health and Medical Responsibility Policy</h2>
            <p>
              I am responsible for consulting a physician or clinic prior to the
              training course to determine if I am healthy enough to attend such
              a training event at my own cost.
            </p>
            <p>
              I will be responsible for my own health maintenance while at any
              training course. In the event of serious illness, accident or
              emergency, I will inform a PointBlank on-site staff member so that
              assistance may be secured and my designated emergency contact may
              be notified, if necessary.
            </p>
            <p>
              I understand that all medical or emergency assistance costs,
              whether or not covered by my insurance policy, are my full
              responsibility, and PointBlank cannot and does not assume any
              legal responsibility for payment of such costs. I also understand
              that I may be responsible for paying for any treatment or
              assistance upfront and receiving later reimbursement from my
              insurance provider.
            </p>
            <p>
              I have fully informed PointBlank of any allergy and/or condition
              of my mental and/or physical health that might affect my
              participation in any training course or event. I understand that
              if I have not fully disclosed this information, PointBlank may not
              be able to provide me with the necessary accommodations. I cannot
              expect PointBlank to make arrangements for those situations that I
              have not disclosed and any false or inaccurate information may
              affect my program participation and refund appeals.
            </p>
            <p>
              I understand and agree that PointBlank does not have medical
              personnel available at the location of the training course or
              during transportation to or from any training event. I understand
              and agree that PointBlank is granted permission to authorize
              emergency medical treatment if necessary, and that such action by
              PointBlank shall be subject to the terms of this Agreement. I
              understand that PointBlank assumes no responsibility for any
              injury or damage which might arise out of or in connection with
              such authorized emergency medical treatment.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Policies;
