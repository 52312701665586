import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { trackConv } from "../common";
import { nav_links } from "./routes";

// Add print-specific styles to hide the header when printing
const printStyles = `
  @media print {
    .non-printable, 
    .sticky-header,
    nav, 
    .navbar,
    .pbgt-navbar-main,
    .top-row-header,
    .usa-color-line {
      display: none !important;
    }
  }
  
  /* Scrollable dropdown menus */
  .dropdown-menu {
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
  }
  
  /* Add explicit scroll indicator for long dropdowns */
  .dropdown-menu.scrollable {
    padding-bottom: 0px;
  }
  
  .dropdown-menu.scrollable::after {
    content: 'Scroll for more ▼';
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    text-align: center;
    padding: 8px 0;
    background-color: #f8f9fa;
    border-top: 1px solid #dee2e6;
    font-size: 12px;
    color: #6c757d;
    z-index: 1;
  }
`;

const Header = () => {
  const location = useLocation();

  useEffect(() => {
    // Function to check if dropdowns are scrollable and add class accordingly
    const handleDropdowns = () => {
      const dropdowns = document.querySelectorAll(".dropdown-menu");

      dropdowns.forEach((dropdown) => {
        // Check if dropdown is scrollable (content height > visible height)
        const isScrollable = dropdown.scrollHeight > dropdown.clientHeight;

        // Add or remove 'scrollable' class based on result
        if (isScrollable) {
          dropdown.classList.add("scrollable");
        } else {
          dropdown.classList.remove("scrollable");
        }
      });
    };

    // Add event listener for when Bootstrap shows a dropdown
    document.addEventListener("shown.bs.dropdown", handleDropdowns);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener("shown.bs.dropdown", handleDropdowns);
    };
  }, []);

  return (
    <>
      {/* Include print styles */}
      <style>{printStyles}</style>

      <div className="sticky-header non-printable">
        <div className="row full-width top-row-header">
          <div className="col-10 col-md-12">
            <div className="row full-width">
              <div className="d-flex align-items-center flex-row full-width">
                <Link to="/" title="Home">
                  <img
                    src="/images/template/point-blank-gun-training-logo-black-mobile.png"
                    alt="PointBlank Gun Training, LLC"
                    className="pbgt-logo"
                  />
                </Link>
                <div className="flex-grow-1">&nbsp;</div>
                <a
                  href="tel:+12188202793"
                  onClick={trackConv("phone", 0)}
                  title="Call Today!"
                  className="pbgt-phone"
                >
                  <i
                    className="fa-solid fa-phone"
                    style={{ paddingRight: 5 }}
                  ></i>
                  218-820-2793
                </a>
              </div>
            </div>
          </div>
          <div className="d-md-none d-lg-none d-xl-none d-xxl-none d-flex justify-content-end col-2">
            <button
              type="button"
              className="navbar-toggle pbgt-navbar-main-toggle"
              data-bs-toggle="collapse"
              data-bs-target="#mainNavbar"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
            >
              <i className="fa-solid fa-bars"></i>
            </button>
          </div>
        </div>
        <div className="container">
          <nav className="navbar navbar-expand-md pbgt-navbar-main sticky-top">
            <div className="navbar-collapse collapse" id="mainNavbar">
              <ul className="nav navbar-nav navbar-left d-flex align-items-end justify-content-center full-width">
                {nav_links.map((link, i) => {
                  if (link.dropdown) {
                    return (
                      <li key={i} className="dropdown">
                        <Link
                          to="#"
                          className="nav-link dropdown-toggle"
                          data-bs-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {link.label}
                          <span className="caret"></span>
                        </Link>
                        <ul className="dropdown-menu">
                          {link.dropdown.map((sublink, j) => {
                            if (sublink.header) {
                              return (
                                <li key={j} className="dropdown-header">
                                  {sublink.header}
                                </li>
                              );
                            } else {
                              return (
                                <li key={j} className="nav-item">
                                  <Link className="nav-link" to={sublink.path}>
                                    {sublink.label}
                                  </Link>
                                </li>
                              );
                            }
                          })}
                        </ul>
                      </li>
                    );
                  } else {
                    return (
                      <li key={i} className="nav-item">
                        <Link className="nav-link" to={link.path}>
                          {link.label}
                        </Link>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          </nav>
        </div>
        <div className="usa-color-line"></div>
      </div>
    </>
  );
};

export default Header;
