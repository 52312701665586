import React from "react";
import { Link } from "react-router-dom";
import JumbotronHeader from "../components/jumbotron-header";
import Testimonials from "../components/testimonials";
import WhyPointBlank from "../components/why-pointblank";
import Payment from "../components/payment";
import Pricing from "../components/pricing";
import UpcomingClassDates from "../components/upcoming-class-dates";
import { filter_schedule, getClassCost } from "../../../common";
import { useSchedule } from "../../../schedule-provider";
import SignupButtons from "../components/signup-buttons";
import RangeRulesExpectations from "../components/range-rules-expectations";

const links = [
  { title: "Schedule", link: "#schedule" },
  { title: "Sign Up", link: "#sign-up" },
  { title: "Testimonials", link: "#testimonials" },
];

const CLASS_ID = 12;

const AdvancedPistol = () => {
  const { schedule, activeClasses } = useSchedule();
  const dates = filter_schedule(schedule, CLASS_ID);
  const cost = getClassCost(activeClasses, CLASS_ID);

  return (
    <>
      <JumbotronHeader title="Advanced Pistol Training" links={links}>
        <div className="row">
          <h1 className="pbgt-yellow">Advanced Pistol </h1>
          <div className="col-md-6 col-lg-6">
            <p className="pbgt-body-text">
              This class pushes students beyond their practical skills, forcing
              them to think outside the box. The fast pace will mentally and
              physically test students. Awareness and observation will be a key
              to success in this course. Expect to shoot 300 or more rounds.
            </p>
            <p className="pbgt-body-text">
              This class covers many topics including use of cover, vehicle
              defense tactics, multiple threats, rapid fire, acceptable accuracy
              and more.
            </p>
            <p className="pbgt-body-text">
              This is not a beginner class, a usable safe draw stroke is
              required. Students will be expected to have a solid understanding
              of firearm fundamentals of both shooting and muzzle control. To
              learn the required skills for this class, take our{" "}
              <Link to="/intermediate-pistol-training" className="pbgt-yellow">
                Intermediate Handgun Training
              </Link>{" "}
              course first.
            </p>
            <p className="pbgt-body-text">
              This class will be hosted outdoors, so please check the gear list
              for required items.
            </p>
            <SignupButtons class_id={CLASS_ID} />
          </div>
          <div className="col-md-6 pbgt-gear-list pbgt-body-text">
            <h2>Gear List</h2>
            <ul>
              <li>Usable Safe Holster</li>
              <li>Electronic Ear Muffs preferred</li>
              <li>Handgun</li>
              <li>Multiple magazines</li>
              <li>Weather Appropriate Clothing</li>
              <li>300+ Rounds of Ammo</li>
              <li>Magazine Pouch</li>
              <li>Flashlight with usable holster</li>
              <li>Backup firearm optional</li>
            </ul>
          </div>
        </div>
      </JumbotronHeader>

      <RangeRulesExpectations />

      <UpcomingClassDates
        title="Advanced Pistol Training"
        description="Advanced Pistol Training"
        dates={dates}
        cost={cost}
      />

      <Payment dates={dates} />

      <Testimonials />
    </>
  );
};

export default AdvancedPistol;
