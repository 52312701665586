import moment from "moment-timezone";

export const trackConv = (type, value) => {
  var label = ""; // default signup label
  if (value === undefined) {
    value = "";
  } else {
    value = "value=" + value + "&currency_code=USD&";
  }
  switch (type) {
    case "signup":
      label = "5peACIOH320Q6K2lnQM";
      break;
    case "phone":
      label = "Wb6xCK2-2W0Q6K2lnQM";
      break;
    case "atc":
      label = "sBw0CPDF2W0Q6K2lnQM";
      break;
    case "newsletter":
      label = "Mm7ACOWTjW4Q6K2lnQM";
      break;
    case "error":
      label = "T4yWCKWsxW0Q6K2lnQM";
      break;
    default:
  }

  const image = new Image(1, 1);
  image.src = `//www.googleadservices.com/pagead/conversion/866735848/?${value}label=${label}&guid=ON&script=0`;
};

export const filter_schedule = (schedule, class_id) => {
  return schedule
    .filter(
      (s) =>
        new Date(s.start_date) > new Date() &&
        (Array.isArray(class_id)
          ? class_id.includes(s.class_id)
          : class_id === s.class_id) &&
        s.open_slots > 0
    )
    .map((s) => ({
      index: s.index,
      class_title: s.class_title,
      date_start: s.start_date,
      date_end: s.end_date,
      deposit_cost: s.deposit_cost,
      total_cost: s.total_cost,
      open_slots: s.open_slots,
    }));
};

export const getClassCost = (activeClasses, class_id) => {
  const _class = activeClasses.find((c) => c.class_id === class_id);
  return _class?.default_cost;
};

export const formatDate = (date) => {
  // Use moment-timezone for consistent formatting
  return moment(date).tz("America/Chicago").format("ddd MM/DD/YYYY hh:mm A");
};

// Get a Date object in Central Time regardless of user's local timezone
export const getCentralTimeDate = (date) => {
  return moment(date).tz("America/Chicago").toDate();
};

// Format date for display with options, ensuring Central Time
export const formatCentralTime = (date, options = {}) => {
  if (!date) return "";

  // When we have a date, we need to preserve the exact date and time
  // but show it in Central Time context without conversion
  const centralDate = moment(date).tz("America/Chicago", true);

  // Default formatting settings
  const defaultOptions = {
    weekday: "short",
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: "America/Chicago",
  };

  // If user passes simple format strings, convert to moment format
  if (typeof options === "string") {
    return centralDate.format(options);
  }

  // Handle dateStyle and timeStyle options which may not be supported in all browsers
  // Convert them to standard format options
  if (options.dateStyle || options.timeStyle) {
    // Convert dateStyle to equivalent moment format
    let format = "";

    if (options.dateStyle === "full") {
      format = "dddd, MMMM D, YYYY";
    } else if (options.dateStyle === "long") {
      format = "MMMM D, YYYY";
    } else if (options.dateStyle === "medium") {
      format = "MMM D, YYYY";
    } else if (options.dateStyle === "short") {
      format = "M/D/YYYY";
    }

    // Add time format if timeStyle is provided
    if (options.timeStyle === "full") {
      format += " h:mm:ss A z";
    } else if (options.timeStyle === "long") {
      format += " h:mm:ss A";
    } else if (options.timeStyle === "medium") {
      format += " h:mm A";
    } else if (options.timeStyle === "short") {
      format += " h:mm A";
    }

    return centralDate.format(format);
  }

  // Otherwise, use standard toLocaleString with options
  // First convert to ISO string to ensure consistent date interpretation
  const isoStr = centralDate.format();
  return new Date(isoStr).toLocaleString("en-US", {
    ...defaultOptions,
    ...options,
  });
};
