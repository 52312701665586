import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { states } from "./common";
import { formatCentralTime } from "../../../common";

import PaymentForm from "./payment-form";

const Payment = ({ dates = [] }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [classDate, setClassDate] = useState("");
  const [selectedDate, setSelectedDate] = useState({});
  const [paymentAmount, _setPaymentAmount] = useState(0);
  const [payDepositOnly, setPayDepositOnly] = useState(false);
  const [renderPaymentForm, setRenderPaymentForm] = useState(false);
  const [studentCount, setStudentCount] = useState(1);

  const showPaymentForm = () => {
    setRenderPaymentForm(true);
  };

  const hidePaymentForm = () => {
    setRenderPaymentForm(false);
  };

  const togglePayDeposit = () => {
    setPayDepositOnly(!payDepositOnly);
    updatePaymentAmount(!payDepositOnly, studentCount);
  };

  const updatePaymentAmount = (isDepositOnly, students) => {
    if (selectedDate.index === undefined) return;

    if (isDepositOnly) {
      // Deposit only - use deposit cost * number of students
      const deposit = parseFloat(selectedDate.deposit_cost) * students;
      _setPaymentAmount(deposit);
    } else {
      // Full payment - use total cost * number of students
      _setPaymentAmount(parseFloat(selectedDate.total_cost) * students);
    }
  };

  const handleStudentCountChange = (e) => {
    const count = parseInt(e.target.value);
    setStudentCount(count);
    updatePaymentAmount(payDepositOnly, count);
  };

  const handleContinueToPayment = () => {
    showPaymentForm();
  };

  const checkBillingInfo = () => {
    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      phone === "" ||
      address === "" ||
      city === "" ||
      state === "" ||
      zip === ""
    ) {
      return false;
    }
    return true;
  };

  const updateSelectedDate = (date) => {
    setClassDate(date);

    const _selectedDate = dates.find((d) => d.date_start === date);
    if (_selectedDate === undefined) {
      _setPaymentAmount(0);
      setStudentCount(1);
      return;
    }
    setSelectedDate(_selectedDate);

    // Reset student count when changing dates
    setStudentCount(1);

    // Set payment amount based on current deposit toggle state
    updatePaymentAmount(payDepositOnly, 1);
  };

  return (
    <div
      id="sign-up"
      className="container-fluid jumbotron-dark section-padding"
    >
      <h2 className="text-center hide-after-signup">Sign Up Online</h2>

      {dates.length === 0 ? (
        <div className="row">
          <div className="col-sm-2"></div>
          <div className="col-sm-8">
            <h3>Online Sign Up is Unavailable Currently for this Class</h3>
            <p className="pbgt-body-text">
              We do not currently have any dates scheduled for this class at
              this time.{" "}
              <Link to="/contact" className="pbgt-yellow">
                Please contact us about our upcoming schedule
              </Link>{" "}
              to setup your own Custom Class.
            </p>
          </div>
          <div className="col-sm-2"></div>
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-sm-3"></div>
            <div className="col-sm-6">
              <form id="payment-form">
                <h3>Student Information</h3>
                <div className="form-group">
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Phone</label>
                  <input
                    type="tel"
                    className="form-control"
                    id="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <br />
                <h3>Billing Information</h3>
                <div className="form-group">
                  <label htmlFor="address">Address</label>
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="city">City</label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="state">State</label>
                  <select
                    className="form-control"
                    id="state"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  >
                    <option value="">Select State</option>
                    {states.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="zip">Zip Code</label>
                  <input
                    type="text"
                    className="form-control"
                    id="zip"
                    value={zip}
                    onChange={(e) => setZip(e.target.value)}
                  />
                </div>
                <br />
                <h3>Class Selection</h3>
                <div className="form-group">
                  <label htmlFor="classDate">Class Date</label>
                  <select
                    className="form-control"
                    id="classDate"
                    value={classDate}
                    onChange={(e) => updateSelectedDate(e.target.value)}
                  >
                    <option value="">Select Class Date</option>
                    {dates.map((date) => (
                      <option key={date.date_start} value={date.date_start}>
                        ${date.total_cost} -{" "}
                        {formatCentralTime(new Date(date.date_start))} -{" "}
                        {date.open_slots} open slots
                      </option>
                    ))}
                  </select>
                </div>

                {selectedDate.index !== undefined && (
                  <div className="form-group mt-3">
                    <label htmlFor="studentCount">Number of Students</label>
                    <select
                      className="form-control"
                      id="studentCount"
                      value={studentCount}
                      onChange={handleStudentCountChange}
                    >
                      {[...Array(selectedDate.open_slots)].map((_, i) => (
                        <option key={i + 1} value={i + 1}>
                          {i + 1} {i === 0 ? "student" : "students"}
                        </option>
                      ))}
                    </select>
                    <small className="form-text text-muted">
                      Select the total number of students attending (including
                      yourself).
                    </small>
                  </div>
                )}

                {selectedDate.index !== undefined && (
                  <div className="form-group mt-3">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="payDepositSwitch"
                        checked={payDepositOnly}
                        onChange={togglePayDeposit}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="payDepositSwitch"
                      >
                        Pay Deposit Only ($
                        {(
                          parseFloat(selectedDate.deposit_cost) * studentCount
                        ).toFixed(2)}
                        )
                      </label>
                    </div>
                    <div className="mt-2">
                      <strong>Payment Amount: </strong>$
                      {paymentAmount.toFixed(2)}
                      {payDepositOnly && (
                        <div>
                          Remaining balance: $
                          {(
                            parseFloat(selectedDate.total_cost) * studentCount -
                            paymentAmount
                          ).toFixed(2)}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <br />
                {paymentAmount === 0 || !checkBillingInfo() ? (
                  <>
                    <p className="pbgt-body-text">
                      Please fill out all fields in order to process your
                      payment and reserve a spot in our class.
                    </p>
                  </>
                ) : (
                  <>
                    <p className="pbgt-body-text">
                      <b>NOTE:</b> Your payment will be processed securely
                      through Square. We do not store any of your credit card
                      information.
                    </p>

                    {!renderPaymentForm ? (
                      <button
                        type="button"
                        className="btn btn-default center-block btn-lg pbgt-yellow-btn"
                        onClick={handleContinueToPayment}
                      >
                        Proceed to Payment
                      </button>
                    ) : (
                      <PaymentForm
                        scheduleId={selectedDate.index}
                        paymentAmount={paymentAmount}
                        numberOfStudents={studentCount}
                        billingContact={{
                          firstName,
                          lastName,
                          email,
                          phone,
                          address,
                          city,
                          state,
                          postalCode: zip,
                          amount: paymentAmount,
                        }}
                        _paymentDetails={{
                          amount: paymentAmount,
                          class_name: selectedDate.class_title,
                          class_id: selectedDate.index,
                          product_url: "https://pointblankguntraining.com",
                          students: studentCount,
                        }}
                      />
                    )}
                  </>
                )}
              </form>
            </div>
            <div className="col-sm-3"></div>
          </div>
        </>
      )}
    </div>
  );
};

export default Payment;
