import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AdminHeader from "./admin-header";
import moment from "moment-timezone";

import API from "../../../api";
import Loading from "../components/loading";
import SortableTable from "../components/sortable-table";
import ClassDateModal from "../components/class-date-modal";
import { createToast } from "./common";
import { handleApiError } from "./error-utils";
import { formatCentralTime } from "../../../common";

// Function to convert an array of schedule entries to appropriate data types (dates to Dates, string floats to floats)
const convertScheduleData = (data) => {
  return data.map((entry) => {
    entry.start_date = new Date(entry.start_date);
    entry.end_date = new Date(entry.end_date);
    entry.total_cost = parseFloat(entry.total_cost);
    entry.deposit_cost = parseFloat(entry.deposit_cost);
    return entry;
  });
};

let args = {};
const setArgs = (key, value) => {
  args[key] = value;
};
const removeArg = (key) => {
  delete args[key];
};
const reduceArgs = () => {
  return Object.keys(args).map((key) => `${key}=${args[key]}`);
};

const fetchSchedule = async () => {
  console.log(reduceArgs());
  const response = await API.get("schedule", reduceArgs());
  return convertScheduleData(response);
};

const fetchClassInfo = async () => {
  const class_info = await API.get("classes/");
  return class_info;
};

const ExtraControls = ({ loading, setLoading, setSchedule }) => {
  const [includePast, setIncludePast] = useState(false);
  const [live, setLive] = useState(false);

  const updateIncludePast = (e) => {
    if (e.target.checked) {
      setArgs("past", 1);
    } else {
      removeArg("past");
    }
    setIncludePast(e.target.checked);
  };

  const updateLive = (e) => {
    if (e.target.checked) {
      setArgs("live", 1);
    } else {
      removeArg("live");
    }
    setLive(e.target.checked);
  };

  useEffect(async () => {
    // Only fetch the schedule if the includePast state changes and data is not loading
    if (!loading) {
      setLoading(true);
      try {
        const response = await fetchSchedule();
        setSchedule(response);
      } catch (error) {
        handleApiError(error, createToast, "Error fetching schedule");
      }
      setLoading(false);
    }
  }, [includePast, live]);

  useEffect(() => {
    args = {};
    //setArgs("live", 1);
  }, []);

  return (
    <>
      <div className="spacer" />
      <label style={{ display: "inline-flex", alignItems: "center" }}>
        Include Dates Before Today:&nbsp;
        <input
          type="checkbox"
          onChange={updateIncludePast}
          checked={includePast}
          style={{ marginLeft: "4px" }}
        />
      </label>
      &nbsp;&nbsp;
      <label style={{ display: "inline-flex", alignItems: "center" }}>
        Public Classes Only:&nbsp;
        <input
          type="checkbox"
          onChange={updateLive}
          checked={live}
          style={{ marginLeft: "4px" }}
        />
      </label>
      &nbsp;&nbsp;
      {loading ? (
        <Loading
          height={20}
          width={40}
          paddingTop={0}
          paddingRight={0}
          marginTop={-2}
          marginLeft={45}
          fontSize={20}
        />
      ) : null}
    </>
  );
};

const AdminSchedule = () => {
  const navigate = useNavigate();
  const [schedule, setSchedule] = useState([]);
  const [classInfo, setClassInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [_forceUpdate, setForceUpdate] = useState(false);
  const [_resetNewModalDefaults, setResetNewModalDefaults] = useState(false);
  const [editModalDefaults, setEditModalDefaults] = useState(null);
  const [_resetEditModalDefaults, setResetEditModalDefaults] = useState(false);

  const forceUpdate = () => setForceUpdate(!_forceUpdate);
  const resetNewModalDefaults = () =>
    setResetNewModalDefaults(!_resetNewModalDefaults);
  const resetEditModalDefaults = () =>
    setResetEditModalDefaults(!_resetEditModalDefaults);

  useEffect(async () => {
    try {
      const class_info = await fetchClassInfo();
      const active_classes = class_info
        .filter((c) => c.active)
        .sort((a, b) => a.class_title.localeCompare(b.class_title));
      setClassInfo([...active_classes]);
    } catch (error) {
      handleApiError(error, createToast, "Error fetching class info");
    }
  }, []);

  const populateAndShowEditModal = (row) => {
    const copy = { ...row };
    copy.class_type = copy.class_id;

    // Format dates for the datetime-local input without timezone offset issues
    const formatToLocalDateTime = (date) => {
      // When date comes from the database, it's UTC
      // We want to preserve the exact same date and time but interpret it as Central Time
      // Clone the date to avoid modifying the original
      const d = new Date(date);

      // Use explicit formatting to avoid timezone shifts
      // This preserves the exact same date/time values but in Central timezone context
      const centralDate = moment(d).tz("America/Chicago", true);
      return centralDate.format("YYYY-MM-DDTHH:mm");
    };

    copy.start_date = formatToLocalDateTime(row.start_date);
    copy.end_date = formatToLocalDateTime(row.end_date);

    setEditModalDefaults(copy);
    resetEditModalDefaults();

    // Show the edit modal
    const modalElement = document.getElementById("editClassDateModal"); // Get the modal element
    const modalInstance = bootstrap.Modal.getOrCreateInstance(modalElement); // Get the modal instance
    modalInstance.show();
  };

  const handleCopySignupLink = (row) => {
    try {
      const signupLink = `${window.location.origin}/private-signup/${row.private_uuid}`;
      navigator.clipboard.writeText(signupLink);
      createToast("success", "Signup link copied to clipboard!");
    } catch (error) {
      createToast("error", "Error copying signup link");
    }
  };

  const headers = [
    { key: "index", text: "ID" },
    { key: "class_title", text: "Class Type" },
    { key: "start_date", text: "Start Date" },
    { key: "end_date", text: "End Date" },
    {
      key: "total_cost",
      text: "Total Cost",
      render: (row) => `${row.total_cost.toFixed(2)}`,
    },
    {
      key: "live",
      text: "Public",
      render: (row) => (row.live === 1 ? "✅" : "❌"),
    },
    {
      key: "current_students",
      text: "Current / Total Students",
      render: (row) => `${row.current_students} / ${row.total_students}`,
    },
    {
      key: "actions",
      text: "Actions",
      render: (row) => {
        const classDetails = classInfo.find((c) => c.class_id === row.class_id);
        const hasGearList = classDetails && classDetails.gear_list !== "none";
        const isPrivate = row.live === 0; // If live is 0, it's a private class
        const shouldDisplayButtons = !(
          row.class_title === "Range Closed" ||
          row.class_title === "Remove from class"
        );

        return (
          <>
            {shouldDisplayButtons && (
              <>
                {hasGearList && (
                  <>
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={(event) => {
                        event.stopPropagation();
                        navigate(
                          `/--admin-gear-list/${row.index}/${row.class_id}`
                        );
                      }}
                    >
                      <i className="bi bi-printer me-1"></i>
                      Print Gear List
                    </button>
                    &nbsp;
                  </>
                )}
                <button
                  type="button"
                  className="btn btn-info btn-sm"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleCopySignupLink(row);
                  }}
                >
                  <i className="bi bi-link-45deg me-1"></i>
                  Copy Link
                </button>
                &nbsp;
              </>
            )}
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              onClick={(event) => {
                event.stopPropagation();
                navigate(`/--admin-class-students/${row.index}`);
              }}
            >
              <i className="bi bi-people me-1"></i>
              Students
            </button>
          </>
        );
      },
    },
  ];

  useEffect(async () => {
    try {
      const schedule = await fetchSchedule();
      setSchedule(schedule);
    } catch (error) {
      handleApiError(error, createToast, "Error fetching schedule");
    }
    setLoading(false);
  }, [_forceUpdate]);

  return (
    <>
      <AdminHeader title="Date Schedule">
        View and Edit the upcoming class schedule. This shows a list of the
        upcoming classes, ordered by class date. Editing a class date includes
        total students, cost, date and class type. Student signups can be viewed
        and managed for each class date.
      </AdminHeader>
      <div className="row section-padding">
        <div className="col-sm-1" />
        <div className="col-xs-12 col-sm-10">
          <SortableTable
            headers={headers}
            data={schedule}
            default_sort_column={headers[2].key}
            default_sort_direction="desc"
            onRowClick={populateAndShowEditModal}
            isRowClickable={(row) => row.class_title !== "Remove from class"}
            extra_controls={
              <ExtraControls
                loading={loading}
                setLoading={setLoading}
                setSchedule={setSchedule}
              />
            }
          />

          <button
            type="button"
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#newClassDateModal"
            onClick={resetNewModalDefaults}
          >
            Create New Date
          </button>

          <ClassDateModal
            id="newClassDateModal"
            mode="create"
            onSuccess={forceUpdate}
            classInfo={classInfo}
            resetDefaults={_resetNewModalDefaults}
          />
          <ClassDateModal
            id="editClassDateModal"
            mode="edit"
            onSuccess={forceUpdate}
            classInfo={classInfo}
            defaults={
              editModalDefaults === null ? undefined : editModalDefaults
            }
            resetDefaults={_resetEditModalDefaults}
          />
        </div>
      </div>
    </>
  );
};

export default AdminSchedule;
