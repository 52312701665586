import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AdminHeader from "./admin-header";
import API from "../../../api";
import Loading from "../components/loading";
import SortableTable from "../components/sortable-table";
import { createToast } from "./common";
import { handleApiError } from "./error-utils";
import { formatCentralTime } from "../../../common";

// Ensure bootstrap is properly imported or accessed
const getBootstrap = () => {
  // Return the global bootstrap object or null if it's not available
  return typeof window !== "undefined" && window.bootstrap
    ? window.bootstrap
    : null;
};

const StudentEditModal = ({
  student,
  classInfo,
  onSave,
  onClose,
  isSaving,
}) => {
  // Create a ref for the modal
  const modalRef = useRef(null);
  const modalInstance = useRef(null);

  useEffect(() => {
    // Initialize modal when component mounts
    const bootstrap = getBootstrap();
    if (bootstrap && modalRef.current) {
      modalInstance.current = new bootstrap.Modal(modalRef.current);
      modalInstance.current.show();
    }

    // Clean up the modal when component unmounts
    return () => {
      if (modalInstance.current) {
        modalInstance.current.hide();
      }
    };
  }, []); // Empty dependency array means this only runs once when component mounts

  const [formData, setFormData] = useState({
    firstn: student?.firstn || "",
    lastn: student?.lastn || "",
    email: student?.email || "",
    phone: student?.phone || "",
    paid: student?.paid || 0,
    students: student?.students || 1,
    memo: student?.memo || "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]:
        name === "paid" || name === "students" ? parseInt(value, 10) : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(student?.signupid, formData);
  };

  return (
    <div
      ref={modalRef}
      className="modal fade"
      id="studentEditModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="studentEditModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="studentEditModalLabel">
              {student ? "Edit Student" : "Add New Student"}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
              disabled={isSaving}
            ></button>
          </div>
          <div className="modal-body">
            <form id="studentEditForm" onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="firstn" className="form-label">
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstn"
                  name="firstn"
                  value={formData.firstn}
                  onChange={handleChange}
                  required
                  disabled={isSaving}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="lastn" className="form-label">
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lastn"
                  name="lastn"
                  value={formData.lastn}
                  onChange={handleChange}
                  required
                  disabled={isSaving}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  disabled={isSaving}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Phone
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  disabled={isSaving}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="paid" className="form-label">
                  Amount Paid
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="paid"
                  name="paid"
                  value={formData.paid / 100}
                  onChange={(e) => {
                    // Convert dollars input to cents for storage
                    const dollarAmount = parseFloat(e.target.value) || 0;
                    const centsAmount = Math.round(dollarAmount * 100);
                    setFormData({
                      ...formData,
                      paid: centsAmount,
                    });
                  }}
                  disabled={isSaving}
                />
                {classInfo && (
                  <div className="form-text">
                    Total class cost: $
                    {(
                      (classInfo.class_cost * 100 * formData.students) /
                      100
                    ).toFixed(2)}{" "}
                    | Remaining: $
                    {(
                      (classInfo.class_cost * 100 * formData.students -
                        formData.paid) /
                      100
                    ).toFixed(2)}
                  </div>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="students" className="form-label">
                  Group Size
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="students"
                  name="students"
                  min="1"
                  value={formData.students}
                  onChange={handleChange}
                  disabled={isSaving}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="memo" className="form-label">
                  Memo
                </label>
                <textarea
                  className="form-control"
                  id="memo"
                  name="memo"
                  rows="3"
                  value={formData.memo}
                  onChange={handleChange}
                  disabled={isSaving}
                ></textarea>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={onClose}
              disabled={isSaving}
            >
              Cancel
            </button>
            <button
              type="submit"
              form="studentEditForm"
              className="btn btn-primary"
              disabled={isSaving}
            >
              {isSaving ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Saving...
                </>
              ) : (
                "Save Changes"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ClassStudents = () => {
  const { scheduleId } = useParams();
  const navigate = useNavigate();

  const [students, setStudents] = useState([]);
  const [classInfo, setClassInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [currentStudent, setCurrentStudent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [studentsToRemove, setStudentsToRemove] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch schedule info first
        const scheduleResponse = await API.get(`schedule/${scheduleId}`);

        if (!scheduleResponse || scheduleResponse.length === 0) {
          createToast("Schedule not found", "Error", true);
          setLoading(false);
          return;
        }

        // Extract class_id from schedule to fetch class info
        const scheduleData = scheduleResponse[0] || scheduleResponse;
        const classId = scheduleData.class_id;

        // Fetch class info
        const classResponse = await API.get(`classes/${classId}`);

        if (!classResponse) {
          createToast("Class not found", "Error", true);
          setLoading(false);
          return;
        }

        // Combine schedule and class data
        setClassInfo({
          schedule_id: scheduleId,
          class_id: classId,
          class_title: classResponse[0].class_title || scheduleData.class_title,
          class_date: new Date(scheduleData.start_date),
          class_cost: parseFloat(scheduleData.total_cost),
          class_total_students: scheduleData.total_students,
          current_students: scheduleData.current_students,
        });

        // Fetch students for this schedule
        const studentsResponse = await API.get(
          `schedule/${scheduleId}/students`
        );
        if (studentsResponse) {
          setStudents(studentsResponse);
        }

        setLoading(false);
      } catch (error) {
        // Use the new error handling utility
        handleApiError(error, createToast, "Error loading data");
        setLoading(false);
      }
    };

    fetchData();
  }, [scheduleId]);

  const handleEditStudent = (student) => {
    setCurrentStudent(student);
    setIsEditing(true);
    setShowModal(true);
  };

  const handleAddStudent = () => {
    setCurrentStudent(null);
    setIsEditing(false);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    // Don't allow closing while saving
    if (isSaving) return;

    setShowModal(false);
    setCurrentStudent(null);
  };

  const handleSaveStudent = async (signupId, formData) => {
    try {
      // Use separate saving state for the form
      setIsSaving(true);

      // Validate required fields
      if (!formData.firstn.trim() || !formData.lastn.trim()) {
        createToast("First and last name are required", "Error", true);
        setIsSaving(false);
        return; // Keep modal open by returning early
      }

      // Create a copy of the current students
      let updatedStudents = [...students];

      if (isEditing) {
        // Update existing student in the local array
        updatedStudents = updatedStudents.map((student) =>
          student.signupid === signupId ? { ...student, ...formData } : student
        );
      } else {
        // Add new student to the local array with a temporary negative ID
        // The server will assign a real ID and return it in the response
        const tempId = -Math.floor(Math.random() * 1000000); // Temporary negative ID
        updatedStudents.push({
          ...formData,
          signupid: tempId,
        });
      }

      // Prepare payload for the API that includes all students and any to remove
      const payload = {
        students: updatedStudents,
        remove: studentsToRemove,
      };

      // Send complete update to the server
      await API.patch(`schedule/${scheduleId}/students`, payload);

      // Clear the removal list
      setStudentsToRemove([]);

      // Success toast
      createToast(
        isEditing
          ? "Student updated successfully"
          : "Student added successfully",
        "Success"
      );

      // Reset saving state
      setIsSaving(false);

      // Close modal only on success
      setShowModal(false);
      setCurrentStudent(null);

      // Refresh the student list and class info from the server to get the latest state
      setLoading(true);
      const [studentsResponse, scheduleResponse] = await Promise.all([
        API.get(`schedule/${scheduleId}/students`),
        API.get(`schedule/${scheduleId}`),
      ]);

      setStudents(studentsResponse);

      if (scheduleResponse && scheduleResponse.length > 0) {
        setClassInfo((prev) => ({
          ...prev,
          current_students: scheduleResponse[0].current_students,
        }));
      }

      setLoading(false);
    } catch (error) {
      // Use the new error handling utility
      handleApiError(error, createToast, "Error saving student");
      // Reset saving state but keep modal open with data intact
      setIsSaving(false);
      // Don't close the modal or clear form data on error
    }
  };

  const handleRemoveStudent = async (signupId) => {
    if (
      !confirm("Are you sure you want to remove this student from the class?")
    ) {
      return;
    }

    try {
      setLoading(true);

      // Use API.delete to move student to "Remove from class" instead of deleting
      await API.delete(`signups/${signupId}`);

      // Success toast
      createToast("Student removed successfully", "Success");

      // Refresh the student list and class info from the server
      const [studentsResponse, scheduleResponse] = await Promise.all([
        API.get(`schedule/${scheduleId}/students`),
        API.get(`schedule/${scheduleId}`),
      ]);

      setStudents(studentsResponse);

      if (scheduleResponse && scheduleResponse.length > 0) {
        setClassInfo((prev) => ({
          ...prev,
          current_students: scheduleResponse[0].current_students,
        }));
      }

      setLoading(false);
    } catch (error) {
      // Use the new error handling utility
      handleApiError(error, createToast, "Error removing student");
      setLoading(false);
    }
  };

  const headers = [
    { key: "signupid", text: "ID" },
    {
      key: "firstn",
      text: "Name",
      render: (row) => `${row.firstn} ${row.lastn}`,
    },
    {
      key: "email",
      text: "Email",
      render: (row) =>
        console.log(row) || (
          <span
            className="text-primary clickable"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation(); // Prevent row click/edit modal
              navigator.clipboard.writeText(row.email);
              createToast("Email copied to clipboard!", "Success");
            }}
            title="Click to copy email"
          >
            {row.email}
          </span>
        ),
    },
    { key: "phone", text: "Phone" },
    {
      key: "timestamp",
      text: "Signup Date",
      render: (row) =>
        formatCentralTime(row.timestamp, {
          year: "numeric",
          month: "short",
          day: "numeric",
        }),
    },
    {
      key: "paid",
      text: "Payment",
      render: (row) =>
        classInfo
          ? `$${(row.paid / 100).toFixed(2)} / $${(
              classInfo.class_cost * row.students
            ).toFixed(2)}`
          : `$${(row.paid / 100).toFixed(2)}`,
    },
    {
      key: "remaining",
      text: "Remaining",
      render: (row) =>
        `$${(classInfo.class_cost * row.students - row.paid / 100).toFixed(2)}`,
    },
    { key: "students", text: "Group Size" },
    {
      key: "actions",
      text: "Actions",
      render: (row) => (
        <button
          type="button"
          className="btn btn-danger btn-sm"
          onClick={(e) => {
            e.stopPropagation();
            handleRemoveStudent(row.signupid);
          }}
        >
          <i className="bi bi-trash me-1"></i>
          Remove
        </button>
      ),
    },
  ];

  if (loading && !showModal) {
    return (
      <AdminHeader>
        <Loading />
      </AdminHeader>
    );
  }

  return (
    <>
      <AdminHeader>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h1 className="pbgt-yellow">
              Students for {classInfo?.class_title}
            </h1>
            <h3 className="text-white">
              {formatCentralTime(classInfo?.class_date, {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </h3>
            <h4 className="text-white">
              {classInfo?.current_students} / {classInfo?.class_total_students}{" "}
              students enrolled
            </h4>
          </div>
          <button
            className="btn btn-secondary"
            onClick={() => navigate("/--admin-schedule")}
            disabled={isSaving}
          >
            <i className="bi bi-arrow-left me-2"></i>
            Back to Schedule
          </button>
        </div>
      </AdminHeader>

      <div className="row section-padding">
        <div className="col-sm-1" />
        <div className="col-xs-12 col-sm-10">
          {loading ? (
            <Loading />
          ) : (
            <>
              <SortableTable
                headers={headers}
                data={students}
                default_sort_column="signupid"
                onRowClick={handleEditStudent}
              />

              <button
                type="button"
                className="btn btn-primary mt-3"
                onClick={handleAddStudent}
                disabled={
                  isSaving ||
                  (classInfo &&
                    classInfo.current_students >=
                      classInfo.class_total_students)
                }
              >
                <i className="bi bi-person-plus me-2"></i>
                Add Student
              </button>
            </>
          )}
        </div>
      </div>

      {showModal && (
        <StudentEditModal
          student={currentStudent}
          classInfo={classInfo}
          onSave={handleSaveStudent}
          onClose={handleCloseModal}
          isSaving={isSaving}
        />
      )}
    </>
  );
};

export default ClassStudents;
