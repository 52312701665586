import React from "react";
import { Link } from "react-router-dom";

// Data format
// const data = [
//   {
//     date: "2024-07-30",
//     time: "6:00 PM",
//   },
// ];

const getDateInfo = (date) => {
  const dateObj = new Date(date);
  // Format in Central Time
  const options = { timeZone: "America/Chicago" };

  return {
    shortMonth: dateObj.toLocaleString("default", {
      month: "short",
      ...options,
    }),
    fullMonth: dateObj.toLocaleString("default", { month: "long", ...options }),
    monthNumber:
      new Date(dateObj.toLocaleString("en-US", options)).getMonth() + 1,
    dayOfMonth: new Date(dateObj.toLocaleString("en-US", options)).getDate(),
    dayOfWeek: dateObj.toLocaleString("default", {
      weekday: "long",
      ...options,
    }),
    year: new Date(dateObj.toLocaleString("en-US", options)).getFullYear(),
    formattedDate: (() => {
      const centralDate = new Date(dateObj.toLocaleString("en-US", options));
      return `${centralDate.getFullYear()}-${
        centralDate.getMonth() + 1
      }-${centralDate.getDate()}`;
    })(),
    _24Time: dateObj.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      ...options,
    }),
    _12Time: dateObj.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      ...options,
    }),
  };
};

const buildAddToCalendarLinkArgs = (
  title,
  description,
  start_date,
  start_time,
  end_date,
  end_time
) => {
  return `utz=-300&uln=en-US&vjs=1.5&e[0][date_start]=${start_date}%20${start_time}&e[0][date_end]=${end_date}%20${end_time}&e[0][title]=${title}&e[0][description]=${description}&e[0][location]=${location}&e[0][timezone]=${timezone}&e[0][organizer]=${organizer}&e[0][organizer_email]=${organizer_email}`;
};

const location =
  "Outdoor Range Location is private and will be sent before class.";
const timezone = "America/Chicago";
const organizer = "PointBlank Gun Training";
const organizer_email = "info@PointBlankGunTraining.com";

const UpcomingClassDates = ({
  title,
  description,
  dates,
  display_header = true,
  cost = null,
}) => {
  return (
    <div className="agenda section-padding" id="schedule">
      {display_header ? (
        <>
          {" "}
          <h2>Upcoming {title} Dates</h2>
          <p className="pbgt-body-text">
            {title} classes available. Custom group dates upon request.
          </p>{" "}
        </>
      ) : null}
      <div>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th colSpan="2">
                <strong>{title} Class Schedule</strong>
                <br />
                <span className="pbgt-body-text">Class Cost: ${cost}</span>
              </th>
            </tr>
            <tr>
              <th>Date</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {dates.length === 0 ? (
              <tr>
                <td
                  colSpan="2"
                  style={{ height: "auto" }}
                  className="pbgt-body-text"
                >
                  No Public Class Dates Scheduled at this time. Contact us to
                  schedule a Private Class
                </td>
              </tr>
            ) : (
              dates.map(({ date_start, date_end }, i) => {
                const {
                  shortMonth,
                  fullMonth,
                  dayOfMonth,
                  dayOfWeek,
                  year,
                  formattedDate,
                  _24Time,
                  _12Time,
                } = getDateInfo(date_start);
                const end_date_info = getDateInfo(date_end);
                return (
                  <tr key={i}>
                    <td className="agenda-date">
                      <div className="d-flex flex-row align-items-center full-height">
                        <div className="monthcircle">{shortMonth}</div>
                        <div className="dayofmonth">{dayOfMonth}</div>
                        <div className="date-cont">
                          <div className="dayofweek">{dayOfWeek}</div>
                          <div className="shortdate text-muted">
                            {fullMonth}, {year}
                          </div>
                        </div>
                        <div className="flex-fill"></div>
                        <span className="addtocalendar">
                          <a
                            className="atcb-link cal-btn"
                            onClick={() => trackConv("atc", 0)}
                            tabIndex="1"
                            id=""
                          >
                            <span className="fa-solid fa-calendar-days"></span>
                          </a>
                          <ul className="atcb-list">
                            <li className="atcb-item">
                              <a
                                className="atcb-item-link"
                                href={`https://addtocalendar.com/atc/ical?${buildAddToCalendarLinkArgs(
                                  title,
                                  description,
                                  formattedDate,
                                  _24Time,
                                  end_date_info.formattedDate,
                                  end_date_info._24Time
                                )}`}
                                target="_blank"
                              >
                                iCalendar
                              </a>
                            </li>
                            <li className="atcb-item">
                              <a
                                className="atcb-item-link"
                                href={`https://addtocalendar.com/atc/google?${buildAddToCalendarLinkArgs(
                                  title,
                                  description,
                                  formattedDate,
                                  _24Time,
                                  end_date_info.formattedDate,
                                  end_date_info._24Time
                                )}`}
                                target="_blank"
                              >
                                Google Calendar
                              </a>
                            </li>
                            <li className="atcb-item">
                              <a
                                className="atcb-item-link"
                                href={`https://addtocalendar.com/atc/outlook?${buildAddToCalendarLinkArgs(
                                  title,
                                  description,
                                  formattedDate,
                                  _24Time,
                                  end_date_info.formattedDate,
                                  end_date_info._24Time
                                )}`}
                                target="_blank"
                              >
                                Outlook
                              </a>
                            </li>
                            <li className="atcb-item">
                              <a
                                className="atcb-item-link"
                                href={`https://addtocalendar.com/atc/outlookonline?${buildAddToCalendarLinkArgs(
                                  title,
                                  description,
                                  formattedDate,
                                  _24Time,
                                  end_date_info.formattedDate,
                                  end_date_info._24Time
                                )}`}
                                target="_blank"
                              >
                                Outlook Online
                              </a>
                            </li>
                            <li className="atcb-item">
                              <a
                                className="atcb-item-link"
                                href={`https://addtocalendar.com/atc/yahoo?${buildAddToCalendarLinkArgs(
                                  title,
                                  description,
                                  formattedDate,
                                  _24Time,
                                  end_date_info.formattedDate,
                                  end_date_info._24Time
                                )}`}
                                target="_blank"
                              >
                                Yahoo! Calendar
                              </a>
                            </li>
                          </ul>
                        </span>
                      </div>
                    </td>
                    <td className="agenda-time">
                      <div className="d-flex flex-row align-items-center full-height">
                        <span className="fa-regular fa-clock"></span>&nbsp;
                        {_12Time}
                      </div>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UpcomingClassDates;
