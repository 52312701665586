import React, { useState } from "react";

const videos = [
  {
    title: ".50 BMG Precision Rifle Action!",
    url: "https://www.youtube.com/embed/ooSeUHtiXA0",
    img: "/images/cdn/videos/50-bmg-rifle-action.jpg",
  },
  {
    title: "PRS Slow Motion Shooting 1",
    url: "https://www.youtube.com/embed/F3YtT58pxX4",
    img: "/images/cdn/videos/slow-motion-prs-1-2018.jpg",
  },
  {
    title: "PRS Slow Motion Shooting 2",
    url: "https://www.youtube.com/embed/UyfESjf7ksA",
    img: "/images/cdn/videos/slow-motion-prs-2-2018.jpg",
  },
  {
    title: "What's in the 750 Yard Precision Rifle Class",
    url: "https://www.youtube.com/embed/mt-fVH_qaOg",
    img: "/images/cdn/videos/in-the-prs-course.jpg",
  },
  {
    title: "750 Yard Precision Rifle Range",
    url: "https://www.youtube.com/embed/rtxbLeeg7IE",
    img: "/images/cdn/videos/750-yard-range-2018.jpg",
  },
  {
    title: "Wireless Camera System",
    url: "https://www.youtube.com/embed/0rM8RNwfiBs",
    img: "/images/cdn/videos/wireless-camera-system-2018.jpg",
  },
  {
    title: "Shoot Night 2016",
    url: "https://www.youtube.com/embed/Q0bKgEvG1Y4",
    img: "/images/cdn/videos/shoot-night-2016.jpg",
  },
  {
    title: "Rob Shepperd 2 Day Combat Pistol",
    url: "https://www.youtube.com/embed/rOTYc5u3Xho",
    img: "/images/cdn/videos/rob-shepperd-2016.jpg",
  },
  {
    title: "Outdoor Facility Overview",
    url: "https://www.youtube.com/embed/Mu5agftPL0Y",
    img: "/images/cdn/videos/outdoor-range.jpg",
  },
  {
    title: "Student Appreciation Event",
    url: "https://www.youtube.com/embed/kTbFJBD1G2E",
    img: "/images/cdn/videos/student-appreciation-event-2015.jpg",
  },
];

const VideoGallery = () => {
  const [video, setVideo] = useState(videos[0]);
  const load_video = (index) => {
    setVideo(videos[index]);
  };

  return (
    <>
      <div className="jumbotron jumbotron-dark text-center">
        <div className="row">
          <div className="hidden-xs col-sm-2"></div>
          <div className="col-xs-12 col-sm-8">
            <div className="ratio ratio-16x9 videoplayer">
              <iframe
                className="youtubeVid embed-responsive-item"
                src={video.url}
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="hidden-xs col-sm-2"></div>
        </div>
      </div>
      <div className="container-fluid section-padding">
        <div className="row">
          <div className="col-xs-12">
            <h1>Video Gallery</h1>

            <div className="videopreviews clearfix">
              {videos.map(({ img, title }, i) => (
                <div key={i} className="pbgt-gallery-preview">
                  <a
                    href="#"
                    title={title}
                    className="block relative"
                    onClick={() => load_video(i)}
                  >
                    <center>
                      <img
                        src={img}
                        data-src={img}
                        alt={`${title} Preview`}
                        className="img-responsive center-block"
                      />
                    </center>
                    <img
                      src="/images/assets/play-red.png"
                      data-src="/images/assets/play-red.png"
                      width="50"
                      className="play-btn-middle"
                      alt=""
                    />
                  </a>
                </div>
              ))}
            </div>
            <br />
            <h4 className="clearfix">
              Click on a video above to automatically start playing. These
              videos were taken at our Private Outdoor Range. In each of our
              Outdoor Training Courses we provide a video to the students. These
              videos are so important as they allow each student to review the
              training and their performance. This is one of many ways we are
              redefining what to expect in a training facility!
            </h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoGallery;
