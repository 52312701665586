import React, { useEffect, useState } from "react";
import { payments } from "@square/web-sdk";

import { SQUARE_APPLICATION_ID, SQUARE_LOCATION_ID } from "../constants";

const PaymentContext = React.createContext();

export const usePayment = () => {
  const context = React.useContext(PaymentContext);
  if (!context) {
    throw new Error("usePayment must be used within a PaymentProvider");
  }
  return context;
};

const PaymentProvider = ({ children }) => {
  const [client, setClient] = useState(null);
  const [thankYouData, setThankYouData] = useState({});

  const loadPayments = async () => {
    await payments(SQUARE_APPLICATION_ID, SQUARE_LOCATION_ID, {}).then(
      (res) => {
        if (res === null) {
          throw new Error("Square Web Payments SDK failed to load");
        }
        setClient(res);
        return res;
      }
    );
  };

  useEffect(async () => {
    await loadPayments();
  }, []);

  return (
    <PaymentContext.Provider
      value={{ payments: client, thankYouData, setThankYouData }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export default PaymentProvider;
